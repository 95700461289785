.add-to-calendar {

	&__dropdown {
		display: inline-block;
	}

	&__btn {
		padding: 0 1rem 0 0;
		color: $body-color;
		background-color: $white;

		&-icon {
			color: #fff;
			padding: 1rem;
			margin-right: .5rem;
			background-color: #000;
			border-radius: 6px 0 0 6px;
		}
	}

	&__menu {
		min-width: 100%;
		background-color: $white;
	}

	&__link {
		font-size: 1.5rem;
		padding: 1rem 1.5rem;

		&-icon {
			margin-right: 1rem;
		}
	}
}
