// Fade

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.animation-fade-out {
	animation: fade-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
	animation-fill-mode: forwards;
}


@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.animation-fade-in {
	animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
	animation-fill-mode: forwards;
}

.fade-in {
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fadeOutCollapse {
	transition: opacity 200ms;

	max-height: 0 !important;
	opacity: 0;
}

.fadeInExpand {
	transition: opacity 400ms;

	max-height: none !important;
	opacity: 1;
}

@-webkit-keyframes fadein-share-casino {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@keyframes fadein-share-casino {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout-share-casino {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}

@keyframes fadeout-share-casino {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}