
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

::v-deep .carousel-indicators > li {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  opacity: 1;
  background-color: transparent;
  border: 2px solid #9a9a9a;
  outline: none;
  outline-color: transparent;
}
::v-deep .carousel-indicators li.active {
  background-color: #0dcb8f;
}

::v-deep .pause-carousel svg {
  fill: #9a9a9a;
}

.pause-carousel {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2%;
  margin-bottom: 1rem;
  z-index: 2;
  height: 3rem;
}

.carousel_image_pause {
  height: 100%;
}

.mobile-carrousel {
  @include media-breakpoint-only(xxs) {
    transform: translate3d(-98%, 0px, 0px);
    width: 80%;
  }

  @include media-breakpoint-only(xs) {
    transform: translate3d(-53.5%, 0px, 0px);
  }

  @include media-breakpoint-up(md) {
    transform: translate3d(-24.5%, 0px, 0px);
  }

  @include media-breakpoint-up(lg) {
    transform: translate3d(-29.5%, 0px, 0px);
    width: 70%;
  }
}

::v-deep .carousel-control-next-icon {
  filter: invert(62%) sepia(5%) saturate(7%) hue-rotate(307deg) brightness(157%) saturate(91%);
}
::v-deep .carousel-control-prev-icon {
  filter: invert(62%) sepia(5%) saturate(7%) hue-rotate(307deg) brightness(157%) saturate(91%);
}

::v-deep .carousel-control-next {
  width: 5%;
  opacity: 1;
}
::v-deep .carousel-control-prev {
  width: 5%;
  opacity: 1;
}
