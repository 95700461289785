.espaceur {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	text-align: left;
	position: relative;

	table td:first-child {
		padding-right: 10px;
	}

	.featured {
		.ww-texte {
			font-family: $font-graphik-regular;
			& * {
				font-family: $font-graphik-regular;
			}
			p {
				color: white;
				margin-bottom: 0;
			}
		}
	}

	.espaceur-inner-content > h2,
	.espaceur-inner-content > h3 {
		&:after {
			display: none;
		}
	}

	&.heading-style-centre {
		.espaceur-inner-content > h2,
		.espaceur-inner-content > h3 {
			text-align: center;
			&:after {
				display: none;
			}
		}
	}

	.espaceur-inner-content > h2,
	.espaceur-inner-content > h3 {
		width: 100%;
	}

	.espaceur-inner-content {
		padding: 0 20px;
		h2 {
			font-family: $font-graphik-semibold;
		}
		h3 {
			font-family: $font-graphik-regular;
			text-transform: uppercase;
		}
	}

	&.heading-style-gauche {
		.espaceur-inner-content > h2,
		.espaceur-inner-content > h3 {
			text-align: left;
		}
	}

	&.pos-vertical-centre {
		.espaceur-content {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&.pos-vertical-bas {
		.espaceur-content {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding-bottom: 10px;
		}
	}

	.ratio {
		height: 0;
		position: relative;
		&.r16_9 {
			padding-bottom: 56.25%;
		}
		&.r4_3 {
			padding-bottom: 75%;
			&.withButton {
				padding-bottom: 56.25%;
			}
			&.espaceurCol5 {
				padding-bottom: 105%;
				&.withButton {
					padding-bottom: 78.75%;
				}

				@include media-breakpoint-down(sm) {
					padding-bottom: 75%;
					&.withButton {
						padding-bottom: 56.25%;
					}
				}
			}
		}

		&.r16_9.r4_3_tablette {
			@include media-breakpoint-only(md) {
				padding-bottom: 75%;
			}
		}
		.ratio-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.buttonnContainerWrap {
		height: 0;
		position: relative;
		padding-bottom: 18.75%;
		&.espaceurCol5 {
			padding-bottom: 26.25%;
			@include media-breakpoint-down(sm) {
				padding-bottom: 18.75%;
			}
		}
		.buttonnContainer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.buttonWrap {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				&.centre {
					text-align: center;
				}
				.espaceurBtn {
					background: white;
					color: black;
				}
			}

			@include media-breakpoint-down(sm) {
				.buttonWrap {
					top: 40%;
				}
			}
		}
	}
}

.espaceur.lg4_md4_sm6_xs12 {
	.espaceur-inner-content {
		padding: 5vw 9vw;
		h2 {
			font-size: 5.5vw;
			margin-bottom: 1.3vw;
		}
		.ww-texte p {
			font-size: 14px;
			//line-height: 1.25em;
		}

		@include media-breakpoint-down(xxs) {
			padding: 5vw 9vw 7px 9vw;
		}
		h2 {
			@include media-breakpoint-down(xxs) {
				font-size: 5.5vw;
				margin-bottom: 1.3vw;
			}
			.ww-texte p {
				@include media-breakpoint-down(xxs) {
					font-size: 14px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.espaceur-inner-content {
			padding: 5vw 5vw;
			h2 {
				font-size: 2.5vw;
				margin-bottom: 0.8vw;
			}
			.ww-texte p {
				font-size: 1.8vw;
				line-height: 1.3em;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.espaceur-inner-content {
			padding: 2.5vw 4.2vw;
			h2 {
				font-size: 1.6vw;
				margin-bottom: 0.6vw;
			}
			.ww-texte p {
				font-size: 1.4vw;
				line-height: 1.26em;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.espaceur-inner-content {
			padding: 40px 50px;
			h2 {
				font-size: 20px;
				line-height: 23px;
				margin-bottom: 6.5px;
			}
			.ww-texte p {
				font-size: 14px;
			}
		}
	}
}

.espaceur.lg5_md5_sm5_xs12 {
	.espaceur-inner-content {
		padding: 0 6vw;
		h2 {
			font-size: 6.5vw;
			margin-bottom: 1.3vw;
			line-height: 7vw;
		}
		h3 {
			font-size: 2.86vw;
			line-height: 5vw;
		}
		@include media-breakpoint-up(md) {
			padding: 0 2.5vw;
		}
		h2 {
			@include media-breakpoint-up(md) {
				font-size: 2.6vw;
				line-height: 2.7vw;
				margin-top: 1vw;
				margin-bottom: 1vw;
			}
		}
		h3 {
			@include media-breakpoint-up(md) {
				font-size: calc(10px + 0.2vw);
				line-height: 1.1vw;
			}
		}
		.ww-texte p {
			font-size: 14px;
			@include media-breakpoint-up(md) {
				font-size: 1.85vw;
				line-height: 1.26em;
			}
			@include media-breakpoint-up(lg) {
				font-size: 1.4vw;
				line-height: 1.26em;
			}
		}
	}
	.buttonnContainerWrap.espaceurCol5 {
		.buttonWrap {
			padding: 0 6vw;
			@include media-breakpoint-up(md) {
				padding: 0 2.5vw;
			}
			a {
				padding: 2.5vw 4.5vw;
				border-radius: 4.5vw;
				font-size: 3.34vw;
				@include media-breakpoint-up(md) {
					padding: 1.17vw 2.34vw;
					border-radius: 1.9vw;
					font-size: 1.41vw;
				}
				font-family: $font-graphik-medium;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.espaceur-inner-content {
			padding: 0 32px;
			h2 {
				font-size: 33px;
				margin-bottom: 6.5px;
				margin-top: 0px;
				line-height: 35px;
			}

			h3 {
				font-size: 16px;
				line-height: 28px;
				padding-bottom: 3px;
			}
			.ww-texte p {
				font-size: 14px;
			}
		}
		.buttonnContainerWrap.espaceurCol5 {
			.buttonWrap {
				padding: 0 32px;

				a {
					padding: 15px 30px;
					border-radius: 25px;
					font-size: 18px;
				}
			}
		}
	}
}

.espaceur.lg6_md6_sm6_xs12 {
	.espaceur-inner-content .ww-texte a:hover {
		text-decoration: underline;
	}

	.espaceur-inner-content {
		padding: 0 10vw;
		h2 {
			font-size: 5.65vw;
			margin-top: 2vw;
			margin-bottom: 3vw;
			line-height: 6.1vw;
		}

		h3 {
			font-size: 2.49vw;
			line-height: 2.59vw;
		}
		.ww-texte p {
			font-size: 14px;
		}

/* 2021-03-03 NE SERT PLUS
		// CED: Black jack
		.sous-titre-black-jack-bkgd-blanc {
			color: #0f6d56;
		}
		.lien-pdf-black-jack {
			text-decoration: underline;
			-webkit-text-decoration-color: #0f6d56;
			text-decoration-color: #0f6d56;
		}
		.lien-pdf-black-jack span {
			color: #0f6d56;
		}
		.lien-pdf-black-jack span:hover {
			color: #000;
		}
		.lien-pdf-black-jack:hover {
			-webkit-text-decoration-color: #000;
			text-decoration-color: #000;
		}
		
		// CED: Roulette
		.sous-titre-roulette-bkgd-blanc {
			color: #194ea1;
		}
		.lien-pdf-roulette {
			text-decoration: underline;
			-webkit-text-decoration-color: #194ea1;
			text-decoration-color: #194ea1;
		}
		.lien-pdf-roulette span {
			color: #194ea1;
		}
		.lien-pdf-roulette span:hover {
			color: #000;
		}
		.lien-pdf-roulette:hover {
			-webkit-text-decoration-color: #000;
			text-decoration-color: #000;
		}
		
		// CED: Jeux en direct
		// TODO: CED-OS-P2
		
		.sous-titre-baccara-bkgd-blanc {
			color: #bc122e;
		}
		.lien-pdf-baccara {
			text-decoration: underline;
			-webkit-text-decoration-color:  #bc122e;
			text-decoration-color: #bc122e;
		}
		.lien-pdf-baccara span {
			color: #bc122e;
		}
		.lien-pdf-baccara span:hover {
			color: #000;
		}
		.lien-pdf-baccara:hover {
			-webkit-text-decoration-color: #000;
			text-decoration-color: #000;
		}
		
		// CED: Poker (anciennement baccara-a-devoiler)
		.sous-titre-baccara-a-devoiler-bkgd-blanc {
			color: #5c0d93;
		}
		.lien-pdf-baccara-a-devoiler {
			text-decoration: underline;
			-webkit-text-decoration-color: #5c0d93;
			text-decoration-color: #5c0d93;
		}
		.lien-pdf-baccara-a-devoiler span {
			color: #5c0d93;
		}
		.lien-pdf-baccara-a-devoiler span:hover {
			color: #000;
		}
		.lien-pdf-baccara-a-devoiler:hover {
			-webkit-text-decoration-color: #000;
			text-decoration-color: #000;
		}
		
		// CED: Dés
		// TODO: CED-OS-P2
*/	

	}

	@include media-breakpoint-up(md) {
		.espaceur-inner-content {
			padding: 2vw 5.5vw;
			h2 {
				font-size: 2.6vw;
				line-height: 2.7vw;
				margin-top: 1vw;
				margin-bottom: 1vw;
			}

			h3 {
				font-size: calc(10px + 0.2vw);
				line-height: 1.1vw;
			}
			.ww-texte p {
				font-size: 14px;
				line-height: 1.3em;
			}
			
			// CED: Black jack
			.sous-titre-black-jack-bkgd-blanc {
				color: #0F6D56;
			  }
			  .lien-pdf-black-jack {
				text-decoration: underline;
				-webkit-text-decoration-color: #0F6D56;
						text-decoration-color: #0F6D56;
			  }
			  .lien-pdf-black-jack span {
				color: #0F6D56;
			  }
			  .lien-pdf-black-jack span:hover {
				color: #000;
			  }
			  .lien-pdf-black-jack:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Roulette
			  .sous-titre-roulette-bkgd-blanc {
				color: #194EA1;
			  }
			  .lien-pdf-roulette {
				text-decoration: underline;
				-webkit-text-decoration-color: #194EA1;
						text-decoration-color: #194EA1;
			  }
			  .lien-pdf-roulette span {
				color: #194EA1;
			  }
			  .lien-pdf-roulette span:hover {
				color: #000;
			  }
			  .lien-pdf-roulette:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Jeux en direct
			  // TODO: CED-OS-P2
			  
			  // Baccara
			  .sous-titre-baccara-bkgd-blanc {
				color: #bc122e;
			  }
			  .lien-pdf-baccara {
				text-decoration: underline;
				-webkit-text-decoration-color: #bc122e;
						text-decoration-color: #bc122e;
			  }
			  .lien-pdf-baccara span {
				color:  #bc122e;
			  }
			  .lien-pdf-baccara span:hover {
				color: #000;
			  }
			  .lien-pdf-baccara:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Poker (anciennement bacara-a-devoiler)
			  .sous-titre-baccara-a-devoiler-bkgd-blanc {
				color: #5C0D93;
			  }
			  .lien-pdf-baccara-a-devoiler {
				text-decoration: underline;
				-webkit-text-decoration-color: #5C0D93;
						text-decoration-color: #5C0D93;
			  }
			  .lien-pdf-baccara-a-devoiler span {
				color: #5C0D93;
			  }
			  .lien-pdf-baccara-a-devoiler span:hover {
				color: #000;
			  }
			  .lien-pdf-baccara-a-devoiler:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Dés
			  // TODO: CED-OS-P2
		}
	}

	@include media-breakpoint-up(xl) {
		.espaceur-inner-content {
			padding: 0 70px;
			h2 {
				font-size: 33px;
				line-height: 35px;
				margin-top: 10px;
				margin-bottom: 20px;
			}

			h3 {
				font-size: 16px;
				line-height: 18px;
			}

			.ww-texte p {
				font-size: 14px;
			}
			
			// CED: Black jack
			.sous-titre-black-jack-bkgd-blanc {
				color: #0F6D56;
			  }
			  .lien-pdf-black-jack {
				text-decoration: underline;
				-webkit-text-decoration-color: #0F6D56;
						text-decoration-color: #0F6D56;
			  }
			  .lien-pdf-black-jack span {
				color: #0F6D56;
			  }
			  .lien-pdf-black-jack span:hover {
				color: #000;
			  }
			  .lien-pdf-black-jack:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Roulette
			  .sous-titre-roulette-bkgd-blanc {
				color: #194EA1;
			  }
			  .lien-pdf-roulette {
				text-decoration: underline;
				-webkit-text-decoration-color: #194EA1;
						text-decoration-color: #194EA1;
			  }
			  .lien-pdf-roulette span {
				color: #194EA1;
			  }
			  .lien-pdf-roulette span:hover {
				color: #000;
			  }
			  .lien-pdf-roulette:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Jeux en direct
			  // TODO: CED-OS-P2
			  
			  .sous-titre-baccara-bkgd-blanc {
				color: #bc122e;
			  }
			  .lien-pdf-baccara {
				text-decoration: underline;
				-webkit-text-decoration-color: #bc122e;
						text-decoration-color: #bc122e;
			  }
			  .lien-pdf-baccara span {
				color: #bc122e;
			  }
			  .lien-pdf-baccara span:hover {
				color: #000;
			  }
			  .lien-pdf-baccara:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Poker (anciennement baccara-a-devoiler)
			  .sous-titre-baccara-a-devoiler-bkgd-blanc {
				color: #5C0D93;
			  }
			  .lien-pdf-baccara-a-devoiler {
				text-decoration: underline;
				-webkit-text-decoration-color: #5C0D93;
						text-decoration-color: #5C0D93;
			  }
			  .lien-pdf-baccara-a-devoiler span {
				color: #5C0D93;
			  }
			  .lien-pdf-baccara-a-devoiler span:hover {
				color: #000;
			  }
			  .lien-pdf-baccara-a-devoiler:hover {
				-webkit-text-decoration-color: #000;
						text-decoration-color: #000;
			  }
			  
			  // CED: Dés
			  // TODO: CED-OS-P2
		}
	}
}

[data-ratio='r16_9 r4_3_tablette'] .espaceur {
	@include media-breakpoint-only(md) {
		background-size: contain;

		.espaceur-inner-content {
			padding: 0 5vw;
			h2 {
				font-size: 3.38vw;
				margin-bottom: 0.65vw;
				line-height: 3.51vw;
			}

			h3 {
				font-size: 1.3vw;
				line-height: 1.4vw;
			}
			.ww-texte p {
				font-size: 1.8vw;
			}
		}
	}
}

body[data-page-categorie='BlackJack'],
body[data-page-categorie='Roulette'],
body[data-page-categorie='Baccara'],
body[data-page-categorie='Poker'],
body[data-page-categorie='JeuxEnDirect'],
body[data-page-categorie='Des'] {
	.lobby3.grid-5 {
		@media screen and (min-width: 640px) {
			.espaceur {
				.ratio.r4_3 {
					padding-bottom: 86.5%;
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	body[data-page-categorie='AccueilCED'] {
		.cedTablePremiereOuverte {
			.tableFooter-ratio {
				padding-bottom: 30%;
			}
		}
	}

	.espaceNaturelMobile {
		.espaceur .ratio {
			height: auto;
			padding-bottom: 0;
			.ratio-content {
				position: relative;
				.espaceur-content {
					top: auto;
					transform: none;
					.espaceur-inner-content {
						padding: 10vw;
					}
				}
			}
		}
	}
}