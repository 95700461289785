
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.image {
  //position: absolute;
  //opacity: 0.5;
  //left: 0;
  height: 100%;
}

.saviez_vous_que{
  border: 2px dashed rgb(13, 203, 143);
  border-radius: ms(2);  
  min-height: 23rem;;
}

.div_container.saviez_vous_ctn{
  padding-left: 5%;
  padding-right: 5%; 
}

.div_container{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding-left:15%;
  padding-right: 15%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index:1;
  align-items: center; 
  color:black;
  text-align: center;
  top: 0;
}

.texte{
  @include ms-respond(font-size, 0);
  padding: 0px 5% 0px 5%;  
  line-height: 150%;
}
.titre_media{
  @include ms-respond(font-size, 1);  
}

.titre_bas .div_container{
  justify-content: flex-end;

  .titre_media{
    @include ms-respond(font-size, 2);
  }
}

