

/* MODAL GUEST */

.container--modalGeneric {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

.container--modalGeneric {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

.modal-header--modalGeneric {
	background: transparent;
	background-color: transparent;
	border: 0;
	box-shadow: none;
	color: black;
}

.modal-footer--modalGeneric {
	background: transparent;
	background-color: transparent;
	border: 0;
	box-shadow: none;
	color: black;
}

.modal-content--modalGeneric {
	background: transparent;
	background-color: transparent;
	border: 0;
	box-shadow: none;
	color: black;
}

.modal-body--modalGeneric {
	background: transparent;
	background-color: transparent;
	border: 0;
	box-shadow: none;
}

.modal--modalGeneric {
	border: 0;
	box-shadow: none;
	color: black;
	padding-right: 0;
	padding-right: 0 !important;
	z-index: 100050;
}

#ej-modal{
	z-index: 100050;
}

.btn-jouez-degrade {
	background: linear-gradient(to bottom, #ffd429 0%, #e29b01 100%);
	padding: 15px 30px;
}

.btn-custom-jaune {
	background: #f6c812;
	background: #ffd429;
	font-size: 1.0em;
	font-weight: 600;
	&:hover {
		background: #ffe588;
	}
}

.btn-same-sizing {
	min-width: 130px;
	margin: 5px;
}

.btn-close-custom-gris {
	background: #aaa;
}

.btn-custom-gris {
	font-size: 1.0em;
	font-weight: 400;
}

.btn-close-custom-jaune {
	color: #f6c812;
	color: #ffd429;
	&:hover {
		color: #f6c812;
		color: #ffd429;
	}
}

.modalDialog--modalGeneric {
	display: flex;
	height: 100%;
	margin: 0 auto;
}

.modal-dialog--modalGeneric {
	display: flex;
	height: 100%;
	margin: 0 auto;
}

.modalContent--modalGeneric {
	display: flex;
	width: 100%;
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: center;
	margin: auto;
}

.modalBody--modalGeneric {
	text-align: center;
	padding: 30px 20px 24px 20px;
	border: 0px solid #ffd429;
}

.modalFooter--modalGeneric {
	text-align: center;
}

.txtJustify {
	text-align: justify;
}

.confirmText--modalGeneric {
	font-family: sans-serif;
	color: white !important;
	font-weight: 500;
	font-size: 1.1em;
	line-height: 1.45em;
}

.confirmTextAdditional--modalGeneric {
	font-size: 0.85em;
	font-family: sans-serif;
	line-height: 1.1em;
	color: black;
}

button.btn-close-confirm-set--modalGeneric {
	min-width: 200px;
	margin: 5px;
	padding: 10px;
}

.boutonFermerLarge--modalGeneric {
	min-width: 200px;
}

div.modal-body~button {
	padding: 2px 7px;
}

p.modalBtnRow {
	color: black;
	button {
		color: black;
	}
}


.dialog-body--modalGeneric {
	background: transparent;
	background-color: transparent;
	border: 0;
	box-shadow: none;
	color: black;
}

.dialog_msg--modalGeneric {
	color: #fff;
	text-align: center;
	font-family: Lato, 'Lato', sans-serif;
	font-weight: 500;
	font-size: 1.1em;
	line-height: 1.45em;
}

.modalText--modalGeneric {
	font-family: 'Lato', Lato, sans-serif;
	color: #000;
	font-size: 1.25em;
	line-height: 1.4em;
	font-weight: 500;
}

.modalText--modalGeneric h4{
	text-align: left;
}

.dialog-body {
	border: 0px solid transparent;
	border-color: transparent;
	border-top: 0px;
	border-right: 0px;
	border-bottom: 0px;
	border-left: 0px;
	box-shadow: 0 0 0 0 transparent;
	background: transparent;
	color: #fff;
	padding: 15px 0;
	line-height: 1.4em;
}

dialog-box {
	border: 0px solid transparent;
	border-color: transparent;
	border-top: 0px;
	border-right: 0px;
	border-bottom: 0px;
	border-left: 0px;
	box-shadow: 0 0 0 0 transparent;
}

dialog-msg {
	border: 0px solid transparent;
	border-color: transparent;
	border-top: 0px;
	border-right: 0px;
	border-bottom: 0px;
	border-left: 0px;
	box-shadow: 0 0 0 0 transparent;
}

.btn-custom-bleu--modalGeneric {
	background: #00407d !important;
	border: 0px solid #204d74 !important;
	color: #fff !important;
	&:hover {
		background: #4286c1 !important;
		border: 0px solid #4286c1 !important;
	}
}

.btn-custom-cancel--modalGeneric {
	color: #fff !important; 
	background: transparent !important;
	border: 1px solid #aaa !important;
	&:hover {
		border: 1px solid #fff !important;
		background: transparent !important;
	}
}

.btn-same-sizing--modalGeneric {
	min-width: 180px;
	max-width: 180px;
	margin: 5px auto;
	padding: 8px 10px;
	border-radius: 4px;
	cursor: pointer;
	box-shadow: none;
	text-shadow: none;
}

div.loading {
	div.icon-clubs {
		margin: 10px 0 !important;
	}
	div.icon-diamonds {
		margin: 10px 0 !important;
	}
	div.icon-hearts {
		margin: 10px 0 !important;
	}
	div.icon-spades {
		margin: 10px 0 !important;
	}
}

.dialog-box {
	box-shadow: 0 0 0 0 transparent;
	border: none;
	border-radius: 0;
}

div.dialog-body {
	>ul {
		li {
			font-size: 1.15em;
			font-family: 'lato', sans-serif;
			line-height: 1.2em;
		}
	}
}

div.dialog-button {
	>div.button-cancel {
		color: white !important;
		min-width: 180px;
		padding: 8px 10px;
		text-transform: uppercase;
		border-radius: 4px;
		box-shadow: none;
		text-shadow: none;
		text-align: center;
	}
	>div.button-confirm {
		color: white !important;
		min-width: 180px;
		padding: 8px 10px;
		text-transform: uppercase;
		border-radius: 4px;
		box-shadow: none;
		text-shadow: none;
		text-align: center;
	}
}

.dialog-button {
	padding-top: 15px;
}


/**********************************************************************************************
	CSS pour le dialogue modal bootstrap contenant une video de promotion

	utilisé dans promotionModaleArea ( TFS-5032 )

************************************************************************************************/

/*Sets the maximum height of the modal body to 90% of the screen height*/

#promoModal {
	.modal-body {
		width: 100%;
		padding: 0px;
	}
	.modal-body.noBorder{
		border-width: 0;
	}
	.modal-body.withBorder{
		border-width: 3px;
		border-style: solid; 
	}
	.modal-header.defaultColorBar{
		background-image: linear-gradient(to top, #090c0b, #3f444e);
	}
	.modal-header {
		.close{
			font-size: 42px;
			margin-top: -10px; 
			margin-bottom: -10px;
		}
	}
	.modal-button-restart{
		display:none;
	}
	.modal-header.noBorder{
		border-width: 0;
	}
	.modal-header.withBorder{
		border-width: 3px 3px 0px 3px !important;
		border-style:solid !important;
	}
	.modal-body.embed-responsive{
		position:absolute;
		top:0;
		opacity:0;
	}
	.modal-button-window{
		display:none;
	}
	.modal-content {
    	height: 100%;
    	width: 100%;
	}
	.modal-dialog {
    	/*width: 90%;
    	height: 91%;*/
    	margin: 0;
	}
	#promoModaleLire, #promoModaleLien {
		width: 100%;
		text-align:center;
		cursor:pointer;
		height:100%;
		position:absolute;
		top:0;
		img{
			width:100%;
		}
	}
	#promoModaleLire{
		button{
			position:absolute;
			left: 50%;
		    top: 50%;
		    width: 68px;
		    height: 48px;
		    margin-left: -34px;
		    margin-top: -24px;
		    border: none;
		    background-color: transparent;
		    padding: 0;
		    color: inherit;
		    text-align: inherit;
		    font-size: 100%;
		    font-family: inherit;
		    line-height: inherit;
		    svg{
		    	height: 100%;
			    left: 0;
			    position: absolute;
			    top: 0;
			    width: 100%;
			    path.ytp-large-play-button-bg{
			    	fill: #212121;
    				fill-opacity: .8;
			    }
		    }
		}
		&:hover button svg path.ytp-large-play-button-bg{
	    	fill: #f00;
			fill-opacity: 1;
	    }
	}
	#promoModaleLien {
		opacity:0;
	}
}

.modal-backdrop.shadow-none{ 
   background-color: transparent;
   opacity:0.5
	
}


