.bloc-jeu-image-container {
  @include media-breakpoint-up(xl) {
    height: 140px;
    margin-bottom: 40px;
    overflow: hidden;
    clip-path: inset(0);
  }
}

.casinoJeu {
  &:focus,
  &:hover {
    position: relative;
    z-index: 3;
  }
}

.blocs-jeux {
  float: left;
}

.bloc-jeu {
  margin-bottom: 1em;
  position: relative;
}

.bloc-jeu-titre {
  display: none;
  p {
    color: white;
    font-weight: bold;
    font-size: 11px;
    height: 20px;
  }
  sup {
    font-size: 8px;
  }
}

.bloc-jeu-main {
  background-color: #000000;
  height: 180px;
  position: relative;
  cursor: pointer;
  z-index:1;
}

.no-fav {
  i.favoris {
    display: none;
  }
}
.cedMASenDirect {
  div.bloc-jeu {
    color: #a8a8a8;
    width: 100%;
    div.bloc-jeu-boutons {
      padding: 0 8px 4px 0;
      a.argent-reel {
        background: #fff;
        border-radius: 18px;
        padding: 11px 0;
        height: 36px;
        strong {
          font-size: larger;
          color: #000;
        }
      }
    }
  }
  .row.gutters-xsmall > [class*="col"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .col-lg-2-4,
  .col-xl-2-4 {
    /*@include make-xl-column(2.4)*/
    width: 20%;
  }

  .col-lg-2-4,
  .col-xl-2-4,
  .col-xl-3 {
    .bloc-jeu-main {
      clip-path: inset(0);
      a.bloc-jeu-lien {
        .fa-mobile {
          font-size: 25px;
          position: absolute;
          top: 5px;
          right: 5px;
          color: #727272;
        }
      }
      .bloc-jeu-info {
        z-index: 1;
      }
    }
    .bloc-jeu-footer {
      padding: 0 4px;
      .lien-info--background-grey::before {
        background: url(/.resources/ej-template-theme/img/ui/info-bottom-right.png);
        background-repeat: repeat;
        background-repeat: no-repeat;
        bottom: 0;
        content: "";
        display: block;
        left: 153px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }
    }
    .bloc-jeu-main:hover {
      height: 387px;
      width: 233px;
      position: absolute;
      z-index: 4;
      .bloc-jeu-info {
        margin: 0;
        height: 100%;
        z-index: 2;
        a.bloc-jeu-lien {
          .fa-mobile {
            font-size: 30px;
            position: absolute;
            top: 168px;
            right: 5px;
            color: #727272;
            display: block;
          }
        }
      }
      .bloc-jeu-boutons {
        bottom: 207px;
      }
      .bloc-jeu-footer {
        bottom: 247px;
      }
      a.bloc-jeu-lien {
        .fa-mobile {
          display: none;
        }
      }
    }
  }
}

.bloc-jeu-main:hover {
  .bloc-jeu-info {
    opacity: 1;
    transition: opacity 0.3s linear;
    visibility: visible;
  }

  .lien-info {
    z-index: 2;
  }

  .lien-info__pin {
    opacity: 0;
    transition: opacity 0s linear;
  }
}

.bloc-jeu-main-nmo {
  background-color: #000000;
  height: 140px;
  position: relative;
  cursor: pointer;
}

.bloc-jeu-main-coupDeCoeur {
  background-color: #000000;
  height: 140px;
  position: relative;
}

.jeu-loteries {
  .bloc-jeu-main {
    background-color: white;
  }
}

.bloc-jeu-info {
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  border: 4px solid #c8c8c8;
  border: 4px solid rgba(200, 200, 200, 0.5);
  border-radius: 9px;
  margin: -4px;
  background-color: black;
  position: absolute;
  transition: opacity 0.5s linear;

  p {
    text-justify: inter-word;
    font-size: 12px;
  }
  p.lien-info-petite {
    a {
      color: white !important;
      text-decoration: none !important;
      font-size: 11px;
      &:link {
        color: white !important;
        text-decoration: none !important;
        font-size: 11px;
      }
      &:visited {
        color: white !important;
        text-decoration: none !important;
        font-size: 11px;
      }
    }
  }
  table {
    cursor: pointer;
    margin: 15px 10px;
    th.left-column {
      width: 48%;
    }
    th.right-column {
      width: 52%;
    }
  }
  > a {
    p {
      cursor: pointer;
      margin: 0 10px 15px !important;
    }
  }
  td {
    padding-bottom: 8px;
    font-size: 12px;
  }
  td.left-column {
    font-weight: bold;
    width: 120px;
  }
  td.right-column {
    padding-right: 8px;
  }
}

.bloc-jeu.jeu-loteries {
  .bloc-jeu-info {
    background-color: white;
    color: #3c3c3c;
  }
  .bloc-jeu-lien {
    color: #3c3c3c;
    &:active {
      color: #3c3c3c;
    }
    &:hover {
      color: #3c3c3c;
    }
    &:focus {
      color: #3c3c3c;
    }
  }
}

.infoJeu {
  td {
    font-size: 14px;
    padding: 4px 0;
    color: #ababab;
  }
}

img.bloc-jeu {
  display: block;
  background: transparent;
  zoom: 1;
  width: 100%;
}

ul#blocs-jeux-accueil {
  .bloc-jeu-img {
    position: relative;
    top: 20px;
  }
}

img.bloc-jeu-bandeau {
  position: absolute;
  width: auto;
  opacity: 1;
  border-radius: 8px 0;
}

img.bloc-jeu-bandeau-right {
  position: absolute;
  width: auto;
  opacity: 1;
  border-radius: 8px 0;
  right: -12px;
  top: -12px;
}

ul#blocs-jeux-accueil .bloc-jeu-img {
  position: relative;
  top: 20px;
}

img.bloc-jeu-bandeau,
img.bloc-jeu-bandeau-right {
  position: absolute;
  width: auto;
  opacity: 1;
  border-radius: 8px 0;
}

img.bloc-jeu-bandeau-right {
  right: -12px;
  top: -12px;
}

.cedMASenDirect {
  .bloc-jeu-footer {
    height: 26px;
  }
}

.bloc-jeu-footer {
  height: 24px;
  clear: left;
  font-size: 11px;
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 100%;
  padding-top: 7px;
  padding-left: 5px;
  text-align: center;
  z-index: 2;

  span.labelprogressif {
    letter-spacing: -0.02em !important;
  }
  > .progressif-moyen {
    float: left;
  }
  > .progressif-xpetit {
    float: left;
  }
  > .lien-info {
    float: right;
  }
}

.jeu-loteries {
  .bloc-jeu-footer {
    background-color: white;
  }
}

img.progressif {
  display: block;
}

.bloc-jeu-info {
  > .lien-info {
    float: right;
  }
}

.lien-info {
  p {
    float: left;
  }
  img {
    margin-top: 4px;
  }
}

p.lien-info-petite {
  padding-right: 4px;
}

.info-title {
  color: #d7a41e;
  flex-grow: 1;
  font-size: 1.2em;
  font-family: "Tahoma Bold", Arial, Helvetica, sans-serif;
  line-height: 1;
  margin-right: 0.5rem;

  &:focus,
  &:hover {
    color: #d7a41e;
  }
}

.info {
  color: #fff;
  float: right;
  font-size: 1.2em;
  font-family: "Tahoma Bold", Arial, Helvetica, sans-serif;
  line-height: 1;
  padding-right: 5px;
}

.other-prize-info {
  clear: both;
  content: " ";
  display: none;
  width: 100%;
}

.other-prize-info .info-title {
  float: left;
  display: block;
  line-height: 30px;
}

.other-prize-info .info {
  float: left;
  display: block;
  line-height: 30px;
}

.gameplay-icons {
  float: left;
  height: 36px;
  line-height: 3em;
  vertical-align: middle;
}

.demo-play,
.real-play {
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0 #333;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: 1.2em/1.1em "Tahoma Bold", Arial, Helvetica, sans-serif;
  letter-spacing: 0.01em;
  padding: 12px 10px;
  text-align: center;
  box-shadow: 1px 1px 2px 0px #333;
}

.demo-play {
  background: linear-gradient(
    to bottom,
    #e2c05a 0,
    #d3971e 10%,
    #d19225 50%,
    #d19225 90%,
    #c87c2c 100%
  );
  border: 1px solid #db9d59;
  text-shadow: 1px 1px 2px #54380e;
}
.real-play {
  background: linear-gradient(
    to bottom,
    rgba(104, 190, 232, 1) 0%,
    rgba(104, 190, 232, 1) 3%,
    rgba(41, 150, 208, 1) 10%,
    rgba(29, 94, 154, 1) 100%
  );
  border: 1px solid #55a7cf;
  text-shadow: 1px 1px 2px #144563;
}

.cedMASenDirect #blocs-jeux .chargement,
body.mobile_device_ej #blocs-jeux .chargement {
  display: none;
}

.bloc-jeu__feature-image-placeholder {
  align-items: center;
  background: #286090;
  color: #fff;
  display: flex;
  height: 4vw;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
}

// Used for preview and game details

.feature-image {
  border-radius: 8px;
  box-shadow: 0 0 0px #000000 inset;
  overflow: hidden;
  width: 100%;
}
