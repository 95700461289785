
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.div_general {
  color: white;
  padding-left: 2%;
  //padding-top:25%;
  margin: -2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 37%;
}

.surTitre {
  font-size: ms(-3);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.titre_mediaImage {
  text-transform: uppercase;
  margin-bottom: 0;
  @include ms-respond(font-size, 0);
}

.btn {
  color: black;
  border-radius: ms(6);
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 0.5rem !important;
  font-size: ms(-1);
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-bottom: 1.5rem !important;
}

.btn:hover {
  opacity: 1 !important;
}

.h3 {
  margin-bottom: 0;
}

.coinsArrondis {
  border-radius: ms(1);
}

.margins {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.texteBouton {
  margin-top: 0px;
  @include ms-respond(font-size, -1);
}
