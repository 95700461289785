//
// DEPRECATED
// Use owl carousel instead
//

.ejCarrouselBannieres {
	overflow:hidden;
    margin-bottom:23px!important;
    min-height: 71px;
    &:not(.slick-initialized){
        visibility: hidden;
        max-height: 220px;
    }

    @media screen and (min-width: 1200px) {
        min-height: 220px;
    }

    .fn-carousel__iframe {
        display: block;
        height: 100%;
        left: 50%;
        overflow: hidden;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        width: 100%;
    }

    .slick-track {
        display: flex;
    }

        
    /* SCSS */
    .slick-loading {
        .slick-list {
            background: #fff url('/dam/espacejeux/resources/images/ui/ajax-loader.gif') center center no-repeat;
        }
    }

    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    .slick-dots {
        bottom: 5px;
        width: auto;
        li {
            button {
                &:before {
                    font-size: 50px;
                    opacity: 1;
                    color: #3c3c3c;
                }
            }
        }

        .slick-active {
            button::before {
                color: #000;
            }
        }
    }

    @media screen and (max-width: 640px) {
        .slick-dots {
            bottom: 0;
        }

        .slick-dots li button:before {
            font-size: 10px;
        }

        .slick-dots li {
            margin: 0 2px;
            height: 17px;
            width: 17px;
        }
    }

    /* SCSS */
    .slick-slide {
        height: inherit;
        position: relative;

        a {
            outline: none;
            &:hover {
                outline: none;
            }
            &:focus {
                outline: none;
            }
        }
    }
}