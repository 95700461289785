.espaceur_commentJouer{
	text-align: left;
	
	.hidden .ww-texte{
		height:170px;
		overflow:hidden;
	}
	
	.featured .ww-texte p{
	    font-size: 14px;
	    line-height: 1.3em;
	    margin:0;
	}
	
	.featured .ww-texte *{
		font-family: graphik_regular, Arial, Helvetica, sans-serif;
	}
	
	table td:first-child {
    	padding-right: 10px;
	}
	
	&.lg6_md6_sm6_xs12 .ratio .espaceur-inner-content{
		 h2 {
			font-size: 5.65vw;
			margin-top: 2vw;
			margin-bottom: 3vw;
			line-height: 6.1vw;
			font-family: graphik_semibold, Arial, Helvetica, sans-serif;
		}
	}
	@media (min-width: 1200px){
		&.lg6_md6_sm6_xs12 .ratio.adaptive .espaceur-inner-content {
			padding: 50px 70px;
		
			&.padding_bouton{
				padding: 50px 70px 90px;
			}
			
			h2 {
	    		font-size: 33px;
	    		line-height: 35px;
	    		margin-top: 10px;
	    		margin-bottom: 20px;
			}
		}
		
		#voirPlus.hidden .ww-texte{
			height:155px;
			overflow:hidden;
		}
	}
	
	@media (max-width: 769px){
		.ratio.adaptive{
			min-height:345px;
		}
		
		&.lg6_md6_sm6_xs12 .espaceur-inner-content{
			h2 {
		    	font-size: 33px;
		    	line-height: 35px;
		    	margin-top: 10px;
		    	margin-bottom: 20px;
			}
		} 
	}
	
	@media (min-width: 768px){
		&.lg6_md6_sm6_xs12 .ratio{
			.espaceur-inner-content {
				padding: 2vw;
		
				&.padding_bouton{
					padding: 2vw 2vw 7.8vw 2vw;
				}
			    h2 {
					font-size: 2.6vw;
			    	line-height: 2.7vw;
			    	margin-top: 1vw;
			    	margin-bottom: 1vw;
				}
			}
		}
		
		.hidden .ww-texte{
			height:140px;
			overflow:hidden;
		}
		
	}
	
	@media (max-width: 767.98px){
		&.lg6_md6_sm6_xs12 .espaceur-inner-content{
			padding: 10vw;
			&.padding_bouton{
					padding: 10vw 10vw 12vw 10vw;
				}
		}
	}
}

.lobby-table-block-body {
	.bouton_voirPlus {
    	color: black;
    	font-weight: bold;
	}	
  
  .bouton_voirPlus {
    background: rgba(0, 0, 0, 0.8);
    font-size: calc(11px + 0.5vw);
  }
}

.bouton_voirPlus{
	cursor:pointer;
	bottom:25px;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.section-verte .bouton_voirPlus {
  background-color: #00a389;
}
.section-bleu .bouton_voirPlus {
  background-color: #0a71f0;
}
.section-bleu-ciel .bouton_voirPlus {
  background-color: #01aef0;
}
.section-rouge .bouton_voirPlus {
  background-color: #f03447;
}
.section-bleu-marin .bouton_voirPlus {
  background-color: #4f71f8;
}
.section-violet .bouton_voirPlus {
  background-color: #d63a93;
}

.desktop #voirPlus.espaceur-inner-content .bouton_voirPlus {
  @include media-breakpoint-up(xl) {
    font-weight: 600;
    font-family: graphik_regular;
    line-height: 18px;
    font-size: 20px;
    padding-top: 17px;
    padding-bottom: 13px;
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 73px;
    -webkit-border-radius: 73px;
    -moz-border-radius: 73px;
  }
}

@media (min-width: 1024px){
	.desktop .espaceur-inner-content .bouton_voirPlus {
	    font-weight: 600;
	    font-family: graphik_regular;
	    line-height: 18px;
	    font-size: 15px;
	    padding-top: 10px;
	    padding-bottom: 8px;
	    padding-left: 34px;
	    padding-right: 34px;
	    border-radius: 18px;
	    -webkit-border-radius: 18px;
	    -moz-border-radius: 18px;
	}
}

.desktop .bouton_voirPlus {
    font-weight: 600;
    font-family: graphik_regular;
    line-height: 18px;
    font-size: 15px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 34px;
    padding-right: 34px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
}

.commentJouer_image{
	position:relative;
	top: 50%;
	transform: translate(0,-50%);
}

.commentJouer_imageColonne{
	    background-size: contain;
    	background-repeat: repeat;
}