// To update the icon set, use the icomoon-icon-set.json located in dev\vendor\icomoon-openbet folder
// - Create a projet at icomoon.io
// - Import the JSON
// - Export the new set
// - Replace all the icomoon fonts

[class^="icomoon"], [class*="icomoon"] {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
    /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* top bar */
.icon-arrowdown:before {
	content: "\e620";
    /* float: right; MGNL 5 */
	margin-top: 5px;
	font-size: 1em;
	line-height: 1.7em;
	margin-left: 5px;
}

.rotate-arrow.icon-arrowdown:before {
	transform: rotate(180deg);
}

.icon-earth:before {
	content: "\61";
	float: left;
	margin-top: 5px;
	font-size: 20px;
}

.icon-account:before {
	content: "\e603";
	font-size: 1.7em;
	line-height: 1.1em;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	margin-right: 0;
}    

/* navigation bar */    
.icon-mainmenu:before {
	content: "\e612";
	color: #fff;
	font-size: 1em;
	padding: 9px 8px 9px 13px;
	line-height: 52px;
}
    
/* dropdown menu */
.icon-home:before {
	content: "\e610";
	font-size: 1.5em;
	line-height: 1.1em;
	margin: 0 18px 0 0;
}

.icon-bonus:before {
	content: "\e605";
	font-size: 1.6em;
	line-height: 1.2em;
}

.icon-cards:before {
	content: "\e601";
	font-size: 1.6em;
	line-height: 1em;
	margin-right: 18px;
}

.icon-seven:before {
	content: "\e600";
	font-size: 1.6em;
	line-height: 1em;
	margin-right: 18px;
}

.icon-diamond-2:before {
	content: "\e602";
	font-size: 1.6em;
	line-height: 1em;
	margin-right: 18px;
}

.icon-roulette:before {
	content: "\e628";
	font-size: 1.9em;
	line-height: 1.1em;
	margin-right: 16px;
	display: inline-block;
}

.icon-Featured:before {
	content: "\e605";
	font-size: 1.6em;
	line-height: 1.2em;
	margin-right: 18px;
}

.icon-Table_Games:before {
	content: "\e601";
	font-size: 1.6em;
	line-height: 1em;
	margin-right: 18px;
}

.icon-Slot_Machines:before {
	content: "\e600";
	font-size: 1.6em;
	line-height: 1em;
	margin-right: 18px;
}

.icon-EZ_Games:before {
	content: "\e602";
	font-size: 1.6em;
	line-height: 1em;
	margin-right: 18px;
}
 
    
/* loader icons */
.icon-clubs:before,
.icon-diamonds:before,
.icon-hearts:before,
.icon-spades:before {
	font-size: 1.8em;
	line-height: 1em;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	min-width: 33px;
	margin-top: -3px;
}

.icon-clubs:before {
	content: "\e607";
}

.icon-diamonds:before {
	content: "\e609";
}

.icon-hearts:before {
	content: "\e60e";
}

.icon-spades:before {
	content: "\e61b";
}

.back-button.icon-arrowleft:before {
	content: "\e621";
	font-size: 1.5em;
	color: #D7A41E;
	line-height: 1em;
}   

.icon-crown:before {
	content: "\e608";
}

.icon-maxminbets:before {
	content: "\e613";
}

.icon-betperline:before {
	content: "\e604";
}

.icon-dice:before {
	content: "\e624";
	font-size: 2.2em;
}

.icon-roulette2:before {
	content: "\e617";
	font-size: 2em;
}

.icon-roulette3:before {
	content: "\e618";
	font-size: 2em;
}

.gameplay-icons .icon-cards:before {
	color: #fff;
	font-size: 1.8em;
	line-height: 1.8em;
}

.icon-download:before {
	content: "\e60a";
	font-size: 1.6em;
	color: #fff;
}

.expand-row-header .icon-arrowdown:before {
	content: "\e620";
	font-size: 1.5em;
	line-height: 0.8em;
	margin: 0 0 0 5px;
	text-shadow: 0 1px 3px #000;
	color: #fff;
}

.icon-freespins:before {
	content: "\e60d";
	font-size: 2em;
	color: #eee;
}

.game-icons .icon-betperline:before,
.game-icons .icon-cards:before,
.game-icons .icon-dice:before,
.game-icons .icon-roulette2:before,
.game-icons .icon-roulette3:before,
.game-icons .icon-bonus:before {
	margin-top: 4px;
}

.game-icons .icon-cards:before {
	color: #ccc;
}

.icon-arrowleft:before {
	content: "\e621";
	line-height: 1em;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
}
    
/* footer */
.icon-menudown:before {
	content: "\6c";
	font-family: 'icomoon';
	font-size: 1.7em;
	line-height: 1.3em;
	color: #fff;
	cursor: pointer;
	margin-right: 5px;
	margin-left: 0;
}

.icon-info:before {
	content: "\e611";
	display: inline-block;
	float: right;
	font-family: 'icomoon';
	font-size: 1.9em;
	line-height: 1em;
	color: #fff;
	cursor: pointer;
	margin-right: 0;
	margin-top: 5px;
}

.icon-facebook:before,
.icon-twitter:before ,
.icon-youtube:before {
	display: inline-block;
	color: #fff;
	font-size: 2.2em;
}

.icon-facebook:before {
	content: "\e60b";
	margin-right: 7px;
}

.icon-twitter:before {
	content: "\e61e";
	margin-right: 10px;
}

.icon-youtube:before {
	content: "\e61f";
	margin-right: 8px;
}
    
/* common UI */
.icon-settings:before {
	content: "\e61a";
	font-size: 1.7em;
	line-height: 2.1em;
	margin-right: 0;
	color: #fff;
}

a.icon-plus:before {
	content: "\e60f";
	font-size: 1.2em;
	line-height: 1em;
	margin-right: 0;
	color: #fff;
	text-shadow: 1px 1px 0 #000;
}

.icon-cross:before {
	content: "\e606";
	font-size: 1.7em;
	line-height: 1em;
	margin-right: 0;
	color: #fff;
}        
    
/* account */
.icon-logout:before {
	content: "\e60c";
	font-size: 1.9em;
	line-height: 1.4em;
	margin-right: 5px;
	color: #fff;
	text-shadow: 1px 1px 0 #000;
}

.icon-tick:before {
	height: 25px;
	width: 20px;
	display: inline-block;
	vertical-align: middle;
	content: "\e616";
	font-size: 1.2em;
	line-height: 1em;
	margin: 0 5px 0 7px;
	color: #B04E2C;
	font-family: 'icomoon';
}

.icon-asterisk:before {
	height: 21px;
	width: 11px;
	content: "\e614";
	font-size: 0.9em;
	line-height: 21px;
	margin-right: 0;
	color: #eee;
}

.icon-arrow-down:before {
	content: "\e620";
}

.icon-arrow-up:before {
	content: "\e623";
}

.icon-checkmark:before {
	content: "\ea10";
}

.icon-prizeinfo:before {
	content: "\e615";
}

.icon-filter:before {
	content: "\ea5b";
}

.icon-search:before {
	content: "\e986";
}

.icon-stop:before {
	content: "\ea17";
}

.icon-next:before {
	content: "\ea1c";
}

.icon-prev:before {
	content: "\ea1c";
    display: inline-block;
    position: relative;
    transform: rotate(180deg);
}

.icon-remove:before {
	content: "\e606";
}     

.icon-gift:before {
  content: "\e900";
}