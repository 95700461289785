*,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

p {
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

svg {
    height: 100%;
	width: 100%;
}