@charset "utf-8";

/* CSS Document */

span#messages, 
span#messages-on {
    background: url("/dam/espacejeux/resources/images/ui/enveloppe.png") no-repeat scroll center top transparent;
}
span#messages, 
span#messages-on {
    width: 32px;
    height: 19px;
    display: block!important;
    float: left;
    margin-right: 10px;
    text-indent: -10px -10px;
    position: relative;
    top: 8px;
}

.ej.badge {
	color: #222;
	background-color: #FFCD22;
	margin-top: 6px;
    margin-left: -6px;
}

#jsddm {
    float: right;
    margin-right: 15px;
    margin-top: 21px;
    margin-bottom: 0px;
    li {
        float: left;
        font: 12px Tahoma, Arial;
        height: 32px;
        a {
            display: block;
            margin: 0;
            color: none;
            text-decoration: none;
            height: 32px;
        }
        ul {
            margin: 0;
            padding: 10px 10px 0;
            position: absolute;
            display: none;
            background: #000000;
            z-index: 999999;
            li {
                float: none;
                height: 32px;
                a {
                    width: auto;
                }
            }
        }
    }
}

body.fr {
    #jsddm {
        li {
            ul {
                width: 187px;
                margin: 0 0 0 -15px;
            }
            &:last-child {
                ul {
                    width: 177px;
                    margin: 0px 0 0 -4px;
                }
            }
        }
    }
    .menu-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/fr/menu-sprite.png");
    }
    div.menu-label-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/fr/menu-sprite.png");
        float: right;
    }
    a.menu-label-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/fr/menu-sprite.png");
        float: right;
    }
}

body.en {
    #jsddm {
        li {
            ul {
                width: 187px;
            }
        }
    }
    .menu-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/en/menu-sprite.png");
    }
    div.menu-label-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/en/menu-sprite.png");
        float: right;
    }
    a.menu-label-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/en/menu-sprite.png");
        float: right;
    }
}

#menu-up {
    li {
        float: right;
        font-size: 13px;
    }
    li#devenez-membre {
        width: 133px;
    }
}

.menu-logo-sprite {
    background-image: url("/dam/espacejeux/resources/images/ui/menu-logo-sprite.png");
}

a.poker {
    text-indent: -3333px;
    background-position: 0 -296px;
    width: 84px;
    &:hover {
        background-position: -183px -296px;
        width: 84px;
    }
}

a.casino {
    text-indent: -3333px;
    background-position: 0 -95px;
    width: 104px;
    &:hover {
        background-position: -183px -95px;
        width: 104px;
    }
}

a.bingo {
    text-indent: -3333px;
}

a.jeuxdetable {
    text-indent: -3333px;
    background-position: 0 -164px;
    width: 160px;
    &:hover {
        background-position: -183px -164px;
        width: 160px;
    }
}

a.parisportif {
    text-indent: -3333px;
}

a.loteries {
    text-indent: -3333px;
}

a.parisportif-moj {
    text-indent: -3333px;
}

a.parisportif-pool-moj {
    text-indent: -3333px;
}

div#devenez-membre {
    text-indent: -3333px;
}

div#depot {
    text-indent: -3333px;
}

div#mon-compte {
    text-indent: -3333px;
}

div#deconnexion {
    text-indent: -3333px;
}

a.MAS {
    text-indent: -3333px;
    background-position: 0 -196px;
    width: 160px;
    &:hover {
        background-position: -183px -196px;
        width: 160px;
    }
}

div.devenir-membre-button {
    background-image: url(/dms/resources/images/ui/petit-bouton.png);
}

a.poker.menu_on {
    background-position: -183px -296px;
    width: 84px;
}

a.casino.menu_on {
    background-position: -183px -95px;
    width: 104px;
}

a.MAS.menu_on {
    background-position: -183px -196px;
    width: 160px;
}

a.jeuxexpress.menu_on {
    background-position: -183px 2px;
    width: 160px;
}

a.jeuxdetable.menu_on {
    background-position: -183px -164px;
    width: 160px;
}

body.fr {
    a.parisportif {
        background-position: 0 -263px;
        width: 152px;
        &:hover {
            background-position: -183px -263px;
            width: 152px;
        }
    }
    a.parisportif.menu_on {
        background-position: -183px -263px;
        width: 152px;
    }
    a.parisportif-moj {
        background-position: 0 -742px;
        width: 153px;
        &:hover {
            background-position: -183px -742px;
            width: 150px;
        }
    }
    a.parisportif-moj.menu_on {
        background-position: -183px -742px;
        width: 150px;
    }
    a.parisportif-pool-moj {
        background-position: 0 -769px;
        width: 150px;
        &:hover {
            background-position: -183px -769px;
            width: 153px;
        }
    }
    a.parisportif-pool-moj.menu_on {
        background-position: -183px -769px;
        width: 153px;
    }
    a.loteries {
        background-position: 0 -233px;
        width: 105px;
        &:hover {
            background-position: -183px -233px;
            width: 105px;
        }
    }
    a.loteries.menu_on {
        background-position: -183px -233px;
        width: 105px;
    }
    a.bingo {
        background-position: 0 -28px;
        width: 80px;
        &:hover {
            background-position: -183px -28px;
            width: 80px;
        }
    }
    a.bingo.menu_on {
        background-position: -183px -28px;
        width: 80px;
    }
}

body.en {
    a.parisportif {
        background-position: 0 -263px;
        width: 182px;
        &:hover {
            background-position: -183px -263px;
            width: 182px;
        }
    }
    a.parisportif.menu_on {
        background-position: -183px -263px;
        width: 182px;
    }
    a.parisportif-moj {
        background-position: 0 -742px;
        width: 150px;
        &:hover {
            background-position: -183px -742px;
            width: 150px;
        }
    }
    a.parisportif-moj.menu_on {
        background-position: -183px -742px;
        width: 150px;
    }
    a.parisportif-pool-moj {
        background-position: 0 -769px;
        width: 153px;
        &:hover {
            background-position: -183px -769px;
            width: 153px;
        }
    }
    a.parisportif-pool-moj.menu_on {
        background-position: -183px -769px;
        width: 153px;
    }
    a.loteries {
        background-position: 0 -233px;
        width: 114px;
        &:hover {
            background-position: -183px -233px;
            width: 114px;
        }
    }
    a.loteries.menu_on {
        background-position: -183px -233px;
        width: 114px;
    }
    a.bingo {
        background-position: 0 -28px;
        width: 80px;
        &:hover {
            background-position: -183px -28px;
            width: 80px;
        }
    }
    a.bingo.menu_on {
        background-position: -183px -28px;
        width: 80px;
    }
}

.silver-border {
    background-position: 0px -2px;
    width: 1px;
    margin: 0 7px;
    padding: 5px 2px;
}

ul#menu-up{
    display: none;
}

/*********************
Menu vertical gauche
*********************/

ul.menuVertical {
    list-style: none;
    background-color: #131313;
    li {
        margin: 0;
        line-height: 16px;
        font-size: 13px;
        background: url(/__ressources/images/zone-menu-gauche-separateur-elements-menu.png) left bottom no-repeat;
        padding: 1px 10px 10px;
        >a {
            color: #a8a8a8;
            text-decoration: none;
        }
        >a.activeLink {
            color: white;
        }
    }
}

.icomoon.icon-cross.close {
    font-size: 12px;
}

.moreInfoButton {
    padding-right: 12px;
}



#section_logo {
    .section_logo_sep {
        min-height: 20px;
    }
}