
div#zonePied {
    clear: both;
    font-size: 11px;
    color: #FFF;
    margin: 0 10px 0 6px;
    padding: 0 10px;
}

div#zonePiedZoneNavigation {
    a {
        color: $ej-color-gray;
    }
    padding: 10px 0 0 0;
}

div#zonePiedZoneLogos {
    a {
        color: gray;
    }
}

div#zonePiedWeb2 {
    clear: both;
    display: block;
    float: left;
    width: auto;
    height: auto;
    padding: 13px 0 0 0;
}

div#zonePiedWeb2SuivezNous {
    display: block;
    float: left;
    width: auto;
    height: auto;
    min-height: 20px;
    padding: 13px 11px 0 0;
    font-size: .85em;
    font-weight: bolder;
    line-height: normal;
}

ul#zonePiedWeb2SitesReseautage {
    display: block;
    float: left;
    width: auto;
    height: auto;
    li {
        display: block;
        float: left;
        width: auto;
        height: auto;
        a {
            img {
                border: none;
            }
        }
    }
}

div#zonePiedWeb2Partagez {
    display: block;
    float: left;
    width: auto;
    height: auto;
    min-height: 20px;
    padding: 13px 4px 0 29px;
    font-size: .85em;
    font-weight: bolder;
    line-height: normal;
}

ul#zonePiedWeb2SitesPartage {
    display: block;
    float: left;
    width: auto;
    height: auto;
    li {
        display: block;
        float: left;
        width: auto;
        height: auto;
        a {
            img {
                border: none;
            }
        }
    }
}

div#zonePiedZoneCertifications {
    display: block;
    float: right;
    width: auto;
    height: auto;
}

ul#zonePiedZoneModesPaiementListe {
    display: block;
    float: left;
    width: auto;
    height: auto;
    li {
        display: block;
        float: left;
        width: auto;
        height: auto;
        padding: 0 5px 0 0;
        img {
            border: none;
            margin: 3px 0 0 0;
        }
        img#logoEntrust {
            border: none;
            margin: 0 5px 0 0;
        }
    }
}

div#zonePiedZoneSeparateurH {
    clear: both;
    display: block;
    background: #444444 repeat-x left top;
    height: 1px;
}

div#zonePiedZoneContact {
    clear: both;
    display: block;
    height: auto;
    padding: 0 0 5px 0;
    li#contact {
        float: right;
    }
    li#logos {
        float: left;
        padding: 10px 0 0 0;
    }
}

div#produits {
    div.siteMap {
        display: block;
        float: left;
        margin-top: 10px;
        padding: 0 0 20px 0;
    }
}

div#informations {
    div.siteMap {
        display: block;
        float: left;
        margin: 16px 17px 0 0;
        padding: 0 0 20px 0;
    }
}

div#zonePiedZoneNavigation {
    div.siteMap {
        titre {
            color: #FFFFFF;
            display: block;
            font-size: 11px;
            font-weight: bold;
            height: 18px;
            margin: 0 0 10px 0;
        }
        strong {
            margin: 0 0 10px 0;
            display: block;
            color: #fff;
            &:hover {
                text-decoration: underline;
            }
        }
        a {
            &:hover {
                color: #fff;
            }
            text-decoration: none;
        }
        ul {
            li {
                margin: 2px 0 0 0;
                line-height: normal;
            }
        }
    }
    div.siteMap.separateur {
        background: url(/__ressources/images/Jeu_responsable-pointille-vertical.png) left top repeat-y;
        width: 1px;
        height: 90px;
        margin: 0 20px 0 0;
    }
    div#produits {
        float: left;
    }
}

div#zonePiedZoneLogos {
    display: block;
    margin: 15px 0 0 0;
    div.logo-footer {
        display: block;
        float: left;
        padding-right: 10px;
    }
}

div#zonePiedZoneInfoLegales {
    clear: both;
    font-size: 9px;
    color: #8B8B8B;
    font-size: 10px;
}

body.fr {
    div.footer-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/fr/menu-sprite.png");
        background-repeat: no-repeat;
    }
    div.responsible-gaming {
        width: 168px;
    }
}

body.en {
    div.footer-sprite {
        background-image: url("/dam/espacejeux/resources/images/ui/en/menu-sprite.png");
        background-repeat: no-repeat;
    }
    div.responsible-gaming {
        width: 190px;
    }
}

.footer-logo-sprite {
    background-image: url("/dam/espacejeux/resources/images/ui/menu-logo-sprite.png");
    background-repeat: no-repeat;
}

div.follow-us {
    width: 133px;
    height: 15px;
    background-position: 0px -584px;
    margin: 0 0 10px 0;
    float: left;
}

div.responsible-gaming {
    height: 31px;
    background-position: 0px 2px;
    margin: 0 0 10px 0;
}

div#produits {
    div.produitsLbl {
        width: 90px;
        height: 21px;
        background-position: 0px -543px;
    }
}

div#informations {
    div.informationsLbl {
        width: 90px;
        height: 15px;
        background-position: 0px -567px;
    }
}

div#boitePied {
    background-color: black;
    padding-top: 6px;
}

body.en {
    span#footer-item-espacejeux {
        left: 315px;
    }
    span#footer-item-poker1 {
        left: 367px;
    }
    span#footer-item-poker2 {
        left: 628px;
    }
    span#footer-item-casino {
        left: 510px;
    }
    span#footer-item-mas {
        left: 490px;
    }
    span#footer-item-jeux-express {
        left: 424px;
    }
    span#footer-item-jeux-de-table {
        left: 362px;
    }
    span#footer-item-pfe {
        left: 344px;
    }
    span#footer-item-loteries {
        left: 251px;
    }
    span#footer-item-evenements {
        left: 463px;
    }
}

body.fr {
    span#footer-item-espacejeux {
        left: 340px;
    }
    span#footer-item-poker1 {
        left: 395px;
    }
    span#footer-item-poker2 {
        left: 745px;
    }
    span#footer-item-casino {
        left: 445px;
    }
    span#footer-item-mas {
        left: 644px;
    }
    span#footer-item-jeux-express {
        left: 404px;
    }
    span#footer-item-jeux-de-table {
        left: 360px;
    }
    span#footer-item-pfe {
        left: 364px;
    }
    span#footer-item-loteries {
        left: 249px;
    }
    span#footer-item-evenements {
        left: 548px;
    }
}

body.ej-paris-sportifs {
    div#contenu_pied {
        div.boiteTypeTroisMetalEntete {
            background: url("/dms/resources/images/barre-1000px.png") no-repeat scroll left top transparent;
            margin-top: 20px;
            font-size: 1px;
            height: 12px;
            line-height: 1px;
            width: 1000px;
        }
        div.boiteTypeTroisCorps {
            width: 1000px;
        }
    }
    div.boiteTypeTroisCorps {
        padding-left: 0;
        padding-right: 0;
    }
    div#zonePied {
        margin: 0;
        padding: 0;
    }
    div#zonePiedZoneSeparateurH {
        margin-top: 10px;
    }
}

/* Le CSS qui suit est bon dans Magnolia 5 */
/* ce qui n'est pas assurément le cas pour ce qui précède */
#footer-content {
    font: 1.2em/1.2em 'Tahoma', Arial, Helvetica, sans-serif;
    color: white;
    background: none repeat scroll 0 0 #4a4952;
    box-shadow: 0 0 10px #000000 inset;
    display: none;
    margin: 0 -15px;
    padding: 0px 15px 15px;
    ul {
        content: " ";
        display: table;
        list-style: none outside none;
        margin: 0;
        width: 100%;
    }
    li {
        float: left;
        list-style-position: outside;
        padding: 0;
    }
    li a {
        color: #fff;
        font: 1.2em/1.2em 'Tahoma', Arial, Helvetica, sans-serif;
    }
    strong:hover,
    a:hover {
        text-decoration: underline;
        color: white;
    }
    div.menuItem--desktop {
        font-size: 8pt;
    }
    div.menuSousItem a {
        color: lightgray;
        font-size: 8pt;
    }
    div.menuSousItem--desktop a {
        font-size: 8pt;
    }
    
   
    
    div#zonePiedZoneTexte {
        padding: 5px 0;
        color: #8B8B8B;
        font-family: Verdana, sans-serif;
        font-size: 10px;
        a {
            color: $ej-color-gray;
            text-decoration: underline;
        }
        h1 {
            display: block;
            margin: 15px 0 5px 0;
            color: #ffffff;
            font-weight: bold;
            font-size: 11px;
            cursor: pointer;
        }
        h2 {
            display: block;
            margin: 15px 0 5px 0;
            color: #ffffff;
            font-weight: bold;
            font-size: 11px;
            cursor: pointer;
        }
        strong {
            font-weight: normal;
        }
        .form-list li {
            position: relative;
            display: block;
            clear: both;
        } /* Par défaut, on cache la zone de texte SEO */
        ul.box-collapse-limite {
            display: none;
        }
    }
    div.footer-copyright {
        margin-top: 10px;
        font-size: smaller;
    }
}

.span-regles{ 
        color: teal;
}

.footer-logos {
    img {
        margin: 0;
    }
}

.social_vertical_align {
    padding-top: 5px;
    vertical-align: bottom;
}

.jeu-responsable-vertical-align {
    margin-bottom: -10px;
}

.expand-footer {
    vertical-align: middle;
    text-align: center;
    padding-top: 3px;
    display: inline-block;
    cursor: pointer;
    &:hover {
        color: white;
    }
}

a.icon-plus {
    &:before {
        padding: 3px 5px 5px 3px;
        border-radius: 5px;
        color: transparent;
        background-color: white;
    }
}

.footer-icon-info{
	font: 1.1em/1.9em "Tahoma",Arial,Helvetica,sans-serif;
	cursor: pointer;
}