
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.grilleTooltip.tooltip {
  &.bs-tooltip-top {
    top: -6px !important;
  }
  &.bs-tooltip-bottom {
    top: 6px !important;
  }
  .tooltip-inner {
    color: black;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    padding: 4px 15px 5px 15px;
    font-family: $font-graphik-regular;
    font-size: 12px;
    font-weight: bold;
    background-color: white;
  }
}

.grilleTooltip .arrow::before {
  border-width: 0.6rem 0.4rem 0 !important;
}
.theme-bg-ced .section-page .filtre {
  display: flex; //pour mettre la grille et le filtre sur la même ligne
  .col-2.d-none.d-xl-flex {
    margin-left: -16.7%;
    justify-content: flex-end;
  }
  .col-12.d-none.d-xl-flex {
    justify-content: flex-end;
  }
}

.theme-bg-ced .section-page .sticky {
  .filtreBar {
    font-family: $font-graphik-regular;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    color: white;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    &:last-child {
      @media (max-width: 767px) {
        margin-bottom: 5px; //mettre de l'espace entre les filtres et le barSlider
      }
    }
    &.centrer {
      @include media-breakpoint-up(md) {
        justify-content: center;
      }
    }
    &.left {
      &:last-child {
        margin-top: 5px; //mettre de l'espace entre les filtres dans le cas de deux
      }
      @include media-breakpoint-up(xl) {
        //grille n'existe qu'à partir de 1200px
        margin-left: 16%;
      }
    }
    .tablesOuverts {
      font-size: 1.1vw;
      height: calc(25px + 0.4vw);
      font-weight: normal;
      margin-right: -4px;
      padding-left: 4vw;
      padding-right: 4vw;
      align-items: center;
      display: flex;
      &.radiusGauche:not(.active) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
      @include media-breakpoint-up(sm) {
        height: calc(23px + 0.4vw);
        padding-left: 3.25vw;
        padding-right: 3.25vw;
        margin-right: -3px;
      }
      @include media-breakpoint-up(md) {
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }
      @include media-breakpoint-up(xl) {
        font-size: calc(14px + 0.02vw);
      }
      @include media-breakpoint-up(xxl) {
        font-size: calc(14px + 0.02vw);
        padding-right: 2vw;
        padding-left: 2vw;
      }
      @include media-breakpoint-up(xxxl) {
        font-size: calc(14px + 0.02vw);
        padding-right: 30px;
        padding-left: 30px;
      }
    }
    button.filtreBarButton {
      height: calc(25px + 0.4vw);
      padding-left: 4vw;
      padding-right: 4vw;
      margin-right: -4px; //pour avoir une impression de continuité entre les choix du filtre
      margin-left: -4px; //pour avoir une impression de continuité entre les choix du filtre
      font-weight: bold;
      font-size: 1.2vw;
      letter-spacing: 0px;
      border: none;
      color: white;
      @include media-breakpoint-up(sm) {
        height: calc(23px + 0.4vw);
        padding-left: 3.25vw;
        padding-right: 3.25vw;
        margin-right: -3px; //pour avoir une impression de continuité entre les choix du filtre
        margin-left: -3px;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.3vw;
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }
      @include media-breakpoint-up(xl) {
        font-size: calc(14px + 0.027vw);
      }
      @include media-breakpoint-up(xxl) {
        font-size: calc(16px + 0.02vw);
        padding-right: 2vw;
        padding-left: 2vw;
      }
      @include media-breakpoint-up(xxxl) {
        font-size: calc(16px + 0.02vw);
        padding-right: 30px;
        padding-left: 30px;
      }
      &.active {
        color: black;
        position: relative;
        color: black;
        border-radius: 6.2vw;
        -webkit-border-radius: 6.2vw;
        -moz-border-radius: 6.2vw;
        height: calc(30px + 0.5vw);
        font-weight: bold;
        @include media-breakpoint-down(sm) {
          border-radius: 3vw;
          -webkit-border-radius: 3vw;
          -moz-border-radius: 3vw;
        }
        @include media-breakpoint-up(sm) {
          height: calc(35px + 0.5vw);
        }
      }
      &.radiusGauche:not(.active) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }
    .filtreBarButton:last-child:not(.active) {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .centrerFiltre .filtre/*.row.col-12*/ {
    &.blackjack,
    &.roulette,
    &.baccara,
    &.des,
    &.poker,
    &.jeuxendirect {
      padding-right: 0px;
    }
  }
  .filtre .grille {
    display: flex;
    align-items: center;
    padding-top: 5px; //semble un peu décalé
  }
  .filtre .col-12 .grille,
  .filtre .col-2 .grille {
    @media (max-width: 1350px) {
      margin-right: 50px;
    }
  }
  .filtre .grille svg {
    height: calc(17px + 0.4vw);
    width: calc(17px + 0.4vw);
    margin-right: 10px;
    fill: white;
    border: 0px;
    outline: none;
  }
  .filtre .grille svg:last-child {
    margin-right: 0px;
  }
  .blackjack {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #00a389 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #00a389;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #00a389 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #205447;
    }
  }
  .roulette {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #0a71f0 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #0a71f0;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #0a71f0 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #163b75;
    }
  }
  .jeuxendirect {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #01aef0 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #01aef0;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #01aef0 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #194ea1;
    }
  }
  .baccara {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #f03447 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #f03447;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #f03447 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #bc122e;
    }
  }
  .poker {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #4f71f8 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #4f71f8;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #4f71f8 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #3135a6;
    }
  }
  .des {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #d63a93 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #d63a93;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #d63a93 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #a51c5d;
    }
  }
}
.domain-mobile .theme-bg-ced .filtreBar .filtreBarButton:first-child:not(.active) {
  @media (max-width: 767px) {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.domain-mobile .theme-bg-ced .filtre {
  text-align: center;
  @media (max-width: 767px) {
    .filtreBar .tablesOuverts {
      display: none;
    }
  }
  .filtreBar {
    justify-content: left;
    .tablesOuverts {
      font-size: 2vw;
      height: calc(25px + 0.4vw);
      padding-left: 4vw;
      padding-right: 4vw;
      height: 5vw;
      @include media-breakpoint-up(md) {
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: 1.5vw;
        height: 3.5vw;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.1vw;
        height: 3vw;
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }
    }
    button.filtreBarButton {
      font-size: 2.2vw;
      padding-left: 4vw;
      padding-right: 4vw;
      height: 5vw;
      @include media-breakpoint-up(xs) {
        padding-left: 4.25vw;
        padding-right: 4.25vw;
        font-size: 2vw;
        height: 4.5vw;
      }
      @include media-breakpoint-up(md) {
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: 1.6vw;
        height: 3.5vw;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.25vw;
        height: 3vw;
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }

      &.active {
        border-radius: 6.2vw;
        -webkit-border-radius: 6.2vw;
        -moz-border-radius: 6.2vw;
        height: 6.75vw;
        @include media-breakpoint-up(md) {
          height: 5vw;
        }
        @include media-breakpoint-up(lg) {
          height: 4.25vw;
        }
      }
    }
    &.centrer {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
