
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

  
    #body-wrapper {
      background-color:#00407d;
			color: #fff;
			font-family : 'Lato', sans-serif;
			margin:0;
			padding:0;
		}
		#wrapper {
      background-color:#00407d;
			color: #fff;
			font-family : 'Lato', sans-serif;
			margin:0;
			padding:0;

			display: block;
			font-size: 10px;
			margin: 0 auto;	
			max-width: 44em;			
			text-align:center;
      width: 100%;

      padding-top:8rem;

      a, 
      a:link, 
      a:visited {
        color: #a1dbd2;
        text-decoration: underline;
      }
      a:focus, 
      a:hover{
        color: #fff;
      }	

		}
		#boxer{
			font-size:1.8em;
			line-height:1.8em;
			padding:0 1em;
		}
		#boxer .img-wrapper{
			border-bottom: dotted 0.1em #6a839b;
			border-top: dotted 0.1em #6a839b;
			//margin-top:8em;
		}
		#boxer .img-wrapper img{
			height:2.5em;
			margin:1em 0 0.5em;
		}
		#boxer p{
			margin:0;
		}	
		
		#boxer .center-wrapper{
			margin: 0 0.5em;
		}
		#boxer .titre{
			font-size:1.1em;
			line-height:1.1em;
			margin-top:1em;
		}	
		#boxer .bottom-wrapper{
			border-top: dotted 0.1em #6a839b;
			font-size:0.7em;
			line-height:1.8em;
			margin-top:2em;
			padding-top:1em;
		}
		
		@media screen and (max-width: 767px){
			#boxer .img-wrapper{
				margin-top:1em;
			}
			#boxer .img-wrapper img{
				height:2em;
			}			
		}	

