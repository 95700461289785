.menu {
	background-color: #091A38;
	min-height: 50px;
	position: relative;
	transition-property: width;
	width: 100%;
	z-index: zindex(dropdownMenu);

	.nav-content-container {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		position: relative;
		justify-content: space-between;

		@include media-breakpoint-up(lg) {
			align-items: flex-start;
			flex-wrap: wrap;
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
			width: calc(100% - 10px);
		}
	}

	.menu-label {
		margin-left: 1rem;

		@include media-breakpoint-up(lg) {
			margin-left: 0;
			margin-right: 6rem;
		}

		a {
			display: block;
		}

		h2 {
			color: #fff;
			font-family: "graphik_black", Arial, Helvetica, sans-serif;
			font-size: 2.8rem;
			line-height: 1.6;
			margin-bottom: 0;
			margin-top: 0;

			@include media-breakpoint-up(lg) {
				font-size: 4rem;
				line-height: 1;
			}
		}
	}

	.burger-menu {
		appearance: none;
		background: transparent;
		border: 0 none;
		display: block;
		min-height: 5rem;
		margin-right: 1rem;
		outline: 0;
		padding: 0;
		width: 2.4rem;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			display: none;
		}

		.burger-inner {
			display: block;
			height: 2.4rem;
			width: 100%;
		}

		.burger-img,
		.img-close {
			height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}

	.menu-container {
		background-color: #091A38;
		height: auto;
		transition: all 0.1s;

		@include media-breakpoint-down(md) {
			overflow: hidden;
			padding: 0 1.5rem;
			position: relative;
			opacity: 0;
			text-align: center;
			transition: opacity 0.5s ease-out;
			width: 100%;
		}

		@include media-breakpoint-up(lg) {
			flex-grow: 1;
		}
	}

	.menu-item-wrapper {
		margin: 0;

		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}

	.menu-item__btn {
		align-items: center;
		appearance: none;
		background-color: transparent;
		border: none;
		color: $color-w1;
		display: flex;
		font-family: $font-graphik-medium;
		font-variant: small-caps;
		letter-spacing: 0.5px;
		outline: none;
		padding: 0;
		text-align: center;
		text-transform: lowercase;
		justify-content: space-between;
		width: 100%;

		@include media-breakpoint-down(md) {
			font-size: 2.4rem;
			line-height: 2.5;
		}

		@include media-breakpoint-up(lg) {
			border-bottom: 2px solid rgba(255, 255, 255, 0.12);
		}

		&:hover {
			&:after {
				transition: transform 0.35s;
			}
		}

		&:after {
			height: 1.9rem;
			content: '';
			border-bottom: 2px solid #fff;
			border-right: 2px solid #fff;
			transform: rotate(45deg);
			transition: transform 0.05s;
			width: 1.9rem;
		}

		&>a {
			text-align: left;
		}

		.media-icon {
			padding-left: 1vw;
		}

		>img {
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}

	#listLiens {
		padding-right: 0;

		li {
			a {
				span.lienText {
					font-family: $font-graphik-medium;
					color: $color-w1;
					font-size: 1.5rem;
					line-height: 1rem;
				}
			}
		}
	}
}

.menu:not(.active) {
	.menu-container {
		@include media-breakpoint-down(md) {
			max-height: 0;
		}
	}
}

.menu.active {

	.burger-img {
		display: none;
	}

	.menu-container {
		border: 3px solid black;
		opacity: 1;
		visibility: visible;
	}
}

.menu {

	&.active {
		background-color: $bleu-marin-casino;

		.menu-inner {
			height: auto;
		}

		.sec-menu-wrapper {
			visibility: visible;
			opacity: 1;
			margin-top: 50px;
		}

		.mobile-menu-bar {
			#icon-mobile-burger-menu {
				display: none;
			}

			#icon-mobile-close-menu {
				display: block;
				width: 3.73333vw;
				height: 3.73333vw;
			}

			button>div,
			button>.burger-inner,
			button>.jackpots-inner {
				margin: 1.33333vw 0 0 1.73333vw;
			}
		}
	}
}

.submenu-wrapper {

	@include media-breakpoint-up(lg) {
		background-color: #091A38;
		border-left: 2px solid;
		border-right: 2px solid;
		border-bottom: 2px solid;
		border-color: #fff;
		margin-top: -0.3em;
	}

	ul {
		display: block;

		@include media-breakpoint-up(lg) {
			padding-top: 1.5em;
		}

		+ul>li a:first-child {
			border-top: 1px solid rgba(255, 255, 255, 0.36);
		}
	}

	li {
		font-family: $font-graphik-medium;
		font-size: 4.53333vw;
		line-height: 11.73333vw;
		color: rgba(179, 179, 179, 1);
		position: relative;
		margin: 0 -4.66667vw;
		text-align: left;

		&:last-child a {
			border-bottom: none;
		}
	}

	a {
		border-bottom: 1px solid rgba(255, 255, 255, 0.36);
		color: $color-blanc;
		display: flex;
		flex-wrap: wrap;
		font-size: 1.65rem;
		line-height: 3;
		padding-left: 2rem;
		padding-right: 2rem;

		@include media-breakpoint-up(lg) {
			font-size: 1.4rem;
			line-height: 1.42;
		}

		&:after {
			height: 0.1rem;

			@include media-breakpoint-down(md) {
				content: '';
				width: 100%;
			}
		}
	}
}

.menu-item {
	text-align: center;
	vertical-align: middle;
	display: block;
	padding: 0;

	.arrow-menu {
		fill: currentColor;

		use {
			fill: currentColor;
		}
	}

	img {
		height: 1.1rem;
		width: 1.5rem;
		vertical-align: middle;
	}

	a.menu-item__btn:after {
		display: none;
	}

	&.active {
		border-bottom: none;

		ul {
			visibility: visible;
		}

		>a:after {
			width: 10.66667vw;
		}

		.menu-item__btn:after {
			transform: rotate(225deg) translate(-0.4rem, -0.4rem);
		}

		>a,
		.menu-item__btn {
			border-bottom: none;
		}
	}

	>a {
		transition: color 0.3s;

		position: relative;
		display: block;
		z-index: 1;
	}

	.arrow-menu {
		display: none;
	}
}

.promo-container {
	display: none;
}

@include media-breakpoint-down(md) {

	.menu {

		.submenu-wrapper {
			li {
				font-size: 1.65rem;
				line-height: 5rem;
				margin: 0;

				a {
					margin: 0;
					text-align: left;
				}

				&.selected {
					a {
						font-family: 'graphik_semibold', Arial, Helvetica, sans-serif !important;
						color: $color-n1;
						background-color: $color-w1;
					}
				}
			}

			li:last-child {
				a:after {
					display: none;
				}
			}
		}

		.menu-inner {
			margin-top: 1.5em;

			>.menu-item-wrapper {
				li.menu-item:last-child {
					border-bottom: none;
				}

				.menu-item.selected {
					>.menu-item__btn {
						font-family: 'graphik_bold', Arial, Helvetica, sans-serif !important;
					}
				}
			}
		}

		.enteteSectionComponent {
			display: none;
		}

		.menu-item {
			border-bottom: 1px solid black;

			&.active {
				.menu-item__btn {
					border-bottom: 2px solid white;
				}
			}

			&:first-child .show-for-sr {
				transform: translateY(+90%);
			}

			.icon-wrapper {
				margin-top: 0.5vw;
				margin-bottom: 0.5vw;
				height: 16vw;

				img {
					display: none;
				}

				.show-for-sr {
					left: 0;
					font-size: 5vw;
					width: auto;
					height: auto;
					clip: auto;
					display: block;
					overflow: visible;
					position: absolute !important;
					bottom: 50%;

					transform: translateY(+50%);
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.menu-container {
		padding: 0;

		a:after {
			bottom: -0.4rem;
			height: 0.4rem;
		}
	}

	.mobile-menu-bar {
		display: none;
		padding: 0;
	}

	.menu {
		min-height: 36px;
		max-height: 95px;
		background-color: $bleu-marin-casino; // ICI
		overflow: visible;
		transition-property: width, height, border-color;

		&.active {
			overflow: hidden;
			padding-top: 0 1em;
			background-color: $bleu-marin-casino; // ICI
			opacity: 1;
		}

		.mobile-menu-bar {
			display: none;
		}

		.enteteSectionComponent {
			a {
				display: block;
			}
		}
	}

	.menu-inner {
		max-width: 100%;
		margin: 0 auto;
		text-align: center;

		>ul {
			top: 0.6rem;
			position: relative;
		}
	}

	.submenu-wrapper {
		visibility: hidden;
		position: absolute;
		width: 100%;

		ul {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			margin-top: 0.2em;

			+ul {
				>li:first-child {
					border-top: none;
				}
			}
		}

		li {
			border-bottom: 0;
			font-size: 1.4rem;
			line-height: 1.42;

			&:first-child {
				padding-top: 0;
			}

			&:hover {
				color: rgba(255, 255, 255, 1);
				background-color: rgba(0, 0, 0, 1);
			}

			a {
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
				color: $color-noir;
				transition: all 0.3s linear;
				padding-left: 0.4em;
				padding-right: 0.4em;

				&:hover {
					color: rgba(255, 255, 255, 1);
					background-color: rgba(0, 0, 0, 1);
				}

				&:after {
					height: 0.2rem;
					bottom: -0.4rem;
				}
			}
		}
	}

	.sec-menu-wrapper {
		position: fixed;
		right: 0;
		z-index: 10;
		text-align: right;

		margin-top: 50px;

		padding: 0;
		visibility: visible;
		opacity: 1;

		li {
			display: inline-block;
		}

		a {
			line-height: 1;
		}
	}

	.menu-item-wrapper {
		opacity: 1;

		.icon-wrapper {
			margin: 0 auto !important;
		}
	}

	.menu-item {
		position: relative;
		border-bottom: none;
		display: inline-block;
		width: 15.7%;
		height: 100%;
		padding-left: 0;
		padding-right: 0;

		.menu-item__btn {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border: none;
			font-size: 1.7rem;
			line-height: 1;
			margin: 0;
			padding-left: 0;
			padding-right: 0;

			&:after {
				display: none;
			}

			.arrow-menu {
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -0.4rem;
				right: 0.2rem;
				width: 1.2rem;
				height: 0.8rem;
			}

			&.cl-primaire {
				>img {
					margin-top: -1px;
				}
			}

			>img {
				margin-left: 2px;
				margin-right: 4px;
			}
		}

		>a,
		.menu-item__btn {
			padding: 0;
			background-color: transparent;
			border-bottom: none;
			position: relative;

			margin: 0 auto a {
				border-bottom: none;
				margin: 0;
			}
		}

		.submenu-wrapper li {
			margin: 0;
			background-color: transparent;

			a {
				border-bottom: none;
				margin: 0;
				padding-bottom: 1rem;
				padding-top: 1rem;
			}
		}

		.menu-item__btn {
			height: 3.8rem;
		}

		a.menu-item__btn {
			padding: 0.7em 0;
		}

		&:hover {
			>a.menu-item__btn {
				border: 2px solid $color-w1;

				img {
					transform: rotate(180deg);
					transition: all 0.3s linear;
				}
			}
		}

		&.active {
			>.menu-item__btn {
				border: 2px solid $color-w1;
				border-bottom: none;

				img {
					transform: rotate(180deg);
					transition: all 0.3s linear;
				}
			}

			>.submenu-wrapper {
				visibility: visible;
			}
		}

		&.selected {
			>.menu-item__btn {
				border: 2px solid $color-w1;
			}
		}

		&.selected.active {
			>.menu-item__btn {
				border-bottom: none;
			}
		}

		>a {
			padding: 1em 0;
		}
	}

	.menu {
		width: 100%;

		.sec-menu-wrapper {
			position: static;
			background: transparent;
			width: auto;
			display: block;
			float: right;
			margin: 0;

			margin-top: 50px;
			padding: 0;

			.lq-links,
			.offer-links {
				position: relative;
				margin: 0;
				padding: 0;
				display: block;
				right: 0;
			}

			.offer-links {
				top: 1.8rem;
			}
		}

		.menu-item-wrapper {
			width: 100%;

			.offers-component {
				padding-top: 3rem;
			}
		}

		.submenu-wrapper {
			li {

				&:hover,
				&.selected {
					background-color: rgba(255, 255, 255, 1);

					a {
						color: rgba(0, 0, 0, 1);
						background-color: rgba(255, 255, 255, 1);
					}
				}

				a {
					white-space: normal;
					overflow: visible;
					color: $color-blanc;
					transition: all 0.3s linear;
				}
			}
		}

		&.menu-editor {
			li.menu-item {
				width: 100%;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.menu {
		.menu-item {
			margin-left: 0.25rem;
			margin-right: 0.25rem;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@include media-breakpoint-up(xl) {
	.menu {
		.menu-item-wrapper {
			text-align: center;
		}

		.menu-item {
			padding-left: 0;
			padding-right: 0;

			>a {
				max-width: none;
			}
		}
	}

	.submenu-wrapper {
		li {
			a {
				overflow: visible;
			}
		}
	}
}

@include media-breakpoint-up(xxl) {
	.menu {
		.menu-item {
			padding-left: 0;
			padding-right: 0;

			>a {
				max-width: none;
			}
		}
	}
}

// @include page-theme-colors {
// 	@include media-breakpoint-down(sm) {
// 		.submenu-wrapper {
// 			li {
// 				font-family: 'graphik_medium', Arial, Helvetica, sans-serif;
// 			}
// 		}

// 		.menu-item {
// 			&.active {
// 				.menu-item__btn {
// 					font-family: 'graphik_semibold', Arial, Helvetica, sans-serif;
// 				}
// 			}
// 		}
// 	}
// }

// Ajout CSS Menu Pied de Page Casino - Mobile
.navbar-bas {
	overflow: hidden;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
}

.navbar-bas-ate {
	overflow: hidden;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	display: block;

	@media (min-width: 1024px) {
		display: none !important;
	}
}

.list-group-bas {
	display: flex;
	flex-direction: row;
	background-image: none;
	background-color: #f2f7fc;
	position: sticky !important;
}

div.menuBas {
	background: white;
	border-top: 1px solid rgba(21, 38, 101, 0.1);
}

div.list-group-bas .list-group-item.router-link-active {
	border-top: 2px solid #0056ac;
	border-radius: 0;
}

div.list-group-bas .list-group-item {
	border: none;
	color: #020a30;
	background-color: white;
	line-height: 13px;
	font-size: 11px;
	font-family: graphik_regular;
	white-space: nowrap;

	&:hover {
		text-decoration: none;
	}
}

div::v-deep div.list-group-bas {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.list-group-item {
		padding: 0.25rem 0;
		background: inherit;
		max-width: 5rem;
		width: stretch;
		border-top: 2px solid rgba(0, 0, 0, 0);
		top: -1px;

		&.router-link-exact-active {
			border-top: 2px solid #0056ac;
			border-radius: 0;

			.item svg {
				color: #0b5db7;
			}
		}

		.item {
			margin: 0 auto;

			svg {
				font-size: 1.25rem;
				color: #828db5;
			}

			>div {
				font-size: 0.575rem;
				color: #192872;
				font-family: graphik_regular;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.padding-menu-bas {
		padding-top: 21px;
	}
}

div.menu-ouvert.menu-bouton {
	padding: 1.5rem;
}

div.menu-ouvert {
	width: 240px;

	.menu-bouton {
		padding: 1rem;
	}

	::v-deep {
		.menu-ferme {
			display: none;
		}

		div.list-group {
			>div.list-group-item {
				.item {
					display: none;
					padding: 0.5rem 0 0;

					&.menu-titre {
						display: flex;
						flex-direction: column;
						width: -webkit-fill-available;
						text-align: center;

						>div {
							font-size: 0.575rem;
							font-family: graphik_semibold;
							display: block;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							padding: 0 0.25rem;
						}
					}
				}
			}
		}
	}
}

div.menu-ferme {
	max-width: 55px;

	.menu-bouton {
		align-self: center;
	}

	::v-deep {
		.menu-ouvert {
			display: none;
		}

		div.list-group {
			>div.list-group-item {
				.item {
					display: none;
					padding: 0.5rem 0 0;

					&.menu-titre {
						display: flex;
						flex-direction: column;
						width: -webkit-fill-available;
						text-align: center;

						>div {
							font-size: 0.575rem;
							font-family: graphik_regular;
							display: block;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							padding: 0 0.25rem;
						}
					}
				}
			}
		}
	}
}

div.menu-ferme div.list-group .list-group-item .item.menu-titre {
	display: flex;
	flex-direction: column;
	width: -webkit-fill-available;
	text-align: center;
}

div.menu-ferme div.list-group .list-group-item .item {
	display: none;
	padding: .5rem 0 0;
}

div.menu-ferme div.list-group .list-group-item .item.menu-titre>div {
	font-family: graphik_regular;
	font-size: 11px;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 .25rem;
}

div.menu-ouvert div.list-group .list-group-item .item.menu-titre>div {
	font-family: graphik_semibold;
	font-size: 14px;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 .25rem;
}

.navbar-gauche {
	height: auto;
	align-self: flex-start;
	background-color: white;
	z-index: 99;
	top: 0;
	left: 0;
	position: sticky !important;
}

.menu-bouton.img {
	// color: #152665;
	color: #020a30;
	font-size: 1.5rem;
}

.menu-bouton {
	cursor: pointer;
	background-color: transparent;
	border: none;
	text-align: left;
	padding-left: 16px !important;
	padding-right: 16px !important;

	img {
		color: #152665;
		font-size: 1.5rem;
	}
}

.navbar-gauche-ate {
	height: auto;
	align-self: flex-start;
	background-color: white;
	z-index: 99;
	top: 220px;
	left: 0;
	position: sticky !important;

	@media (max-width: 1023px) {
		display: none !important;
	}
}

.menu-bouton-ate.img {
	// color: #152665;
	color: #020a30;
	font-size: 1.5rem;
}

.menu-bouton-ate {
	cursor: pointer;
	background-color: transparent;
	border: none;
	text-align: left;
	padding-left: 16px !important;
	padding-right: 16px !important;

	img {
		color: #152665;
		font-size: 1.5rem;
	}
}

.menu-bouton-ate .burger-img {
	@media (min-width: 1718px) {
		display: none !important;
	}
}

div div.list-group .img-container {
	width: 56px;
}

div.list-group .list-group-item {
	border: none;
	color: #020a30;
	font-family: graphik_medium;
	white-space: nowrap;

	&:hover {
		text-decoration: none;
	}
}

div div.list-group .list-group-item {
	text-decoration: none;
	color: #020a30;
	display: block;
	font-size: .9rem;
	font-family: graphik_regular;
	padding: 0;
}

div.list-group .list-group-item {
	border: none;
	color: #020a30;
	font-family: graphik_medium;
	white-space: nowrap;
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.list-group-item {
	position: relative;
	display: block;
	padding: .75rem 1.25rem;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .125);
}

div div.list-group .list-group-item {
	color: #020a30;
	font-size: .9rem;
	font-family: graphik_regular;
}

div div.list-group .list-group-item .menu-titre {
	font-family: graphik_semibold;
}

div div.list-group .list-group-item .item {
	display: flex;
	font-size: 14px;
	line-height: 17px;
	padding: .9rem 0;

	&:menu-titre-ate {
		font-weight: 600;
	}
}

.menu-titre-ate {
	font-weight: 600;
}


div.list-group .list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 0.75rem;
	background-color: #fff;
}

div.list-group .list-group-item:hover {
	text-decoration: none;
	background-color: #f2f7fc;
}

div::v-deep div.list-group {
	padding-bottom: 1rem;

	.list-group-item {
		text-decoration: none;
		color: #020a30;
		display: block;
		font-size: 0.9rem;
		font-family: graphik_regular;
		padding: 0;

		.item {
			display: flex;
			padding: 0.625rem 0;
		}

		&:hover,
		&.router-link-exact-active>div {
			background-color: #f2f7fc;
		}

		.menu-titre {
			font-family: graphik_semibold;
		}

		.menu-titre-bas {
			font-family: graphik_semibold;
		}

		svg {
			color: #0b5db7;
			font-size: 1.25rem;
		}
	}

	.img-item {
		height: 20px;
		max-width: 32px;
	}

	.img-container {
		width: 56px;
	}
}

.affichage-bouton {
	display: flex;
	font-size: 0.9rem;
	padding: 0.5rem 0;

	&:hover {
		background-color: #f2f7fc;
	}
}

.padding-titre {
	padding: 0.5rem 3.5rem !important;
}

.fleche-titre {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}

div::v-deep svg {
	color: #0b5db7;
	height: 20px;
	width: 15px;
	margin-left: 1.25rem;
	margin-right: 1.3rem;
	object-fit: contain;
}

.overflow-auto {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.casinoClair {
	background-image: none;
	background-color: white;
	color: #192872;
}

main {
	width: inherit;
}