
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								


.steps-form {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;

	@include media-breakpoint-up(md) {
		margin-bottom: 2rem;
	}
}

.steps-form__label {
	flex-basis: 100%;
	margin-bottom: 0;
	text-align: left;

	span {
		background-color: #DEF0FC;
		border-radius: 1rem 1rem 0 0;
		color: #002952;
		display: inline-block;
		font-family: Tahoma, Helvetica, Arial, sans-serif;
		font-size: 1.6rem;
		padding: 1rem 1rem 0.6rem;
		text-transform: uppercase;
	}
}

.steps-form__summary {
	display: flex;
	flex-grow: 0;
	
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.steps-form__steps {
	display: flex;
	flex-grow: 1;
	font-family: Tahoma, Helvetica, Arial, sans-serif;
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.steps-form__step {
	display: flex;
	flex-grow: 1;
	position: relative;
}
.steps-form__step::before {
	background-color: #002951;
	content: "";
	display: block;
	height: 100%;
	left: -12px;
	position: absolute;
	top: 0;
	width: 12px;
	z-index: 0;
}

.steps-form__step::after {
	border-style: solid;
	border-width: 17px 0 17px 13px;
	border-color: transparent transparent transparent #fff;
	content: "";
	display: inline-block;
	height: 0;
	left: -11px;
	position: absolute;
	top: -2px;
	width: 0;
	z-index: 1;

	@include media-breakpoint-up(md) {
		border-width: 20px 0 20px 13px;
	}
}

.steps-form__step:nth-child(1)::after {
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.steps-form__step:first-child::before {
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.steps-form__step.is_active {
	display: flex;
}
.steps-form__step.is_active::before {
	background-color: #92cdeb;
}

.steps-form__step.is_active .steps-form__step-label {
	background-color: #92cdeb;
	color: #002b52;
}
.steps-form__step.is_active .steps-form__triangle::before {
	display: none;
}
.steps-form__step.is_active .steps-form__triangle::after {
	border-color: transparent transparent transparent #92cdeb;
}
.steps-form__step.is_active + .steps-form__step::after {
	display: none;
}
@media (max-width: 767.98px) {
	.steps-form__step:not(.is_active) {
		display: none;
	}
}
.steps-form__summary,
.steps-form__step {
	height: 30px;
}
@media (min-width: 768px) {
	.steps-form__summary,
	.steps-form__step {
		height: 36px;
	}
}
.steps-form__triangle {
	position: relative;
}
.steps-form__triangle::after {
	border-style: solid;
	border-width: 15px 0 15px 12px;
	border-color: transparent transparent transparent #002951;
	content: "";
	display: inline-block;
	height: 0;
	position: relative;
	width: 0;
	z-index: 2;
}
@media (min-width: 768px) {
	.steps-form__triangle::after {
		border-width: 18px 0 18px 12px;
	}
}
.steps-form__step-label {
	align-items: center;
	background-color: #002951;
	color: #fff;
	display: flex;
	flex-grow: 1;
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	padding: 0 10px;
}
.steps-form__step-number {
	display: none;
}
@media (min-width: 768px) {
	.steps-form__step-number {
		display: inline;
		font-size: 20px;
		margin-right: 8px;
	}
}

/*# sourceMappingURL=steps-form.css.map*/

