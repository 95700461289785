$zindex: (
	modalSecondaryJeu: 100003, // modales dans la sidebar d'un jeu
	modalJeu: 100002,
	modal: 100001,
	modalOverlay: 100000, // z-index des modal doit être supérieur à 99 999 pour être au dessus de : header.mel-barre-portail
	addToHomescreen: 7,
	returnToTop: 6,
	dropdownMenu: 5,
    enteteSticky: 6
);

@function zindex($zindex-name) {
	@return map-get($zindex, $zindex-name);
}