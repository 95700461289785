.pagination-games {
    align-items: center;
    display: flex;
}

.pagination-games__list {
    align-items: center;
    display: flex;
    margin-bottom: 0;
}

.pagination-games__item {
    
    &:not(.is_displayed) {
        display: none;
    }
}

.pagination-games__label {
    @include media-breakpoint-down(xs) {
        display: none;
    }

    @include media-breakpoint-up(sm) {
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.pagination-games__link {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    transition: color 0.2s ease-in-out;

    &:hover,
    &:focus {
        color: #fff;
    }

    &.is_active {
        color: #939393;
        text-decoration: underline;

        &:focus {
            color: #939393;
        }
    }
}

.pagination__nav {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 2rem;
    padding: 0;
}

.pagination__nav[disabled] {
    color: #3b3b3b;
}

.page-anchor {
    float: left;
}

