

.blockImageTitreDesc {
	height: 120px;
	float: left;
}

.tuileNoire {
	background-color: #131313;
	padding: 10px 5px 0 5px;
	margin-bottom: 20px;
}

.blockImageTitreDescLeft {
	img {
		float: left;
	}
}

.blockImageTitreDescRight {
	float: right;
	padding-right: 10px;
	padding-left: 25px;
	span.titre {
		font-weight: bold;
		color: #004990;
	}
}

.paddedTitre {
	padding: 10px 50px 0 25px;
}
