
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px !important;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}
.div_general{
display:flex; 
flex-direction: column;
align-items: flex-start; 
}

.petite_note{
  @include ms-respond(font-size, 1);
  color:white;
  font-weight: bold;
  line-height: 1;
  padding: 1.5rem;
}

.boite_petite_note{
background-color:rgba(0,0,0,.65);; 
width:fit-content; 
border-radius: ms(-1);
display:flex; 
justify-content: center;
align-items:center;
margin-left:-4%;
margin-top: 3%;
}

.titre{  
  @include ms-respond(font-size, 1);
  color: black; 
  margin:0;

  @include media-breakpoint-down(sm)  { 
    padding-top: 0.75rem;
  }
}

.btn{
  margin-bottom:12%;
  text-transform: uppercase; 
  color: black;
  @include ms-respond(font-size, -1);

  @include media-breakpoint-down(sm)  { 
      padding-top: 0.5rem ;
      padding-bottom: 0.5rem ;
      padding-right: 1.25rem ;
      padding-left: 1.25rem ;
      border-radius: 20px;
  }
}


.sousTitre{
  color:black; 
  @include ms-respond(font-size, 0);
  margin:0;
  margin-top: 3%;

  @include media-breakpoint-down(sm)  { 
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

.promo_texte{
  @include ms-respond(font-size, 0);
}

.h-380px{
  height: 380px !important;
  @include media-breakpoint-down(sm)  { 
    height: 320px !important;
  }
}

.barreDessousMobile{
  background-color: #efefef; 
  height: 30%;
  @include media-breakpoint-down(sm)  { 
    height: 35%;
  }
}

.hauteurImageMobile{
  height: 70%;
  @include media-breakpoint-down(sm)  { 
    height: 65%;
  }
}
