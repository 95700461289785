
html {
    font-size: 10px;
}

body {
	background-color: #181818;
	background-image: url(/dam/espacejeux/backgrd_casino.jpg);
	background-position: left top;
	background-repeat: repeat;
	color: #000;
	font: 12px/14px 'Tahoma',Arial,Helvetica,sans-serif;
    overflow-x: hidden;
}

.grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

a:not([class]) {
	color: #fff;
}

a:hover {
	text-decoration: none;
}

.switch-language {
	float: right;
}

.switch-language a {
	color: #fff;
	margin-left: 5px;
}

.ej-main-container p {

	@include media-breakpoint-up(xl) {
		margin-bottom: 20px;
	}
}