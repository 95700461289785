.multi-formats.margeCote {
	.columns {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.multi-formats {
	padding: 1rem;

	.liens {
		* {
			position: relative !important;
		}
		.text-sizer.squared-text {
			padding-top: 85%;
			z-index: 0;
		}

		@include media-breakpoint-up(lg) {
			.text-sizer.squared-text {
				padding-top: 100%;
				z-index: 0;
			}
		}

		.media-component.media-text {
			padding: 0;
			border: 1px solid black;
			.content-inner.squared-text-content {
				z-index: 1;
				position: absolute !important;
				.rich-text {
					margin-top: 0;

					.dateListe {
						li {
							list-style-type: none;
						}
					}
				}
				.image-title {
					img {
						width: 100%;
						max-width: none;
					}
				}
				a.btn {
					text-decoration: none;
					color: black;
					&:hover {
						color: black;
					}
				}

				.pContent {
					min-height: 12vw;
					font-size: 4vw;
				}

				.btn {
					font-family: inherit;
					padding: 4vw 10vw;
					font-size: 2vw;
				}

				@include media-breakpoint-up(lg) {
					.pContent {
						min-height: 5vw;
						font-size: 1.5vw;
					}
					.btn {
						font-family: inherit;
						padding: 1vw 2.5vw;
						font-size: 0.8vw;
					}
				}
			}
		}
	}
}
