.index-ancre {
    margin-bottom: 2vw;
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: left;
    
    .index-ancre-avec-image-texte {
        column-count: 3;
        column-gap: 1vw;
       
        li {
            break-inside: avoid-column;
            overflow: hidden;
            width: 100%;
		    margin-top: 1vw;
		    background-color: white;
        }
    }
   
    .index-ancre-sans-image-texte {
        column-count: 4;
        column-gap: 20px;
    }
   
    .index-ancre-gauche {
        margin-top: 6px;
        /* Recette pour lazyload */
        .bg-img {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            background-color: #F6F6F6;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            &.lazyloaded {
                opacity: 1;
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                padding-top: 50%;
            }
        }
    }

    .index-ancre-droite {
		background-size: cover;
		padding-left: 1vw;
		padding-right: 1vw;
        padding-bottom: 1vw;
        padding-top: 1vw;
    }

    .index-ancre-image {
        background-color: white;
        border-style: solid;
        border-width: 1px;
        height: 70%;
    	width: 97%;
    }
   
    .index-ancre-texte {
    	display: block;
        background-color: transparent;
        margin-top: 20px;
    }
   
    li {
        align-items: center;
        line-height: 1.3vw;
        background-position-y: center;
        background-repeat: no-repeat;
        background-position-x: 0.5vw;
        background-size: 2vw;
        display: flex;
        font-size: 100%;
        height: 4vw;
        padding-left: 3vw;
        padding-top: 3px;
        &:first-child{
            margin-top: 0;
        }
    }
   
    @media screen and(max-width:1200px) {
        .index-ancre-avec-image-texte {
            column-count: 2;
        }
        .index-ancre-sans-image-texte {
            column-count: 3;
        }
        li {
            align-items: center;
            background-repeat: no-repeat;
            display: flex;
            height: 5vw;
            line-height: 1;
            padding-top: 3px;
        }
    }
   
    @media screen and(max-width:992px) {
        li {
            height: 7vw;
        }
    }

	    .index-ancre-select {
        margin-top: 20px;
        padding-top: 10px;
    }
    @include media-breakpoint-down(sm) {
        .index-ancre-select{
            select{
                font-size: 16px;
                height: auto;
                padding: 10px 15px;
                background: linear-gradient(45deg, transparent 50%, black 50%),
                    linear-gradient(135deg, black 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
                background-position: calc(100% - 20px) calc(1em + 3px), calc(100% - 16px) calc(1em + 3px), 100% 0;
                background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
                background-repeat: no-repeat;
                -webkit-appearance:none;
                -moz-appearance:none;
            }
        }
    }
}