
.carousel-gagnants,
.carousel-offres {
    margin-bottom: 0;

    .tile .content.content-text {
        text-align: center;
        margin: 0.5rem 1.3rem 1.3rem;

        .note {
            color: #b3cde1;
            text-transform: uppercase;
            text-decoration: underline;
            margin-top: 0.2857142857rem;
            font-size: 2.8vw;
            line-height: 3.2vw;

            &:hover {
            color: #FFFFFF;
            }
                    
            @include media-breakpoint-up(md) {
                font-size: 1.4rem;
                line-height: 1.36;
            }

            @include media-breakpoint-up(lg) {
                font-size: 1.6rem;
            }
        }
    }
}


/* line 880, dev/assets/sass/components/_carousel.scss */
.carousel-gagnants .tile .content.content-text h3,
.carousel-gagnants .tile .content.content-text .h3,
.carousel-offres .tile .content.content-text h3,
.carousel-offres .tile .content.content-text .h3 {
  font-size: 3.8666666667vw;
  line-height: 4vw;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 1;
    }

    @include media-breakpoint-up(lg) {
        font-size: 2.2rem;
    }
}

.carousel-gagnants {
    display: block;
    margin-bottom: 20px;
    min-height: 200px;

    @include media-breakpoint-up(md) {
        min-height: 300px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 384px;
    }

    .content-text {
        line-height: 1.4;
    }

    .over-title-text,
    .under-title-text {
        font-size: 2.9vw;

        @include media-breakpoint-up(md) {
            font-size: 1.6rem;
        }
    }
}