html{
    &:not(.ios){
        .iframe-animation {
            @include media-breakpoint-down(sm) {
                object-fit: cover;
            }
        }
    }
}
.iframe-animation {
    border: none;
    height: 100%;
    left: 0;
    object-position: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
}