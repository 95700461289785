
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.theme-bg-bingo{
.container_media{
  display: flex; 
  //justify-content: center;
  flex-direction: column;
  //height:100% ;
  overflow:visible;
  justify-content: center;
  // si on veut le centrer par rapport à la div *problème avec l'ouverture de l'entrevue dans la page animateur 
  //align-items:center;
  //height: 100%;
}}
.noBorder{
  border:none; 
}

.coin_haut{
  border-top-left-radius: ms(3); 
  border-top-right-radius: ms(3);
}

.coin_bas{
  border-bottom-left-radius: ms(3); 
  border-bottom-right-radius: ms(3);
}

.quatreCoin{
  border-radius: ms(2); 
}

.theme-bg-bingo{
  .margin_1_mediaImage{
    margin-top:15%;
  }
  .margin_2_mediaImage{
    margin-top:30%;
  }
}
@media screen and (max-width:768px){
  // si on mets des marges pour avoir les components en cascade on mets les marge à 0 içi quand ça tombe en mobile. 
  .theme-bg-bingo{
  .margin_1_mediaImage{
    margin-top:0;
  }
  .margin_2_mediaImage{
    margin-top:0;
  }
}
}


.square_container{
  //defini pour les tit25img50txt25
  width: 95%;
  padding-bottom: 95% ;

}

.tit25img50txt25_container{
  // positionne le contenu pour les composantes suivantes
    position: absolute !important;
    height: 100%;
    /* bottom: 0; */
    left: 0;
    top: 0;
}

.drop_shadow {
  box-shadow: 1px 1px 20px -10px #555
}

.no_border{
  border: 0px !important;
}

.h-98{
  height: 98% !important;
}

.h-340px{
  height: 340px !important;
}

.h-380px{
  height: 380px !important;
  @include media-breakpoint-down(sm)  { 
    height: 300px !important;
  }
}

.card {
  border : none;
}

@include media-breakpoint-down(sm) {
  .no_rounded_corner_sm{
    border-radius: unset;
  }
}


.center_image_offres{
  @media (orientation: portrait ) {
    @include media-breakpoint-down(sm) {
      background-position-y : -75px !important;
      // background-position-x: 20px !important;
    }
    @include media-breakpoint-only(md) {
      background-position-y : -75px !important;
      // background-position-x: 10px !important;
    }
  }

  @media (orientation: landscape ) {
    @include media-breakpoint-down(md) {
      background-position-y : -75px !important;
      // background-position-x: 10px !important;
    }
  }
}


