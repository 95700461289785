
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.contenu {
  background-position: center;
  background-repeat: no-repeat;
}
.contenu.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.gs_section{
  overflow:visible; 
  padding-bottom: 1%;
  padding-top: 1%;
}
.gs_titre {
  text-align: left; 
  @include ms-respond(font-size, 6);
  position:relative;
  line-height: 1.25;
 
  @include media-breakpoint-down(sm)  { 
      color : #808080 !important;
  }
}

.gs_sous_titre {
  text-align: left;
  @include ms-respond(font-size, 2);
  margin-top: -1.5rem;
  color: #000000;
  // margin-bottom: 55px;
  // margin-left: 15%;
}
.gs_titre_top_neg{
  top:-9rem !important;
  margin-bottom: -6rem !important;
 
  @include media-breakpoint-down(sm)  { 
    top:-7.5rem !important;
  }
}

// lié a contenu.typeCoin
.coin_haut{
  border-top-left-radius: ms(3); 
  border-top-right-radius: ms(3);
}

.coin_bas{
  border-bottom-left-radius: ms(3); 
  border-bottom-right-radius: ms(3);
}

.quatreCoin{
  border-radius: ms(3); 
}

.margin_top_entre_section{
  margin-top: 10rem;
}

.margin_bottom_entre_section{
  margin-bottom: 10rem;
}


@media screen and (max-width: 765px) {
  .gs_titre {
    text-align: center;
    margin-left:0; 
    font-size: 42px;
  }

  .gs_titre_top_neg{
    top:-6rem !important;
    margin-bottom: 0rem;
  }

  .gs_sous_titre {
    margin-left: 0;
    text-align: center;
  }
}
