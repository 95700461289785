.media-bg-with-content {
	.heading-content {
		.row {
			.columns {
				.media-text {
					width: 38vw;
					height: 38vw;
					padding: 0;
					margin: 0;

					background-color: $color-n1;

					@include media-breakpoint-up(lg) {
						width: 35vw;
						height: 35vw;
					}

					@include media-breakpoint-up(lg) {
						width: 33vw;
						height: 33vw;
					}

					@include media-breakpoint-up(xl) {
						width: 31vw;
						height: 31vw;
					}

					@include media-breakpoint-up(xxl) {
						width: 28vw;
						height: 28vw;
					}

					@include media-breakpoint-up(xxxl) {
						width: 26vw;
						height: 26vw;
					}

					.content {
						height: 100%;

						.squared-text-content {
							padding: 2vw;

							.image-title {
								img {
									float: right;
									width: 3.8vw;
									margin-top: -3vw;
								}

							}
							h3 {
								@include media-breakpoint-up(md) {
									font-size: 1.8rem;
									line-height: 1.33;
								}
							}
						}

						.container-progressif {
							position: absolute;

							.jackpot-number,
							.currency {
								font-size: 3vw;
							}
						}
					}
				}
			}
		}
	}
}

.mobile-content {
	.media-text {
		.content {
			height: 100%;
		}
	}
}