
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.ad-center-vert {
  margin-bottom: auto;
  margin-top: auto;
  
}
.ad-surtitre {
  color: #000000;
  @include ms-respond(font-size, -1);
}
.ad-titre {
  color: #000000;
  @include ms-respond(font-size, 0);
}
.drop-shadow {
  -webkit-box-shadow: 2px 0px 15px 1px rgba(181,181,181,0.40);
  -moz-box-shadow: 2px 0px 15px 1px rgba(181,181,181,0.40);
  box-shadow: 2px 0px 15px 1px rgba(181,181,181,0.40);
}

