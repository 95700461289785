#casino-nav-bar {
	width: 100%;
	float: left;
	padding: 0;
	list-style: none;
	margin-bottom: 0;
	li {
		float: left;
		background-color: #525252;
		margin-right: 10px;
		width: 320px !important;
		min-height: 70px;
		box-shadow: 0 -15px 16px -11px #000000 inset;
		cursor: pointer;
		.a-wrap {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		a {
			display: block;
			text-decoration: none;
			color: #aaa;
			overflow: hidden;
			height: 70px;
			font-weight: bold;
			margin: 0;
			background-position: 0% 0%;
		}
		&:hover {
			background-color: #747474;
			box-shadow: none;
			h1 {
				color: white;
			}
		}
		h1 {
			font-size: 17px;
			margin: 0;
			float: left;
			line-height: 29px;
			cursor: pointer;
			font-weight: bold;
			height: 100%;
		}
	}
	li.last {
		margin-right: 0;
	}
	li.selected {
		background-color: #747474;
		box-shadow: none;
		h1 {
			color: white;
		}
	}
	img {
		vertical-align: middle;
		float: left;
		margin: 0 5px 0 0;
	}
}

#nav-bar-container {
	overflow: hidden;
	clear: both;
}

.form-horizontal .form-group.recherche {
	margin-right: -5px;
	margin-left: -5px;
}

#casino-nav-bar {
	width: 100%;
	float: left;
	padding: 0;
	list-style: none;
	margin-bottom: 0;
	li {
		float: left;
		background-color: #525252;
		margin-right: 10px;
		width: 320px !important;
		min-height: 70px;
		box-shadow: 0 -15px 16px -11px #000000 inset;
		cursor: pointer;
		&.itemsList-3 {
			width: 320px !important;
			h1 {
				font-size: 15px;
				line-height: 17px;
				padding-top: 50%;
				margin-bottom: 63%;
				&.uneLigne {
					.aLinkText {
						margin-top: 10px;
					}
				}
				&.logo {
					padding-left: 54px;
					.imgWrap {
						left: -7px;
					}
				}
			}
		}
		&.itemsList-4 {
			width: 237.5px !important;
			h1 {
				font-size: 15px;
				line-height: 17px;
				padding-top: 50%;
				margin-bottom: 67%;
				&.uneLigne {
					.aLinkText {
						margin-top: 10px;
					}
				}
				&.logo {
					padding-left: 44px;
					.imgWrap {
						left: -7px;
					}
				}
			}
		}
		&.itemsList-5 {
			width: 188px !important;
			h1 {
				font-size: 15px;
				line-height: 17px;
				padding-top: 50%;
				margin-bottom: 71%;
				&.uneLigne {
					.aLinkText {
						margin-top: 10px;
					}
				}
				&.logo {
					padding-left: 44px;
					.imgWrap {
						left: -7px;
					}
				}
			}
		}
		&.itemsList-6 {
			width: 155px !important;
			h1 {
				font-size: 13px;
				line-height: 17px;
				padding-top: 50%;
				margin-bottom: 75%;
				&.uneLigne {
					.aLinkText {
						margin-top: 7px;
					}
				}

				&.logo {
					padding-left: 35px;
					.imgWrap {
						left: -7px;
					}
				}
				img {
					width: 35px;
				}
			}
		}
		&.last {
			margin-right: 0;
		}
		a {
			display: block;
			text-decoration: none;
			color: #aaa;
			overflow: hidden;
			height: 70px;
			font-weight: bold;
			margin: 0;
			background-position: 0% 0%;
		}
		&:hover,
		&.selected {
			background-color: #747474;
			box-shadow: none;
		}
		h1 {
			position: relative;
			font-size: 17px;
			float: left;
			line-height: 29px;
			cursor: pointer;
			font-weight: bold;
		}
		&:hover h1,
		&.selected h1 {
			color: white;
		}
	}
	.imgWrap {
		position: absolute;
	}
}
