.enteteSectionComponent {
	flex-basis: 100%;
	overflow: hidden;

	#listLiens {

		li {
			background: transparent;

			&:active {
				background: transparent;

				.cercle {
					color: rgb(0, 96, 145);
				}
			}
		}

		a {
			background: transparent;
			color: white;
			font-weight: 500;
			border: none;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 10px;

			&:active,
			&:hover {
				text-decoration: none;

				.cercle {
					text-decoration: none;
					color: rgb(0, 96, 145);
				}
			}

			min-width: 0;
			span {
				&.lienText {
					font-family: 'lato-regular', lato-regular, lato, sans-serif;
					text-transform: uppercase;
				}
			}
		}
	}

	.cercle {
		font-size: 14px;
		font-weight: 600;
		padding: 0 3px;
		margin-right: 7px;
		color: rgb(0, 66, 133);
	}

	.white {
		color: white;
	}

	.bleuVert {
		color: rgb(86, 149, 158);
	}

	.bleuFonce {
		color: rgb(0, 66, 133);
	}

	.bleuFonceBG {
		background-color: rgb(0, 66, 133);
	}

	.bleuMedian {
		color: rgb(0, 96, 145);
	}

	.bleuMedianBG {
		background-color: rgb(0, 96, 145);
	}

	#listLiens > li {
		display: inline-block;
		background: transparent;
	}

	#enteteSectionHeader {
		float: left;
		margin-top: 0;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
	}

	#menuHTitre {
		color: white;
		margin-top: 0;
		margin-bottom: 0;
		font-size: 1.95em;
		outline: none;
		cursor: default;
		font-family: 'lato-light', lato-light, lato, sans-serif !important;
		font-weight: 300;
		text-transform: uppercase;
		
		&.pointer{
			cursor: pointer;
		}
	}

	#listLiens {
		float: right;
		display: inline;
		background: transparent;
		margin-right: 0;
		padding-right: 5px;
	}

	.row.liens {
	}

	.cercle.fa.fa-circle {
		margin-top: -0.075em;
		margin-right: 1px;
	}
}
