button {
	cursor: pointer;
}

button[disabled] {
	cursor: not-allowed;
}

.button-close-corner-x {
    background: #010101;
    border: 2px solid #00417f;
    border-radius: 50%;
	color: #ffffff;
    font-family: Tahoma, Arial, Helvetica, sans-serif;
	font-size: 2rem;
	height: 3.6rem;
	line-height: 1;
	padding-bottom: 5px;
	position: absolute;
	right: -24px;
	top: -18px;
	width: 3.6rem;

	@include media-breakpoint-up(md) {
		right: -33px;
	}
}

.btn {
	background-color: transparent;
	border: 2px solid #fff;
	border-radius: 8px;
	color: #fff;
	display: inline-block;
	font-family: $lato-font-medium;
	font-size: 3.46667vw;
	line-height: 1.25em;
	padding: 3.06667vw 5.2vw 3.33333vw;
	text-decoration: none;
	transition: all 0.2s;

	@include media-breakpoint-up(md) {
		font-size: 1.4rem;
        line-height: 1.25;
        padding: 0.8rem 1.8rem;
        margin-top: 1.5rem
	}

	@include media-breakpoint-up(lg) {
		font-size: 1.6rem;
        padding: 1.1rem 3rem;
        margin-bottom: 0.7rem;
        margin-top: 1rem;
	}
}

.btn:hover {
	background-color: #fff;
	color: #000;
}

body.theme-bg-casino .media-component.media-text .rich-text p a.btn, body.theme-bg-casino .media-component.media-text.rich-text p a.btn{
	background-color: #fff;
	color: #000;
	border-radius: 8px;
}

body.theme-bg-casino .media-component.media-text .rich-text p a.btn:hover, body.theme-bg-casino .media-component.media-text.rich-text p a.btn:hover{
	background-color: transparent;
	border: 2px solid #fff;
	color: #fff !important;
}

.theme-bg-poker footer .btn {
	color: #fff;
}

.theme-bg-poker footer .btn:hover {
	color: #000;
}

.btn.opaque {
	color: #000;
	border-color: #fff;
	background-color: #fff;
}

.btn.opaque:hover {
	color: #fff;
	border-color: #fff;
	background-color: #000;
}

.btn.icon {
	padding-right: 6.26667vw;

	@include media-breakpoint-up(md) {
		padding-right: 2.0142857143rem
	}

	@include media-breakpoint-up(lg) {
		padding-right: 1.4285714286rem
	}
}

main.theme-bg-sports {
	~ footer .btn,
	.btn {
		border-radius: 0px;
	}
}
