.modal-overlay {
	background-color: rgba(0, 0, 0, 0.85) !important;
}

.modal-backdrop.in {
	opacity: 0.8;
	z-index: zindex(modalOverlay);
}

.modal {
	z-index: zindex(modal);
}

.modal--center {
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 70%;
}

.modal-header {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background-clip: padding-box;
	background-image: linear-gradient(to top, #090c0b, #3f444e);
	border-color: #2b2d33 #2b2d33 -moz-use-text-color;
	border-image: none;
	border-radius: 6px 6px 0 0;
	border-style: solid solid none;
	border-width: 1px 1px 0;
	box-shadow: 0 1px 1px #63666b inset;
	color: #0e1010;
	outline: 0 none;
	position: relative;
}
.modal-content {
	background-color: transparent;
}
.modal-body {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background-color: #fff;
	border-color: #2b2d33 #2b2d33 -moz-use-text-color;
	border-image: none;
	border-radius: 0;
	border-style: solid solid none;
	border-width: 1px 1px medium;
	outline: 0 none;
}
.modal-footer {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	background-color: #0e1010;
	border-color: -moz-use-text-color #2b2d33 #2b2d33;
	border-image: none;
	border-radius: 0 0 6px 6px;
	border-right: 1px solid #2b2d33;
	border-style: none solid solid;
	border-width: 0 1px 1px;
	outline: 0 none;
	padding: 5px 5px 20px;
	text-align: center;
}
.close {
	color: #272727;
	opacity: 0.3;
	text-shadow: 0 1px 0 #070a0a;
}
.close:hover {
	color: #272727;
	opacity: 0.9;
}
.modal-body p {
	color: #272727;
	text-align: left;
}
.modal-footer .lq-btn-default {
	background: linear-gradient(to bottom, #8e9094 0%, #46484e 49%, #24262c 51%, #1f2126 100%);
	border: medium none;
	color: #ffffff;
	padding: 10px 25px;
}
.modal-footer .lq-btn-custom {
	background: linear-gradient(to bottom, #c87027 0%, #c56c27 49%, #bb5b29 51%, #bb5b29 51%, #9b3632 100%);
	border: medium none;
	padding: 10px 25px;
}

.wrapper {
    min-height: 100%;
    position: relative;
}



.dialog-box {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	margin:auto;
	position:absolute;
	height:1px;
	min-width:220px;
	display:table;
	border-radius:8px;
	border:1px solid #0B0E0D;
	box-shadow:         0px 1px 6px 2px #000;
	z-index:99;
}
.dialog-body {
	padding:15px;
	background:#0B0E0D;
	color:#ccc;
	text-align:center;
	font:1.1em/1.1em 'Tahoma', Arial, Helvetica, sans-serif;
}
.dialog-button {
	margin:auto;
	text-align:center;
	display:table;
	width:100%;
	padding-top:10px;
}
.button-confirm {
	background: linear-gradient(to bottom, #898B8F, #3B3D43 49%, #202227 50%, #202227 100%);
	color:#fff;
	font:0.9em/1em 'Tahoma', Arial, Helvetica, sans-serif;
	border-radius:6px;
	padding:11px;
	color:#ccc;
	display:table;
	float:left;
	min-width:80px;
	cursor:pointer;
	box-shadow:         0px 1px 2px 1px #000;
	letter-spacing:1px;
	float:right;
	color:#ACD10E;
	font-weight:bold;
	text-transform:uppercase;
	text-shadow: 1px 1px 2px #000;
}

.button-cancel,
.button-confirm {
	background: linear-gradient(to bottom, #898B8F, #3B3D43 49%, #202227 50%, #202227 100%);
	color: #fff;
	font: 0.9em/1em 'Tahoma', Arial, Helvetica, sans-serif;
	border-radius: 6px;
	padding: 11px;
	color: #ccc;
	display: table;
	float: left;
	min-width: 96px;
	cursor: pointer;
	box-shadow: 0px 1px 2px 1px #000;
	letter-spacing: 1px;
}

.button-confirm {
	float: right;
	color: #ACD10E;
	font-weight: bold;
	text-transform: uppercase;
	text-shadow: 1px 1px 2px #000;
}

.button-cancel {
	color: #52ACFF;
	font-weight: bold;
	text-transform: uppercase;
	text-shadow: 1px 1px 2px #000;
}

.button-cancel,
    .button-confirm {
	font: 1.1em/1em 'Tahoma', Arial, Helvetica, sans-serif;
	min-width: 126px;
	border-bottom: 1px solid #61646D;
}

// Message d'avertissement avant la maintenance d'un jeu.

#modalJeuAvertissement {
	z-index: zindex(modalJeu);

	.modalFlexContent {
		max-width: calc( 100vw - 20px );
	}
}