//Les fichiers pour les fonts se trouvent dans resources/dev/assets/fonts/
@font-face {
    font-family: 'graphik_light';
    src: url("../fonts/Graphik-Light-Web.eot");
    src: url("../fonts/Graphik-Light-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Light-Web.woff2") format("woff2"), url("../fonts/Graphik-Light-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal; 
    }
@font-face {
    font-family: 'graphik_regular';
    src: url("../fonts/Graphik-Regular-Web.eot");
    src: url("../fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Regular-Web.woff2") format("woff2"), url("../fonts/Graphik-Regular-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal; 
    }
@font-face {
    font-family: 'graphik_regular_italic';
    src: url("../fonts/Graphik-RegularItalic-Web.eot");
    src: url("../fonts/Graphik-RegularItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-RegularItalic-Web.woff2") format("woff2"), url("../fonts/Graphik-RegularItalic-Web.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-stretch: normal; 
    }
@font-face {
    font-family: 'graphik_medium';
    src: url("../fonts/Graphik-Medium-Web.eot");
    src: url("../fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Medium-Web.woff2") format("woff2"), url("../fonts/Graphik-Medium-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal; 
    }
@font-face {
    font-family: 'graphik_bold';
    src: url("../fonts/Graphik-Bold-Web.eot");
    src: url("../fonts/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Bold-Web.woff2") format("woff2"), url("../fonts/Graphik-Bold-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal; 
    }
@font-face {
    font-family: 'graphik_black';
    src: url("../fonts/Graphik-Black-Web.eot");
    src: url("../fonts/Graphik-Black-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Black-Web.woff2") format("woff2"), url("../fonts/Graphik-Black-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal; 
    }

@font-face {
    font-family: 'graphik_semibold';
    src: url('../fonts/Graphik-Semibold.eot');
    src: url('../fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),url('../fonts/Graphik-Semibold.woff2') format('woff2'),url('../fonts/Graphik-Semibold.woff') format('woff'),url('../fonts/Graphik-Semibold.ttf') format('truetype'),url('../fonts/Graphik-Semibold.svg#Graphik-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'graphik_black_italic';
    src: url('../fonts/Graphik-BlackItalic.eot');
    src: url('../fonts/Graphik-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-BlackItalic.woff2') format('woff2'), url('../fonts/Graphik-BlackItalic.woff') format('woff'), url('../fonts/Graphik-BlackItalic.ttf') format('truetype'), url('../fonts/Graphik-BlackItalic.svg#Graphik-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_bold_italic';
    src: url('../fonts/Graphik-BoldItalic.eot');
    src: url('../fonts/Graphik-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-BoldItalic.woff2') format('woff2'), url('../fonts/Graphik-BoldItalic.woff') format('woff'), url('../fonts/Graphik-BoldItalic.ttf') format('truetype'), url('../fonts/Graphik-BoldItalic.svg#Graphik-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_extralight';
    src: url('../fonts/Graphik-Extralight.eot');
    src: url('../fonts/Graphik-Extralight.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Extralight.woff2') format('woff2'), url('../fonts/Graphik-Extralight.woff') format('woff'), url('../fonts/Graphik-Extralight.ttf') format('truetype'), url('../fonts/Graphik-Extralight.svg#Graphik-Extralight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'graphik_extralight_italic';
    src: url('../fonts/Graphik-ExtralightItalic.eot');
    src: url('../fonts/Graphik-ExtralightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-ExtralightItalic.woff2') format('woff2'), url('../fonts/Graphik-ExtralightItalic.woff') format('woff'), url('../fonts/Graphik-ExtralightItalic.ttf') format('truetype'), url('../fonts/Graphik-ExtralightItalic.svg#Graphik-ExtralightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_light_italic';
    src: url('../fonts/Graphik-LightItalic.eot');
    src: url('../fonts/Graphik-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-LightItalic.woff2') format('woff2'), url('../fonts/Graphik-LightItalic.woff') format('woff'), url('../fonts/Graphik-LightItalic.ttf') format('truetype'), url('../fonts/Graphik-LightItalic.svg#Graphik-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_medium_italic';
    src: url('../fonts/Graphik-MediumItalic.eot');
    src: url('../fonts/Graphik-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-MediumItalic.woff2') format('woff2'), url('../fonts/Graphik-MediumItalic.woff') format('woff'), url('../fonts/Graphik-MediumItalic.ttf') format('truetype'), url('../fonts/Graphik-MediumItalic.svg#Graphik-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_semibold_italic';
    src: url('../fonts/Graphik-SemiboldItalic.eot');
    src: url('../fonts/Graphik-SemiboldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-SemiboldItalic.woff2') format('woff2'), url('../fonts/Graphik-SemiboldItalic.woff') format('woff'), url('../fonts/Graphik-SemiboldItalic.ttf') format('truetype'), url('../fonts/Graphik-SemiboldItalic.svg#Graphik-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_super';
    src: url('../fonts/Graphik-Super.eot');
    src: url('../fonts/Graphik-Super.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Super.woff2') format('woff2'), url('../fonts/Graphik-Super.woff') format('woff'), url('../fonts/Graphik-Super.ttf') format('truetype'), url('../fonts/Graphik-Super.svg#Graphik-Super') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'graphik_super_italic';
    src: url('../fonts/Graphik-SuperItalic.eot');
    src: url('../fonts/Graphik-SuperItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-SuperItalic.woff2') format('woff2'), url('../fonts/Graphik-SuperItalic.woff') format('woff'), url('../fonts/Graphik-SuperItalic.ttf') format('truetype'), url('../fonts/Graphik-SuperItalic.svg#Graphik-SuperItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_thin_italic';
    src: url('../fonts/Graphik-ThinItalic.eot');
    src: url('../fonts/Graphik-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-ThinItalic.woff2') format('woff2'), url('../fonts/Graphik-ThinItalic.woff') format('woff'), url('../fonts/Graphik-ThinItalic.ttf') format('truetype'), url('../fonts/Graphik-ThinItalic.svg#Graphik-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'graphik_thin';
    src: url('../fonts/Graphik-Thin.eot');
    src: url('../fonts/Graphik-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Thin.woff2') format('woff2'), url('../fonts/Graphik-Thin.woff') format('woff'), url('../fonts/Graphik-Thin.ttf') format('truetype'), url('../fonts/Graphik-Thin.svg#Graphik-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'lato_bold';
    src: url("../fonts/lato-bold-webfont.eot");
    src: url("../fonts/lato-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-bold-webfont.woff2") format("woff2"), url("../fonts/lato-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal; 
    }

@font-face {
    font-family: 'futura_book';
    src: url('../fonts/futura-book.eot');
    src: url('../fonts/futura-book.eot?#iefix') format('embedded-opentype'),
            url('../fonts/futura-book.svg#futura-fook') format('svg'),
            url('../fonts/futura-book.ttf') format('truetype'),
            url('../fonts/futura-book.woff') format('woff'),
            url('../fonts/futura-book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura_bold';
    src: url('../fonts/futura-bold.eot');
    src: url('../fonts/futura-bold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/futura-bold.svg#futura-bold') format('svg'),
            url('../fonts/futura-bold.ttf') format('truetype'),
            url('../fonts/futura-bold.woff') format('woff'),
            url('../fonts/futura-bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}  

@mixin genGraphikClassFont($fontName){
    .#{$fontName}{
        font-family:$fontName, Arial, Helvetica, sans-serif !important;
    }
}



@each $fontName in (
    graphik_light,
    graphik_regular,
    graphik_regular_italic,
    graphik_medium,
    graphik_bold,
    graphik_black,
    graphik_semibold,
    graphik_black_italic,
    graphik_bold_italic,
    graphik_extralight,
    graphik_extralight_italic,
    graphik_light_italic,
    graphik_medium_italic,
    graphik_semibold_italic,
    graphik_super,
    graphik_super_italic,
    graphik_thin_italic,
    graphik_thin,
    lato_bold,
    futura_bold,
    futura_book
){
  @include genGraphikClassFont($fontName);
}

@font-face {
  font-family: 'GothamMediumRegular';
  src: url('../fonts/gothammedium-webfont.eot');
  src: url('../fonts/gothammedium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/gothammedium-webfont.woff') format('woff'), url('../fonts/gothammedium-webfont.ttf') format('truetype'), url('../fonts/gothammedium-webfont.svg#GothamMedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url("../fonts/lato-light-webfont.woff2") format("woff2"), url("../fonts/lato-light-webfont.woff") format("woff"), url("../fonts/lato-light-webfont.ttf") format("truetype"), url("../fonts/lato-light-webfont.svg#latolight") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url("../fonts/lato-regular-webfont.woff2") format("woff2"), url("../fonts/lato-regular-webfont.woff") format("woff"), url("../fonts/lato-regular-webfont.ttf") format("truetype"), url("../fonts/lato-regular-webfont.svg#latoregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latomedium';
    src: url("../fonts/lato-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-medium-webfont.woff2") format("woff2"), url("../fonts/lato-medium-webfont.woff") format("woff"), url("../fonts/lato-medium-webfont.ttf") format("truetype"), url("../fonts/lato-medium-webfont.svg#latomedium") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack';
    src: url("../fonts/lato-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-black-webfont.woff2") format("woff2"), url("../fonts/lato-black-webfont.woff") format("woff"), url("../fonts/lato-black-webfont.ttf") format("truetype"), url("../fonts/lato-black-webfont.svg#latoblack") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url("../fonts/lato-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-bold-webfont.woff2") format("woff2"), url("../fonts/lato-bold-webfont.woff") format("woff"), url("../fonts/lato-bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoheavy';
    src: url("../fonts/lato-heavy-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-heavy-webfont.woff2") format("woff2"), url("../fonts/lato-heavy-webfont.woff") format("woff"), url("../fonts/lato-heavy-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latosemibold';
    src: url("../fonts/lato-semibold.woff2") format("woff2"), url("../fonts/lato-semibold.woff") format("woff"), url("../fonts/lato-semibold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'oswaldlight';
    src: url("../fonts/oswald-light-webfont.woff2") format("woff2"), url("../fonts/oswald-light-webfont.woff") format("woff"), url("../fonts/oswald-light-webfont.ttf") format("truetype"), url("../fonts/oswald-light-webfont.svg#oswaldlight") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'oswaldregular';
    src: url("../fonts/oswald-regular-webfont.woff2") format("woff2"), url("../fonts/oswald-regular-webfont.woff") format("woff"), url("../fonts/oswald-regular-webfont.ttf") format("truetype"), url("../fonts/oswald-regular-webfont.svg#oswald_regularregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'oswaldmedium';
    src: url("../fonts/oswald-medium-webfont.woff2") format("woff2"), url("../fonts/oswald-medium-webfont.woff") format("woff"), url("../fonts/oswald-medium-webfont.ttf") format("truetype"), url("../fonts/oswald-medium-webfont.svg#oswaldmedium") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'oswaldbold';
    src: url("../fonts/oswald-bold-webfont-webfont.woff2") format("woff2"), url("../fonts/oswald-bold-webfont-webfont.woff") format("woff"), url("../fonts/oswald-bold-webfont-webfont.ttf") format("truetype"), url("../fonts/oswald-bold-webfont-webfont.svg#oswaldbold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'eausans_black';
    src: url("../fonts/eau_sans_black-webfont.woff2") format("woff2"), url("../fonts/eau_sans_black-webfont.woff") format("woff"), url("../fonts/eau_sans_black-webfont.ttf") format("truetype"), url("../fonts/eau_sans_black-webfont.svg#eausans_black") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url("../fonts/montserrat-regular.woff2") format("woff2"), url("../fonts/montserrat-regular.woff") format("woff"), url("../fonts/montserrat-regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: url("../fonts/montserrat-bold.woff2") format("woff2"), url("../fonts/montserrat-bold.woff") format("woff"), url("../fonts/montserrat-bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Montserrat Black';
    src: url("../fonts/montserrat-extrabold.woff2") format("woff2"), url("../fonts/montserrat-extrabold.woff") format("woff"), url("../fonts/montserrat-extrabold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}


@font-face {
	font-family: 'function_probook';
	src: url("../fonts/FunctionPro-Book-webfont.woff") format("woff"), url("../fonts/FunctionPro-Book-webfont.ttf") format("truetype");
}

@font-face {
	font-family: 'function_promedium';
	src: url("../fonts/FunctionPro-Medium-webfont.woff") format("woff"), url("../fonts/FunctionPro-Medium-webfont.ttf") format("truetype");
}

// super override font familly pour poker

main.theme-bg-poker .media-image .content-text > div.over-title-text,
main.theme-bg-poker .media-image .content-text-inner > div.over-title-text,
main.theme-bg-poker section.transparent .media-image .content-text > div.over-title-text,
main.theme-bg-poker section.transparent .media-image .content-text-inner > div.over-title-text {
    font-family: $font-graphik-regular;
}

