
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px !important;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}
.div_general {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

body.theme-bg-bingo {
  div > * {
    line-height: 1em;
  }
  .boite_petite_note {
    background-color: rgba(0, 0, 0, 0.65);
    width: fit-content;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    padding: 1.5% 3%;
    color: white;

    @include ms-respond(font-size, 0);
  }

  .titre {
    @include ms-respond(font-size, 4);
    margin: 0;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      padding-top: 0.75rem;
    }
  }

  .btn {
    margin-bottom: 12%;
    text-transform: uppercase;
    color: black;
    @include ms-respond(font-size, -1);
    border-radius: 4rem;

    @include media-breakpoint-down(sm) {
      padding: 1rem 2.5rem;
      border-radius: 20px;
    }
  }

  .offre_surtitre {
    @include ms-respond(font-size, 0);
  }

  .sousTitre {
    @include ms-respond(font-size, 0);
    margin: 0;
    margin-top: 2%;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .hauteurImageMobile {
    height: 70%;
    @include media-breakpoint-down(sm) {
      height: 65%;
    }
  }

  @include media-breakpoint-down(sm) {
    .offre_surtitre {
      font-size: 12px;
    }

    .barreDessousMobile {
      height: 50%;
      background-color: #efefef; //couleur background par defaut
    }

    .offre_special_container {
      align-content: space-between;
      align-items: center;
    }

    .mobile_text_center {
      text-align: center;
    }
  }
}
