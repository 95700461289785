
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

  // TODO: Ce qui suit jusqu'à "table-vertical" doit être révisé
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
	}

  .entete{
    @include ms-respond(font-size, 0);
    height: ms(5);
    font-weight:bold;
  }

  .ligne{
    @include ms-respond(font-size, 0);  
    line-height: 1;
    height: ms(7);
  }

  .footer{
    height: ms(2);
  }

  /* Autre scss dans portail pour accès aux variables */
  /* 
  Generic Styling, for Desktops/Laptops 
  */
  .table-vertical {
    border-collapse: collapse;
    font-size: 1.4rem;
    height: auto;
    margin-bottom: 1.5vw;
    width: 100%;
  }
  
  // TABLEAU
  .tableau {
    table {
      color: #000;
      thead {
        @include media-breakpoint-down(sm) {
          display: none;
        }

        tr {
          th {
            border: none;
            background-color: transparent;
          }
          background-color: #f3efe3;
        }
      }
      tbody {
       .majuscule {
            font-weight: bold;
            text-transform: uppercase;
          }

        td {
          border: none;      
        }

        tr {
          &.boutons {
            td {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .ligne {
      display: none;
    }

    .ligne {
      &.mobile {
        &.desktop {
          @include media-breakpoint-down(sm) {
            display: block;
            padding: 2%;
          }
          @include media-breakpoint-up(md) {
            display: table-row;
          }
        }
      }
    }

    .ligne {
      &.mobile {
        @include media-breakpoint-down(sm) {
          display: block;
          height: 100%;
        }
      }
    }

    .ligne {
      &.desktop {
        @include media-breakpoint-up(md) {
          display: table-row;
        }
      }
    }

    .plusWrapDesktop {
      text-align: center;
      &.init {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .plusWrapMobile {
      text-align: center;
      &.init {
        display: none;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 0 !important;
      }
    }

    .tableauBoutonPlus {
      display: none;
      font-size: 1.4rem;

      @include media-breakpoint-down(sm) {
        &[data-button-plus='mobile'] {
          display: inline;
        }
      }

      @include media-breakpoint-up(md) {
        &[data-button-plus='desktop'] {
          display: inline;
        }
      }
    }

    &.editMode {
      .tableauBoutonPlus {
        display: none;
      }
      tr.ligne {
        @include media-breakpoint-down(sm) {
          display: block;
        }
        @include media-breakpoint-up(md) {
          display: table-row;
        }
      }
    }

    tbody tr:nth-child(odd) {
      background-color: white;
    }
    tbody tr:nth-child(even) {
      background-color: #EFEFEF; // gris
    }

    a[data-button-plus] {
      border: 1px solid black;
      padding: 5px 40px;
      border-radius: 5px;
      text-decoration: none;
      background-color: white;
    }

    .table-vertical > thead > tr > th,
      .table-vertical > tbody > tr > td {
        /* Behave  like a "row" */
        vertical-align: middle;
        padding-left: 2rem;
      }


    .enteteTableau,
    .piedTableau {
      padding: 69px 60px 69px 60px;
      @include media-breakpoint-down(sm) {
        padding: 15px 10px 15px 10px;
      }
      p,
      h3 {
        text-align: left;
      }

      p {
        font-size: 4.4vw;
        line-height: 1.24;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
        }
      }

      ul {
        display: block;
        margin-left: 20px;
        li {
          list-style: disc;
          text-align: left;
        }
      }

      .container {
        display: table;
        width: 100%;

        .row {
          align-items: center;
          height: 100%;
          
          .vertical-center {
            vertical-align: middle;
          }

          .vertical-top {
            vertical-align: top;
          }
        }

        .row .no-float {
          display: table-cell;
          float: none;
        }
      }
    }

    .voir_plus_btn{
      text-transform: uppercase;
      color: black;
      @include ms-respond(font-size, -1);
      background-color: #0DCB8F !important;
    }

    @media (max-width: 767px) {
      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      .table-vertical thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      .table-vertical > thead > tr > tr,
      .table-vertical > tbody > tr > td {
        /* Behave  like a "row" */
        border: none;
        position: relative;
        padding-left: 40%;
        display: flex;
        align-items: center;
      }

      .table-vertical > tbody > tr > td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: unset;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      /*
          Label the data
          */
      .table-vertical > tbody > tr > td:before {
        content: attr(data-th);
        white-space: pre-wrap;
      }

      .footer{
        height: 0; //Probleme lié à l'adaptation en mobile qui empeche d'avoir un footer de meme taille
      }
    }
  }

