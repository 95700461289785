
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}
