.catfish {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    z-index: 99;
    visibility: hidden;
    background-color:#000000;
    background-color:rgba(0,0,0,0.35);

    &.topShadow {
        box-shadow: 0px -2px 5px 0px rgba(255,255,255,0.39);
    }

    &.full img {
        width: 100%;
    }

    .wrap {
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .catfish-close {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 5px 5px 7px 7px;
        font-size: 18px;
        cursor: pointer;
    }


    &.light {
        .catfish-close{
            background: black;
            color: white;
            border-left:1px solid #dddddd ;
            border-bottom:1px solid #dddddd ;
        }
    }

    &.dark {
        .catfish-close {
            background: white;
            color: black;
            border-left:1px solid #000000 ;
            border-bottom:1px solid #000000 ;
        }
    }
}

body.mobile_device {
    .catfish.full img {
        width: 100%;
        max-width: 640px;
    }
}
