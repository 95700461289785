.right_slider {
	z-index: 9991;
	border-width: 5px;
	background: #ffffff;
	.icon-cross {
		&::before {
			color: #000000;
			margin-top: -20px;
			margin-right: -10px;
			cursor: pointer;
		}
	}
	.ui-slideouttab-handle {
		border-radius: 5px 5px 5px 5px;
	}
}

.bottom_slider {
	z-index: 1000;
	border-width: 5px;
	background: #ffffff;
	.icon-cross {
		&::before {
			color: #000000;
			margin-right: -10px;
			cursor: pointer;
		}
	}
	.ui-slideouttab-handle {
		border-radius: 5px 5px 0px 0px;
	}
}

pre {
	margin-left: 3em;
}

.right_slider.ui-slideouttab-panel {
	border: 5px solid grey;
	border-radius: 5px 0px 0px 5px;
}

.bottom_slider.ui-slideouttab-panel {
	border: 5px solid grey;
	border-radius: 0;
}

.ui-slideouttab-panel {
	a.handle {
		color: #fff;
		
		&:hover {
			color: black;
			cursor: pointer;
		}
	}
	background-color: white;
	box-sizing: border-box;
}

.ui-slideouttab-handle {
	background-color: white;
	box-sizing: border-box;
	cursor: pointer;
	color: #000000;
	font: 1.7em/1.7em Arial, Helvetica, sans-serif;
	font-weight: bold;
	background: linear-gradient(to bottom, rgba(54, 54, 54, 1) 0%, rgba(203, 203, 204, 1) 100%);
	padding: 5px 10px;
}

.ui-slideouttab-handle-image {
	transform: rotate(0deg);
}

.ui-slideouttab-right {
	.ui-slideouttab-handle {
		transform-origin: 0% 0%;
		transform: rotate(-90deg) translate(-100%, -100%);
	}
	.ui-slideouttab-handle-reverse {
		transform-origin: 0% 100%;
		transform: rotate(-90deg);
	}
	.ui-slideouttab-handle-rounded {
		border-radius: 15px 15px 0 0;
	}
}

.ui-slideouttab-left {
	.ui-slideouttab-handle {
		transform-origin: 100% 0%;
		transform: rotate(-90deg);
	}
	.ui-slideouttab-handle-reverse {
		transform-origin: 100% 100%;
		transform: rotate(-90deg) translate(100%, 100%);
	}
	.ui-slideouttab-handle-rounded {
		border-radius: 0 0 6px 6px;
	}
}

.ui-slideouttab-top {
	.ui-slideouttab-handle-rounded {
		border-radius: 0 0 6px 6px;
	}
}

.ui-slideouttab-bottom {
	.ui-slideouttab-handle-rounded {
		border-radius: 15px 15px 0 0;
	}
}

.open {
	right: 0px;
}

.openBottom {
	bottom: 0px;
}
