.bandeau {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 100px;
    height: 50px;
	top: -7px;
    right: -33px;
	font-weight: bold;
    font-family: sans-serif;
	text-transform: uppercase;
    text-align: center;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
	color: #fff;
    transform: rotate(45deg);
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	display: flex;
    align-items: flex-end;
    justify-content: center;
}

.bandeau span{
	margin-bottom: 1px;
}

.bandeau--gauche{
	left: 0px;
	right: auto;
	top: -7px; 
	left: -33px;
	transform: rotate(-45deg);
}

.bandeau__sameline{
	display: inline-block;
}

.bandeau__firstline{
	font-size:9px;
}

.bandeau__secondline{
    bottom: 2px;
	font-size: 14px;
	position: relative;
}