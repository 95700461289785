
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.row {
  background-position: center;
  background-repeat: no-repeat;
}
.image{
  border-top-left-radius: ms(2);
  border-top-right-radius:ms(2);
  height: 80%;
  object-fit: cover;
  object-position: center left;
  display: block;
}

.img90tit10{
  height: 220px;//TODO: Voir si cette valeur pourrait etre defini ailleurs
}

.img90tit10_titre{
  text-align:center; 
  margin: 1rem;
  @include ms-respond(font-size, 2);
  color: #303030;
}

.img90tit10_content_container{
  margin:-2px;
}

.img90tit10_text_content{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.img90tit10 a {
  text-decoration: none;
  color: #2c3e50;
}

body.theme-bg-bingo div .img90tit10_titre{
    line-height: 1em;
}

