
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.cedTablePremiereOuverte {
  .splitGauche {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .TableInfoWrap--jdxy_ {
    overflow: visible;
  }
  .TableFooter--3hms2 {
    .container {
      @media (max-width: 425px) {
        padding-bottom: 6vw;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 3vw;
        padding-top: 2vw;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 3rem;
        padding-top: 2rem;
      }

      @include media-breakpoint-up(xxl) {
        padding: 2rem 3.2rem;
      }

      .wrapperBouton {
        width: 100%;
        justify-content: left;
        text-align: left;
      }
    }
    .soustitre {
      padding-top: 1.5rem;
    }
  }
}

.dernierSiege {
  color: #e9ec87; //Black jack : Dernier siège;  http://intranetvpcap.loto-quebec.com/_APPROBATION_WEB/produits-projets-2021/02-2021-offshore-guide-styles-graphiques/start.html#id=tgle30&p=6_0_couleurs
  border: solid 2px #e9ec87; //Black jack : Dernier siège
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  font-weight: 600;
  font-family: $font-graphik-regular;
  font-size: calc(8px + 0.1vw);
  line-height: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  .nbplayersIcon {
    background-color: #e9ec87; //Couleurs Blackjack dernier siège
  }
  @include media-breakpoint-up(md) {
    //media > 768px
    font-size: calc(8px + 0.1vw);
  }
  @include media-breakpoint-up(xl) {
    //media > 1200px
    font-size: calc(9.75px + 0.1vw);
  }
  .splitGauche.libelle.espace {
    margin-right: 8px;
    margin-left: 15px;
  }
}

.placesDisponibles {
  font-weight: 600;
  font-family: $font-graphik-regular;
  font-size: 0.8rem;
  line-height: 13px;
  height: 30px;
  display: flex;
  align-items: center;
  .splitGauche.libelle.espace {
    margin-right: 8px;
    margin-left: 15px;
  }
  @include media-breakpoint-up(md) {
    font-size: 0.9rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }
}

.nbplayersIcon {
  width: auto;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  height: 38px;
  display: flex;
  align-items: center;
}

.lobby-table-block-body {
  .langue {
    color: white;
    font-family: $font-graphik-regular;
    font-weight: bold;
    line-height: 14px;
    font-size: 11px;
    background-color: black;
    position: absolute;
    padding: 5px 0px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    font-size: 14px;
    left: 20px;
    top: 60px;
    //min-width: 64px;
    display: flex;
    @include media-breakpoint-up(lg) {
      //@media (min-width: 1024px){
      font-size: 11px;
      left: 20px;
      top: 60px;
    }
  }
  .langue.bulleIcon .presentateur {
    float: left;
    height: 15px;
    width: 15px;
    float: left;
    margin-left: 5px;
    padding: 0px;
    display: block;
    fill: white;
  }
  .bulleIcon.bordure:before {
    //Icone bulle, triangle exterieur de l'icone bulle*/
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 10px;
    right: auto;
    top: auto;
    bottom: -11px;
    border: 5px solid;
  }
  .bulleIcon:after {
    //Icone bulle, triangle interieur de l'icone bulle*/
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 12px;
    right: auto;
    top: auto;
    bottom: -5.5px;
    border: 3px solid;
  }
  .bulleMessage {
    text-align: left;
    margin-right: 12px;
    margin-left: 3px;
    line-height: 14px;
    font-size: 11px;
    position: relative;
    top: 2px;
  }
  .lobby-table-game-specific-data {
    background: linear-gradient(180deg, transparent 45%, rgba(0, 0, 0, 0.85));
  }
  .chargement {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/.resources/ej-template-theme/img/ui/loader.gif");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px 32px;
  }
  .lobby-table-limits {
    color: black;
    font-weight: bold;
    .maxMontantMobile {
      overflow: unset;
      max-width: unset;
      transition: unset;
    }
  }

  .lobby-table-limits.mise {
    background: rgba(0, 0, 0, 0.8);
    font-size: calc(11px + 0.5vw);
  }

  .lobby-table-overlay {
    .lobby-table-info {
      display: block;
      text-align: left;
      fill: #fff;
      color: #fff;
      transition-delay: 0.5s;
      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
      padding: 1.5em;

      .valeurChamp {
        font-weight: bold;
      }
    }
  }

  .LobbyTableOverlay--3_xjP {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent 45%, rgba(0, 0, 0, 0.65));
    z-index: 4;
    pointer-events: none;
  }

  .recent-numbers-container {
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .table-state {
    border-radius: 0.29rem;
    color: #fff;
    font-size: 0.72rem;
    line-height: 1;
    padding: 0.36rem;
    position: absolute;
    right: 0.72rem;
    top: 0.72rem;
    z-index: 1;
  }

  .table-state--opened {
    background-color: #00b050;
  }

  .table-state--closed {
    background-color: #c00000;
  }
}
.TableFooter--3hms2 {
  .tableFooter-ratio {
    .developper {
      height: 250%;

      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);

      .exterieur {
        .centre {
          .soustitre {
            display: block;
            transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

            justify-content: flex-start;

            padding-left: 11.5vw;
            p {
              font-size: calc(12px + 0.1vw);
            }

            @include media-breakpoint-up(md) {
              padding-left: 6vw;
              p {
                font-size: calc(12px + 0.3vw);
                line-height: calc(15px + 0.3vw);
              }
            }

            @include media-breakpoint-up(xl) {
              padding-left: 75px;
              padding-right: 35px;
              p {
                font-size: 16px;
                line-height: 19px;
              }
            }

            p {
              color: white;
            }
          }
        }
      }
    }

    .footerAccueil {
      position: absolute;
      top: 1vw;
    }

    .tableFooter-ratio-content {
      a {
        border-radius: 4.5vw;

        // Bouton jouer page accueil
        &.buttonJouer {
          padding: 2.5vw 4.5vw;
        }
        &.buttonJouerAccueil {
          font-size: 3.34vw;
          right: 4vw;
        }
      }

      .produit {
        @media (max-width: 355px) {
          padding-right: 7px;
          padding-left: 7px;
          margin-top: 0;
        }
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 0;
        padding-top: 10px;
        // Bouton jouer page produit
        .buttonJouer {
          padding: 2vw 5vw;
          font-size: 3.5vw;
          text-transform: capitalize;
          @include media-breakpoint-up(sm) {
            padding: 1vw 2vw;
            font-size: 2vw;
          }
        }
      }

      @include media-breakpoint-up(md) {
        a {
          border-radius: 5vw;

          // Bouton jouer page accueil
          &.buttonJouer {
            padding: 1.27vw 2.34vw;
          }
          &.buttonJouerAccueil {
            padding: 1vw 2.34vw;
            font-size: 1.41vw;
            right: 3vw;
          }
        }
        .produit {
          // Bouton jouer page produit
          .buttonJouer {
            padding: 1.7vw 3.4vw;
            font-size: 2.2vw;
          }
        }
      }

      @media (min-width: 991px) {
        .produit {
          // Bouton jouer page produit
          .buttonJouer {
            padding: 1.2vw 2.5vw;
            font-size: 18px;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        a {
          border-radius: 25px;

          // Bouton jouer page accueil
          &.buttonJouer {
            padding: 17px 30px;
          }
          &.buttonJouerAccueil {
            font-size: 18px;
            right: 32px;
          }
        }

        .produit {
          margin-top: 2px;
          // Bouton jouer page produit
          .buttonJouer {
            padding: 11px 20px;
            font-size: 14px;
            font-family: $font-graphik-regular;
          }
        }
      }
    }

    .buttonJouer {
      text-transform: uppercase;

      background: white;
      color: black;
      text-align: center;
      align-self: center;
      transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

      background: white;
      color: black;
    }

    .buttonJouer:hover {
      color: white;
    }

    .buttonJouerAccueil {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .centre {
      .ajustementHauteur {
        height: 0;
      }
      .soustitre {
        height: unset;
        padding-left: 8vw;
        font-size: 100%;
        font-weight: lighter;
      }
      .ajustementBouton {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .etendu {
    .wrapperBouton {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }
    .centre {
      .container {
        height: 33%;
        flex-direction: row;
        align-items: center;
        padding-left: 0;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-between;
    padding-bottom: 8vw;
    padding-top: 4vw;
    width: 100%;

    @media (max-width: 425px) {
      padding-bottom: 6vw;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 3vw;
      padding-top: 2vw;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 3rem;
      padding-top: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }

    &.produit {
      height: 100%;
      .nomTableHeures {
        overflow: hidden;
        max-width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .sousTitre {
          font-weight: lighter;
        }
        div.splitGauche {
          max-width: calc(50% - 10px);
        }
        .splitMoitie {
          max-width: 50%;
        }
        @include media-breakpoint-up(sm) {
          height: 40px;
          line-height: 17px;
          padding-top: 0;
        }
        @include media-breakpoint-up(md) {
          height: 43px;
          line-height: 17px;
          padding-top: 2px;
        }
        @include media-breakpoint-up(xl) {
          height: 40px;
          padding-top: 0;
        }
      }
    }
    .nomTableHeures {
      &.splitGauche.desactive {
        overflow: visible;
        max-width: calc(100% - 35px); //100% - marges fixes right et left
        span.titreProduit.desactive {
          max-width: 100%;
        }
      }
    }
    .contenu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      font-family: $font-graphik-regular;

      .centreVertical {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .descriptionAccueil {
        text-transform: uppercase;
        font-weight: lighter;
      }

      .espaceVertical {
        padding-top: 5px;
      }

      .sousTitreAccueil {
        font-size: calc(14px + 1vw);
        padding-top: 10px;
        .dealerName {
          line-height: 4vw;
        }
      }
      .descriptionAccueil {
        font-size: calc(5px + 1vw);
      }
      .heuresOuvertureLibelle {
        font-size: calc(10px + 0.1vw);
        padding-bottom: 3vw;
      }

      .heuresOuvertureLibelleProduit {
        font-size: calc(14px + 0.1vw);
        font-weight: lighter;
      }

      .desactive {
        font-weight: lighter;
        max-width: 90%;
      }

      .titreProduit,
      .tableComplete {
        font-size: calc(11px + 0.1vw);
        line-height: 20px;
        font-family: $font-graphik-regular;
      }

      .sousTitreProduit {
        font-size: calc(10px + 0.1vw);
        font-weight: lighter;
      }

      .heuresOuvertureProduit {
        font-size: calc(15px + 0.1vw);
      }

      .heuresOuvertureAccueil {
        font-size: 7vw;
      }

      @include media-breakpoint-up(sm) {
        .espaceVertical {
          padding-top: 20px;
        }

        .titreProduit,
        .tableComplete {
          font-size: calc(11px + 0.1vw);
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 1.3vw;
        }
      }

      @include media-breakpoint-up(md) {
        .espaceVertical {
          padding-top: 0.3vw;
        }

        .sousTitreAccueil {
          font-size: 2vw;
          .dealerName {
            line-height: 19px;
          }
        }
        .descriptionAccueil {
          font-size: 9px;
          line-height: 12px;
        }
        .heuresOuvertureLibelle {
          font-size: 1.24vw;
          padding-bottom: 1.5vw;
          line-height: 1vw;
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 1.3vw;
        }

        .titreProduit,
        .tableComplete {
          font-size: calc(11px + 0.1vw);
        }

        .heuresOuvertureAccueil {
          font-size: 3.5vw;
        }
      }

      @media (min-width: 991px) {
        .espaceVertical {
          padding-top: 0.6vw;
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 1.2vw;
        }
      }

      @include media-breakpoint-up(xl) {
        .espaceVertical {
          padding-top: 10px;
        }

        .sousTitreAccueil {
          font-size: 25px;
          .dealerName {
            line-height: 10px;
          }
        }
        .descriptionAccueil {
          font-size: 12px;
        }
        .heuresOuvertureLibelle {
          font-size: 13px;
          padding-bottom: 20px;
          line-height: 17px;
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 13px;
        }
        .titreProduit,
        .tableComplete {
          font-size: calc(11px + 0.1vw);
        }
        .heuresOuvertureAccueil {
          font-size: 40px;
        }
      }
    }
    /*.splitDroite.tableComplete {
      position: relative;
      top: 50%;
    }*/
    .tableComplete span {
      display: inline-block;
    }
    .sousTitreAccueil {
      .libelleAccueil {
        font-weight: lighter;
      }
    }

    .heuresOuvertureLibelle {
      font-weight: normal;
    }

    .nbJoueurs {
      margin-left: 2.5vw; //28px,
      position: relative;
      margin-right: 1.5vw; //10px; Design original pour le 1920
      margin-top: 1px;
      color: black;
      font-weight: 600;
      font-size: 18px;
      font-family: $font-graphik-regular;
      line-height: 15px;
      display: inline-block;
      padding-bottom: 0px;
      @include media-breakpoint-up(md) {
        margin-left: calc(13px + 0.1em); //28px
        margin-right: calc(7px + 0.1em); //10px
      }
      @include media-breakpoint-up(xl) {
        margin-left: calc(26px + 0.1em); //28px Design 1920px
        margin-right: calc(9px + 0.1em); //10px; Design 1920px
      }
    }
    .iconeJoueurs {
      fill: white;
      background-color: black;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      vertical-align: middle;
      position: relative;
      padding: 3px;
      @include media-breakpoint-up(sm) {
        height: 2.6rem;
        width: 2.6rem;
      }
      @include media-breakpoint-up(md) {
        height: 2.7rem;
        width: 2.7rem;
      }
      @include media-breakpoint-up(xl) {
        height: 2.8rem;
        width: 2.8rem;
        position: relative;
      }
    }
    .dernierSiege,
    .placesDisponibles {
      .iconeJoueurs {
        fill: #60ca4a; //couleurs blackjack personnage
      }
    }

    .infoActive {
      border-color: rgba(255, 255, 255, 0.3);
      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    .TableInfoButtonBase {
      opacity: unset;
      padding: unset;
    }

    .splitGauche {
      .TableInfoButtonChevron {
        border: none;
      }

      .TableInfoButtonChevron {
        height: 9vw;
        width: 9vw;
      }

      @include media-breakpoint-up(md) {
        .TableInfoButtonChevron {
          height: 4vw;
          width: 4vw;
        }
      }

      @include media-breakpoint-up(xl) {
        .TableInfoButtonChevron {
          height: 50px;
          width: 50px;
        }
      }

      .TableInfoButtonChevron::before {
        content: none;
      }
      .infoActiveChevron {
        transform: rotate(180deg);
      }
      .espace {
        margin-right: 5px;
      }
      &.espace {
        margin-right: 6px;
      }
      .TableInfoButtonI {
        border-width: 1.5px;
        height: 6vw;
        width: 6vw;
        font-size: 5vw;
        line-height: 6vw;
        position: relative;

        @include media-breakpoint-up(sm) {
          height: 3vw;
          width: 3vw;
          line-height: 3vw;
          font-size: 2.5vw;
        }
        @include media-breakpoint-up(md) {
          height: calc(12px + 1vw);
          width: calc(12px + 1vw);
          line-height: 2.5vw;
          font-size: 2vw;
        }
        @include media-breakpoint-up(xl) {
          height: 24px;
          width: 24px;
          line-height: 21px;
          font-size: 20px;
        }
      }
      .titreProduit:not(.desactive) {
        position: relative;
        display: inline-block;
      }
    }

    .splitDroite {
      float: right;

      .espace {
        margin-left: 9px;
      }

      .TableInfoButtonI {
        border-width: 1.5px;

        height: 6vw;
        width: 6vw;
        font-size: 5vw;
        line-height: 6vw;

        @include media-breakpoint-up(sm) {
          height: 3vw;
          width: 3vw;
          line-height: 3vw;
          font-size: 2.5vw;
        }
        @include media-breakpoint-up(md) {
          height: calc(12px + 1vw);
          width: calc(12px + 1vw);
          line-height: 2.5vw;
          font-size: 2vw;
        }
        @include media-breakpoint-up(xl) {
          height: 24px;
          width: 24px;
          line-height: 21px;
          font-size: 20px;
        }
      }
    }

    .espaceFooterAccueil.espaceFooterAccueilEtendu {
      width: 100%;
    }

    .espaceFooterAccueil {
      width: 100%;
      padding-left: 2vw;
    }

    @include media-breakpoint-up(md) {
      .espaceFooterAccueil {
        padding-left: 2vw;
      }
    }

    @include media-breakpoint-up(xl) {
      .espaceFooterAccueil {
        max-width: 500px;
        padding-left: 25px;
      }
    }
  }
}

// Couleurs par thèmes (Vlada)
// http://intranetvpcap.loto-quebec.com/_APPROBATION_WEB/produits-projets-2021/02-2021-offshore-guide-styles-graphiques/start.html#id=tgle30&p=6_0_couleurs
.blackjack {
  // Vert
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #205447; // Couleurs par thÃ¨mes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #0f6d56; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}

.roulette {
  // Bleu
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #163b75; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #194ea1; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.jeuxendirect {
  // Bleu ciel
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #3a71a9; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #01aef0; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.baccara {
  // Rouge
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #851929; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #bc122e; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.poker {
  // Bleu marin
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #0d035b; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #3135a6; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.des {
  // Violet
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #730040; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #a51c5d; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}

.theme-bg-ced section.section-page {
  .placesDisponibles .libelle {
    text-align: right;
  }
  &.section-verte {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #00a389;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #00a389;
    }
    .langue {
      border: solid 3px #00a389;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #a7a5aa; //Couleurs personnage blackjack siège infini
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-bleu {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #0a71f0;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #0a71f0;
    }
    .langue {
      border: solid 3px #0a71f0;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #0a71f0;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-bleu-ciel {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #01aef0;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #01aef0;
    }
    .langue {
      border: solid 3px #01aef0;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #01aef0;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-rouge {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #f03447;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #f03447;
    }
    .langue {
      border: solid 3px #f03447;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #f03447;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-bleu-marin {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #4f71f8;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #4f71f8;
    }
    .langue {
      border: solid 3px #4f71f8;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #4f71f8;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-violet {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #d63a93;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #d63a93;
    }
    .langue {
      border: solid 3px #d63a93;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #d63a93;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
}
.theme-bg-ced section.section-page {
  //Bordure couleur famille de  l'exterieur de l'icône bulle
  &.section-verte .bulleIcon.bordure:before {
    border-color: #00a389 transparent transparent #00a389;
  }
  &.section-bleu .bulleIcon.bordure:before {
    border-color: #0a71f0 transparent transparent #0a71f0;
  }
  &.section-bleu-ciel .bulleIcon.bordure:before {
    border-color: #01aef0 transparent transparent #01aef0;
  }
  &.section-rouge .bulleIcon.bordure:before {
    border-color: #f03447 transparent transparent #f03447;
  }
  &.section-bleu-marin .bulleIcon.bordure:before {
    border-color: #4f71f8 transparent transparent #4f71f8;
  }
  &.section-violet .bulleIcon.bordure:before {
    border-color: #d63a93 transparent transparent #d63a93;
  }
}
.theme-bg-ced section.section-page {
  //Background noir de l'interieur de l'icône bulle
  &.section-verte,
  &.section-bleu,
  &.section-bleu-marin,
  &.section-rouge,
  &.section-bleu-ciel,
  &.section-violet {
    .bulleIcon:after {
      border-color: black transparent transparent black;
    }
  }
}
.lobby-table-block.not-operational {
  .TableFooter--3hms2 {
    opacity: unset;
    pointer-events: none;
  }
}

.lobby-table-block {
  .TableFooter--3hms2 {
    padding: 0;

    .non-operationnel {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.lobby-table-block-cnt {
  .TableFooter--3hms2 {
    .tableFooter-ratio-content {
      bottom: 0;

      .exterieur {
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .centre {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

.owl-carousel .owl-item:after {
  display: none;
}

@include media-breakpoint-up(md) {
  .owl-carousel .owl-item:after {
    display: none;
  }
}

.blackTable {
  font-size: 1.4rem;
  line-height: 1.5;
}

.lightning-effect {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  height: 220px;
  width: 364px;
  background-image: url("../img/casino/lobbylight.53c1c.png");
  -webkit-animation: playh 12s steps(5) infinite, playv 12s steps(10) infinite;
  animation: lightning-effect-playh 12s steps(5) infinite,
    lightning-effect-playv 12s steps(10) infinite;
}

@include media-breakpoint-up(xl) {
  .col-2 .lightning-effect {
    width: 170px;
    height: 80px;
  }
}

@include media-breakpoint-up(xl) {
  .col-3 .lightning-effect {
    height: 140px;
    width: 280px;
  }
}

// lightning-effect: L'éclair apparaît après 1s, pendant 1s, puis disparait pendant 10s.
@-webkit-keyframes lightning-effect-playh {
  0%,
  20% {
    background-position-x: 0px;
  }
  22%,
  100% {
    background-position-x: -1820px;
  }
}
@keyframes lightning-effect-playh {
  0%,
  20% {
    background-position-x: 0px;
  }
  22%,
  100% {
    background-position-x: -1820px;
  }
}

@-webkit-keyframes lightning-effect-playv {
  0%,
  20% {
    background-position-y: 0px;
  }
  30%,
  100% {
    background-position-y: -2220px;
  }
}
@keyframes lightning-effect-playv {
  0%,
  20% {
    background-position-y: 0px;
  }
  30%,
  100% {
    background-position-y: -2220px;
  }
}

.lobby-table-logo {
  position: absolute;
  bottom: 100%;
  position: absolute;
  right: 20px;
  top: 20px;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.lobby-table-logo-image {
  width: 130px;
  position: relative;
  top: -24px;
  left: 25px;
}

@include media-breakpoint-up(xl) {
  .col-2 .lobby-table-logo-image {
    width: 50px;
    top: -15px;
  }
}
@include media-breakpoint-up(xl) {
  .col-3 .lobby-table-logo-image {
    width: 100px;
    top: -16px;
  }
}

@media (min-width: 1025px) {
  .TableFooter--3hms2 .container .contenu .titreProduit,
  .TableFooter--3hms2 .container .contenu .tableComplete {
    font-size: calc(12px + 0.1vw);
    line-height: 17px;
  }
}

@media (max-width: 1024px) {
  .TableFooter--3hms2 {
    height: 140px;
    padding-bottom: 30%;
  }
}

.espaceNombreJoueurs.splitDroite.centreVerticalRelative {
  margin-right: 2px;
}

@media (max-width: 767px) {
  .TableFooter--3hms2 {
    height: 180px;
  }

  .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
    padding: 5vw 8vw;
    font-size: 6.5vw;
    border-radius: 10.5vw;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
    padding: 2.75vw 6vw;
    font-size: 4.5vw;
    border-radius: 10.5vw;
  }
}

@media (max-width: 425px) {
  .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
    padding: 4vw 7vw;
    font-size: 4.5vw;
    border-radius: 10.5vw;
  }

  .TableFooter--3hms2 .container .contenu .titreProduit {
    font-size: calc(12px + 0.1vw);
  }

  .TableFooter--3hms2 {
    height: 140px;
  }
}

.isTablePremiereOuverte {
  .TableFooter--3hms2 {
    height: 100%;
  }
  .lobby-table-block-body {
    .langue {
      @include media-breakpoint-up(xxxl) {
        //media > 1920px
        font-size: 17px;
        line-height: 22px;
        left: 25px;
        top: 83px;
        padding: 5px 8px 5px 5px;
        min-width: 105px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
      }
    }
    .bulleMessage {
      @include media-breakpoint-up(xxxl) {
        //media > 1920px
        line-height: 22px;
        font-size: 17px;
        position: relative;
        top: 6px;
      }
    }
    .langue.bulleIcon .presentateur {
      @include media-breakpoint-up(xxxl) {
        //media > 1920px
        height: 30px;
        width: 30px;
      }
    }
  }
}
.desktop .lobby-table-block.isTablePremiereOuverte .lobby-table-limits {
  @include media-breakpoint-up(xxxl) {
    //media > 1920px
    font-weight: 600;
    font-family: graphik_regular;
    line-height: 18px;
    font-size: 20px;
    left: 20px;
    top: 15px;
    padding-top: 17px;
    padding-bottom: 13px;
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 73px;
    -webkit-border-radius: 73px;
    -moz-border-radius: 73px;
  }
}
.TableFooter--3hms2 .container .isTablePremiereOuverte span {
  font-family: graphik_regular;
}

.TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
  display: inline-block;
}
@media (min-width: 992px) and(max-width:1200px) {
  .tableFooter-ratio {
    height: 0;
    padding-bottom: 35%;
  }
}
.row.gutters-xsmall {
  .colonne.col-2,
  .colonne.col-3,
  .colonne.col-4 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.TableFooter--3hms2 .container .splitGauche .TableInfoButtonI {
  display: flex;
  justify-content: center;
  align-items: center;
}
.espaceNombreJoueurs {
  align-self: center;
}
.TableFooter--3hms2 .container .contenu .heuresOuvertureLibelle {
  padding-bottom: 0px;
}
.TableFooter--3hms2 .container .contenu .espaceFooterAccueil .heuresOuvertureLibelle {
  margin-top: 3px;
}
.TableFooter--3hms2
  .container
  .contenu
  .espaceFooterAccueil
  .heuresOuvertureLibelle
  .heuresOuvertureAccueil {
  font-size: 4vw;
  @include media-breakpoint-up(sm) {
    font-size: 3vw;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.75vw;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}
@include media-breakpoint-up(xl) {
  .colonne.col-12.col-md-6.col-lg-4.col-3.col-xl-3 .lobby-table-block:not(.isTablePremiereOuverte) {
    //@include mediaLargeCol-3;
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit {
      padding-right: 7.5px;
      padding-left: 7.5px;
      padding-top: 5px;
    }
    .lobby-table-limits {
      font-size: 10px;
      left: 10px;
      top: 10px;
      padding-top: 6px;
      padding-bottom: 5px;
      padding-left: 25px;
      padding-right: 25px;
    }
    .nbplayersIcon {
      height: 25px;
    }
    .TableFooter--3hms2 .container .splitGauche .TableInfoButtonI {
      height: 18px;
      width: 18px;
      line-height: 15px;
      font-size: 15px;
    }
    .lobby-table-block .lobby-table-info {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
    .lobby-table-block .lobby-table-info p {
      font-size: 1rem;
      line-height: 1.1rem;
    }
    .TableFooter--3hms2 .container .nbJoueurs {
      font-size: 13px;
      margin-left: calc(13px + 0.1rem);
      margin-right: calc(6px + 0.1em);
    }
    .TableFooter--3hms2 .container .iconeJoueurs {
      height: 2rem;
      width: 2rem;
      padding: 2px;
    }
    .TableFooter--3hms2 .container .contenu .titreProduit,
    .TableFooter--3hms2 .container .contenu .tableComplete {
      font-size: calc(10px + 0.1vw);
      line-height: 15px;
    }
    .placesDisponibles {
      font-size: 0.8rem;
      line-height: 11px;
    }
    .dernierSiege {
      font-size: calc(7.5px + 0.1vw);
      line-height: 9px;
      height: 28px;
      text-align: right;
      .nbplayersIcon {
        margin: -1px -1px -1px -1px;
      }
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures .splitMoitie.dernierSiege {
      max-width: 40%;
    }
    .espaceNombreJoueurs.splitDroite.centreVerticalRelative {
      margin-right: 1px;
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures div.splitGauche {
      max-width: calc(50% - 5px);
    }
    .TableFooter--3hms2 .container .splitDroite .espace {
      margin-left: 7px;
    }
    .TableFooter--3hms2 .container .splitGauche .espace {
      margin-right: 3px;
    }
    .TableFooter--3hms2 .container .contenu .desactive {
      max-width: 100%;
    }
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
      padding: 10px 18px;
      font-size: 13px;
    }
    .TableFooter--3hms2 .container .contenu {
      padding-bottom: 10px;
    }
    .TableFooter--3hms2 {
      padding-bottom: 15px;
    }
    .tableFooter-ratio .tableFooter-ratio-content {
      padding-bottom: 10px;
    }
    .lobby-table-block-body .bulleMessage {
      font-size: 9px;
      margin-right: 10px;
    }
    .lobby-table-block-body .langue {
      padding: 3px 0px;
    }
    .lobby-table-block-body .langue.bulleIcon .presentateur {
      margin-left: 5px;
      margin-left: 3px;
    }
    .lobby-table-block-body .bulleIcon.bordure::before {
      left: 9px;
      bottom: -10px;
    }
    .lobby-table-block-body .langue {
      left: 18px;
      top: 45px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .colonne.col-12.col-md-6.col-lg-4.col-2.col-xl-2 .lobby-table-block:not(.isTablePremiereOuverte) {
    //@include mediaLargeCol-2;
    .smallTable {
      display: inline-flex;
      align-items: center;
    }
    .lobby-table-block-body .lobby-table-limits {
      font-size: 10px !important;
      left: 8px !important;
      top: 6px !important;
      padding-top: 4px !important;
      padding-bottom: 3px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .lobby-table-block-body .bulleIcon::after {
      left: 10px;
      bottom: -6.5px;
      border: 3.5px solid;
      border-color: black transparent transparent black;
    }
    .lobby-table-block-body .bulleMessage {
      font-size: 7px;
      margin-right: 7px;
      margin-left: 1px;
      top: 1px;
    }
    .lobby-table-block-body .langue {
      padding: 2px 0px;
    }
    .lobby-table-block-body .langue.bulleIcon .presentateur {
      margin-left: 5px;
      margin-left: 3px;
      height: 12px;
      width: 12px;
    }
    .lobby-table-block-body .bulleIcon.bordure::before {
      left: 9px;
      bottom: -10px;
    }
    .lobby-table-block-body .langue {
      left: 14px;
      top: 37px;
    }
    .nbplayersIcon {
      height: 25px;
    }
    .TableFooter--3hms2 .container .splitGauche .TableInfoButtonI {
      height: 18px;
      width: 18px;
      line-height: 15px;
      font-size: 15px;
    }
    .TableFooter--3hms2 .container .nbJoueurs {
      font-size: 12px;
      margin-left: calc(1px + 0.1rem);
      margin-right: calc(11px + 0.1em);
    }
    .TableFooter--3hms2 .container .iconeJoueurs {
      height: 2rem;
      width: 2rem;
      padding: 2px;
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures.ferme {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
    .heuresOuvertureLibelle {
      flex-direction: row;
    }
    .TableFooter--3hms2 .container .contenu .desactive {
      max-width: 100%;
    }
    .TableFooter--3hms2 .container .contenu .titreProduit,
    .TableFooter--3hms2 .tableComplete {
      font-size: calc(9px + 0.1vw);
      line-height: 14px;
    }
    .TableFooter--3hms2 .container .contenu .heuresOuvertureProduit,
    .TableFooter--3hms2 .container .contenu .heuresOuvertureLibelleProduit {
      font-size: 12px;
    }
    .infoEtatTable {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .pasOuvert .infoEtatTable {
      justify-content: flex-end !important;
    }
    .placesDisponibles {
      font-size: 0.85rem;
      line-height: 11px;
      max-width: 20px;
      text-align: left;
      .nbplayersIcon {
        margin-right: 5px;
      }
    }
    .dernierSiege {
      font-size: 8px;
      line-height: 9px;
      padding: 0px 5px 0px 0px;
      height: 28px;
      text-align: left;
      .nbplayersIcon {
        margin: -1px 4px -1px 0px;
      }
    }
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
      padding: 6px 16px;
      font-size: 10px;
    }
    .TableFooter--3hms2 {
      padding-bottom: 0;
      padding-bottom: 25px;
    }
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit {
      padding-top: 2px;
      margin-top: 0px;
      padding-right: 7px;
      padding-left: 7px;
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures div.splitGauche {
      max-width: calc(100% - 10px);
    }
  }
}

.col-2 .lobby-table-block:not(.isTablePremiereOuverte) .infoEtatTable {
  .tableComplete,
  .infoEtatJoueurs {
    @include media-breakpoint-up(xl) {
      display: inline-flex !important;
    }
  }
  &.centrer {
    .buttonJouer {
      @media (max-width: 1200px) {
        justify-content: center;
      }
    }
  }
}
.col-2 .lobby-table-block:not(.isTablePremiereOuverte) .infoEtatTable.centrer {
  @include media-breakpoint-up(xl) {
    justify-content: center;
  }
}
.col-2 {
  @include media-breakpoint-up(xl) {
    .lobby-table-block .lobby-table-info {
      font-size: 1rem !important;
      line-height: 1rem !important;
    }
    .lobby-table-block .lobby-table-info p {
      font-size: 1.1rem !important;
      line-height: 1.1rem !important;
    }
  }
}
.col-2 .notSmallGrille {
  @media (max-width: 1200px) {
    display: inline-flex !important;
  }
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}
