.panneau {
	.ej-panel-noir-heading {
		background: linear-gradient(180deg, rgba(57,56,62,1) 0%, rgba(57,56,62,1) 49%, rgba(42,41,46,1) 51%, rgba(39,38,43,1) 55%, rgba(26,25,30,1) 100%);
	}

	.panel-body {
		.panel-heading {
			background: #242424;
		}
	}
}

.panel {
	background: #fff;
	border-radius: 0;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
    text-align: left;

    [data-toggle='collapse'] {
    	cursor: pointer;
    }
    
	.fa-chevron-down {
	    float: right;
	  	transition: transform 1s;

		@at-root .panel [aria-expanded="true"] .fa-chevron-down  {
			transform: rotate(-180deg);
		}
	  	
	}

	.panel-title {
		font-size: 1.6rem;
		line-height: 1;
		margin-bottom: 0;
	    width: 100%;
	}
	
	.panel-heading {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding: 10px 15px;

	    [data-toggle="collapse"] {
	        &:after {
	            font-family: 'Glyphicons Halflings';
	            float: right;
	            color: gray;
	        }
	    }
		
	    p {
	    	text-transform: initial;
			font-weight: 600;
	    }
	   
		>.ej-panel-blanc-title {
			&.panel-heading-rotate-arrow {
				background-color: red;
			}
		} 
	}
	
	.panel-body {
	    padding: 8px;
		background-color: transparent;
		
		.screenshot {
			width: 100%;
		}
	}
	
	.panel-group {
	    .panel {
	        border-radius: 0;
	    }
	    margin-bottom: 0px;
	}
	
	&.panel-default {
		border: 1px solid #ddd;

		.ej-panel-noir-heading {
		  cursor: pointer;
		  position: relative;
			border-bottom: 0 none transparent;
			padding: 10px 15px;
		}
		.ej-panel-blanc-heading {
		    background-color:  #FFFFFF; /* Blanc */
			&.ej-panel-heading-open {
			    background-color: #EBEBEB; /* Gris */
			}
		}
	}
}

.panel.ej-panel-noir {
  border: 0 none transparent;
  background-color: transparent;
}

.ej-panel-noir-body {
  color: #ccc;
  strong {
    color: #fff;
  }
}

.ej-panel-noir-title {
  .fa-chevron-up {
    &:before {
      margin-top: -20px;
    }
  }
  color: #fff;
  display: inline-block;
  font: 1.2em/1em 'Tahoma Bold',
  Arial,
  Helvetica,
  sans-serif;
  text-shadow: 1px 1px 2px #333;
  width: 100%;
}

.ej-panel-blanc-body {
  a {
    color: #004990;
    font-weight: bold;
  }

  p {
	  font-size: 1.7rem;
	  margin-bottom: 0;
  }
}

/* TODO: Pourquoi ce bloc est-il ici? */
div#generiquePageContainer.mainContainer.container {
    >.btn {
        border-radius: 0;
    }

}