.modal--cta-stop {
    background-color: black;
    border: solid #00407d;
    border-width: 2px;
    border-radius: 5px;
    height: auto;
    max-width: 830px;
    pointer-events: auto;
    z-index: zindex(subModalJeu);
}

.modal--cta-stop__content {
    color: #fff;
    font-size: 1.6rem;
    padding: 4rem 4rem 2rem;

    p {
        line-height: 1.5;
    }
}

.modal--cta-stop__cta-close {
    background-color: #000;
    border: solid #00407d 2px;
    border-radius: 50%;
    height: 4rem;
    position: absolute;
    transform: translate(50%,-50%);
    right: 0 !important;
    top: 0 !important;
    width: 4rem;
}

.modal--cta-stop__cta-primary {
    background: #00407d;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
    border-radius: 4px;
    margin-bottom: 2rem;
    padding: 15px 40px;
}