
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
  }

  .element-margin > div {
    margin-bottom: 3rem;
  }
  
   @media screen and (max-width: 768px) {
    .col-sm-no-padding 
      {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }  
  }

  @media screen and (max-width: 576px) {
    .col-xs-no-padding 
      {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }  
  }
