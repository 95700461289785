
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.casino-jeu {
  &-chargement {
    align-items: center;
    display: flex;
    min-height: 190px;
    justify-content: center;
    width: 243px;
  }
}

.bloc-jeu-generique {
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2 !important;
}

.bloc-jeu-main {
  &:hover {
    z-index: 4;
    .warning-consent {
      z-index: 4;
    }
    .ej-block-jeu-video {
      .bloc-jeu {
        display: none;
      }
      .ej-video-iframe-container {
        position: relative;
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
        }
        iframe {
          width: 100%;
          height: 100%;
          max-width: 100%;
          margin-top: -5px;
        }
      }
    }
  }
  .bloc-jeu-lien {
    position: relative;
  }

  .loading-ring {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    transition: 2s;
    div {
      width: 40px;
      height: 40px;
      border-width: 3px;
      margin: 0;
    }
  }
  .warning-consent {
    position: absolute;
    left: unset;
    bottom: 1em;
    border-radius: 12px;
    padding: 2px 6px 2px 6px;
    z-index: 2;
    &:hover,
    &:focus,
    &:focus-visible {
      .tooltip-favoris-text {
        visibility: visible;
      }
    }
  }
  .tooltip-favoris-text {
    position: absolute;
    display: inline-block;
    padding: 6px;
    left: 0em;
    bottom: 1.95em;
    width: 233px;
    visibility: hidden;
    background-color: #fff9e6;
    border: 1px solid #ffbc06;
    font-family: $font-graphik-regular;
    font-size: 12px;
    color: black;
    line-height: 14px;
    text-align: left;
  }
}
.mobile_device_ej {
  .casino-jeu {
    &-chargement {
      width: 100%;
      min-height: 110px;
      padding-top: 10px;
    }
  }
}

.warning-consent-mobile {
  position: absolute;
  right: 0em;
  bottom: 0.25em;
  border-radius: 12px;
  padding: 7px 16px 12px 6px;
  z-index: 2;
  &:hover,
  &:focus,
  &:focus-visible {
    .tooltip-favoris-text-mobile {
      visibility: visible;
    }
  }
}
.tooltip-favoris-text-mobile {
  position: absolute;
  display: inline-block;
  padding: 6px;
  right: 2em;
  bottom: 0.5em;
  width: 233px;
  visibility: hidden;
  background-color: #fff9e6;
  border: 1px solid #ffbc06;
  font-family: $font-graphik-regular;
  font-size: 12px;
  color: black;
  line-height: 14px;
  text-align: left;
}

.warning-consent-jeu-mobile {
  position: absolute;
  z-index: 4;
  right: 1rem;
  bottom: 1rem;
  border-radius: 12px;
  padding: 7px 16px 12px 6px;
  &:hover,
  &:focus,
  &:focus-visible {
    .tooltip-favoris-text-jeu {
      visibility: visible;
    }
  }
}
.warning-consent-jeu-desktop {
  position: absolute;
  z-index: 4;
  right: 2rem;
  top: 1rem;
  border-radius: 12px;
  padding: 2px 6px 2px 6px;
  &:hover,
  &:focus,
  &:focus-visible {
    .tooltip-favoris-text-jeu {
      visibility: visible;
    }
  }
}
.tooltip-favoris-text-jeu {
  position: absolute;
  display: inline-block;
  padding: 6px;
  bottom: 0.75em;
  right: 2em;
  width: 233px;
  visibility: hidden;
  background-color: #fff9e6;
  border: 1px solid #ffbc06;
  font-family: $font-graphik-regular;
  color: black;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}

.inactive {
  opacity: 0.7 !important;
}
