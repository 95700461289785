.for-carousel-promotions {
    @include media-breakpoint-down(xs) {
        display: block;
    }

    @include media-breakpoint-up(md) {
        margin-top: 20px;
    }
}

.carousel-promotions {
    display: block;
    overflow: visible;
    padding-bottom: 2rem;
    min-height: 243px;

    @include media-breakpoint-up(md) {
        min-height: 400px;
    }

    .owl-stage-outer {
        overflow: hidden;
        padding-top: 15%;

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 3.3%;
        }

        .owl-stage {
           @include media-breakpoint-only(md) {
                padding-top: 6%;
            }
        }

        .content,
        a,
        .tile .tile-inner,
        .tile-inner {
            overflow-y: visible;

            @include media-breakpoint-up(xs) {
                overflow: visible;
            }
        }

        div.tile-inner .filiale {
            z-index: 2;
            position: relative;
            height: 10%;
			margin-top: -10%;
            background-color: #00407d;

            @include media-breakpoint-up(lg) {
                margin-top: -12%;
                height: 12%;
            }

            p {
                position: relative;
                text-align: center;
                text-transform: uppercase;
                top: 50%;

                @include media-breakpoint-up(md) {
                    font-size: 2vw;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 2.1rem;
                }

                transform: translateY(-50%);
            }
        }
    }

    .media-component .content.content-text {
        height: calc(50% - 1.4vw);
        left: 10%;
        position: absolute;
        right: 10%;
        top: calc(50% + 1.4vw);
        width: auto;

        .under-title-text {
            font-size: 1rem;
            line-height: 1;
            margin-bottom: 0.5rem;
            padding: 0;
            text-transform: uppercase;
        }

        .over-title-text {
            color: #fff;
            font-family: $lato-font-medium;
            font-size: 1.4rem;
            line-height: 1.1;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 2.1rem;
            }


        }

        .over-title-text {
            + .under-title-text {
                bottom: 1.5rem;
                line-height: 1;
                position: absolute;

                @include media-breakpoint-up(md) {
                    padding-bottom: 0.9rem;
                    padding-top: 0.9rem;
                }

                @include media-breakpoint-up(lg) {
                
                }
            }
        }
	}
	
	
}
