
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
	}

  .titre{
    color:black; 
    @include ms-respond(font-size, 1);
    border-top-left-radius: ms(2);
    border-bottom-right-radius:ms(2);
    margin-left:-7%;
    margin-top: -1rem; 
    padding:1%;
    display:flex; 
    justify-content: flex-start;
    align-items:center;
    margin-bottom:3%;
    background-color: transparent;
    text-align: left;    
  }

  .titre-no-image{
    height: ms(7);
    width: 50%;
  }

  .titre-image{
    height: 7rem;
    width: 70%;
  }

  .image{
    height: 100%;
    width: auto;
  }

  .div_container{
    padding-left:5%;
    padding-right: 5%;
  }

  .text-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .texte{
    color:black; 
    @include ms-respond(font-size, 0);
  }

  @media screen and (max-width: 992px) {
    .titre {
      width: 90%;
      margin-left: -9%;
    }

    .text-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

