button {
    outline: none;
    outline-color: transparent;
}

.noPadding {
    padding: 0;
}

.jeuLightboxContainer {
    display: none;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.modal-header {
    background: transparent;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
}

.modal-footer {
    background: transparent;
    background-color: transparent;
    border: 0;
    box-shadow: none;
}

.modal-content {
	&.igtIframe{
	    background: #000000;
    	background-color: #000000;
	}
    background: transparent;
    background-color: transparent;
    border: 0;
    box-shadow: none;
}


.modal-body {
    background: transparent;
    background-color: transparent;
    border: 0;
    box-shadow: none;
}

.modal {
    background: transparent;
    background-color: transparent;
    border: 0;
    box-shadow: none;
}

.btnLancerJeu {
    padding: 15px 40px;
    border-radius: 5px;
}

.btn-close-footer {
    text-align: center;
    align-self: center;
    border-radius: 3px;
}

.btn-full-screen {
    text-align: center;
    align-self: center;
    border-radius: 3px;
}

.btn_x_close {
    color: #f6c812;
    float: right;
    font-size: 24px;
    font-family: sans-serif;
    font-weight: lighter;
    margin-right: -20px;
    margin-bottom: -100px;
    visibility: collapse;
    &:hover {
        color: #ffe588;
        text-decoration: none;
        cursor: pointer;
        visibility: collapse;
    }
    &:focus {
        color: #ffe588;
        text-decoration: none;
        cursor: pointer;
        visibility: collapse;
    }
}

.modalFooterPrincipal {
    .btn {
        margin-bottom: 0 !important;
    }
    text-align: center;
    margin: 0;
}

.modalDialogPrincipal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width:100%;
    overflow: auto;
}

.modalContentPrincipal {
    justify-content: center;
}

.modal-wrapper-jeu-secondary {
	z-index: zindex(modalSecondaryJeu);
}

.modalQuitter {
    .modalDialogQuitter {
    	display: flex;
    	height: 100%;
		margin: 0 auto;
		pointer-events: auto;
	}

	.modalBodyQuitter {
    	text-align: center;
	}

	.confirmText {
    	font-family: sans-serif;
    	color: white;
    	font-weight: 500;
    	font-size: 1.1em;
    	line-height: 2.4em;
	}

	.confirmTextAdditional {
    	font-size: 0.85em;
    	font-family: sans-serif;
    	line-height: 1.1em;
    	color: white;
	}

	button.btn-close-confirm-set {
    	min-width: 250px;
    	margin: 5px;
    	padding: 10px;
    	border-radius: 4px;
    	border:0;
	}

	.btn-danger {
		background-color: #d9534f;

		&:hover,
		&:focus {
			background-color: #c9302c;
		}
	}

	.boutonFermerLarge {
    	min-width: 250px;
	}

	.warningConfirm {
    	background: rgba(0, 0, 0, 0.85);
    	background-color: rgba(0, 0, 0, 0.85);
	}

	div.modal-body~button {
    	padding: 2px 7px;
	}

	.btn {
		font-size: 1.4rem;
	}
}

.gameIframe {
    box-shadow: none !important;
    float:left;
    background-color: black;
}

.iFrameStroke {
    border: 1px solid yellow;
}

.ejSidebarJeu{
	display: flex;
	flex-direction: column;
	float: left;
    width: 250px;
    background-color: black;
    height: 800px;
	color:#BBB;
	
    &.fermer{
  		width: 50px;
  		height: 132px !important;
		opacity: 1;
		  
		.ejicon-arrow-left,  
		.gagnants,
		.jeuxRecents,
		.jeu-cta-stop,
		.jeu-responsable,
		.pubZone {
			display: none;
		}
		  
  		.controls div{
  			float: none;
  			padding: 0 0 10px;
  		}
  		
  		.ejicon-arrow-right{
  			display: block;
  		}
    }
    .controls{
		text-align: right;
		font-size: 2.5em;
		margin: 9px 10px 8px;

		div{
			cursor:pointer;
			float: right;
			padding: 0 10px;
		}
	}
	.ejicon-arrow-left{
  		display: block;
  		text-align: center;
  	}
  	.ejicon-arrow-right{
  		display: none;
  		text-align: center;
  	}
  	.ejicon-enlarge,
  	.ejicon-shrink{
  		font-size:.9em;
  		line-height: 1.2em;
  		text-align: center;
  	}
  	.ejicon-cancel-circle{
  		text-align: center;
  	}
    h4{
	 	background-color: #073157;
		padding: 4px 10px 3px;
		font-size: 14px;
		margin: 0;
		span{
			margin-right: 10px;
			font-size: 18px;
		}   
    }
	.pubZone{
		.row{
			margin: 0;
		}
		.backup-after-expiry{
			display:none;
		}
		height: 233px;
		overflow:hidden;
	}
	.gagnants{
		transition-delay: .75s;
		height: 220px;
	}
    .jeuxRecents{
		flex-grow: 1;
		z-index: 2;

    	.col-6{
    		padding-top: 5px;
    		height: 70px;
    	}
    	img{
    		width: 100%;
    		max-height: 65px;
    		border: 1px solid #2c3534;
    		&:hover{
    			border: 2px solid #0055a7;
    		}
    	}
    	.scroll-pane{
    		padding: 0px;
    		height: 232px;
    		outline: none;
    		overflow: auto;
		    float: left;
    	}
    	.jspContainer{
    		width: 250px;
    		height: 232px;
    	}
    	.jspPane{
    		padding: 0px;
    		width: 230px;
    		top: 0px;
    	}
		.jspTrack{
			height: 232px;
		}
		.jspDrag{
			height: 80px;
			top: 0px;
			background-color: #5ba9f4;
		}
    }
    .jeu-responsable{
    	text-align: center;
		margin-bottom: 5px;
		
		a {
			outline: none;
		}
    }
}

.close-btn-container{
	float: left;
	.closefirstmodal{
		font-size:8em;
		margin-left:20px;
		color:black;
		cursor:pointer;
	}
}

#Jeu{
	background: url(/dam/espacejeux/casino/bg-generique.jpg) no-repeat center center fixed; 
  	background-size: cover;
  	padding-right: 0 !important;
  	z-index: zindex(modalJeu);
}

#lightboxJeuIframe{
	display: block;
	width: 800px;
	height: 800px;
}

.gagnants{
	> div{
		height: 200px;
	}
	ul{
		overflow:hidden;
		margin-bottom: 20px;
		li{
			overflow:hidden;
			height: 30px;
			margin: 5px;
			display: block;
			float: left !important;
			width: 240px;
			span{
				width: 100%;
				color: white;
			}
			.gain{
				text-align: right;
				span{
					font-size: 13px;
					font-weight: bold;
				}
			}
			.bleuclair{
				color: #5ba9f4;
			}
			.nom{
				font-size: 13px;
			}
			.nom-jeu{
				font-size:10px;
			}
		}
	}
}

.jeu-cta-stop {
	align-items: center;
	background: linear-gradient(145deg, rgba(114,146,24,1) 0%, rgba(61,85,4,1) 100%);
	border: 1px solid #000;
	border-radius: 0.6rem;
	color: #fff;
	display: flex;
	padding: 0.3rem 1rem;
	text-align: left;
	width: 100%;
}

.jeu-cta-stop__icon {
	
	&::before {
		background: black;
		border-radius: 50%;
		font-size: 2.6rem;
		margin-right: 1rem;
	}
}

.jeu-cta-stop__text {
	flex-grow: 1;
	font-size: 0.9rem;
	letter-spacing: 0.4px;
	line-height: 1.4;
}