.media-text.rich-text .btn {
	color: white;
	text-decoration: none;
	font-family: $font-graphik-bold;
}

.media-text .rich-text .btn {
	
	@include media-breakpoint-up(md) {
		margin-top: 0;
		margin-bottom: 0;
	}
	&:hover {
		color: $color-theme7-link-hover;
	}
}

.multi-formats,
.carousel-promotions {
	.media-image {
		.content-text {
			height: 100%;

			&.text-color15 {
				// noir
				.under-title-text,
				.over-title-text {
					color: $color-noir;
				}

				div.over-title-text + .under-title-text {
					color: $color-noir;
					border-color: $color-blanc;
					background-color: $color-blanc;
					display: inline-block;

					&:hover {
						color: $color-blanc;
						background-color: transparent;
					}
				}
			}

			&.text-color16 {
				// blanc
				.under-title-text,
				.over-title-text {
					color: $color-blanc;
				}

				div.over-title-text + .under-title-text {
					color: $color-noir;
					border-color: $color-blanc;
					background-color: $color-blanc;
					display: inline-block;

					&:hover {
						background-color: transparent;
						color: $color-blanc;
					}
				}
			}

			@include media-breakpoint-down(sm) {
				top: calc(50% + 1.4vw);
			}
		}
	}
}

/**** les exceptions pour Casino ****/

.media-bg-with-content {
	.media-text {
		&.coinsArrondis {
			border-radius: 20px;
		}
		.image-title {
			h2,
			.h2 {
				margin-top: 6rem;
				font-size: 4vw;
				line-height: 4vw;

				.under-title-text {
					font-size: 1.1vw;
					font-family: $font-graphik-medium;
				}
			}
			+ .h3 {
				font-size: 1.85vw;
				line-height: 2.2vw;
				font-family: $font-graphik-semibold;
			}
			h2 + h3,
			h2 + .rich-text {
				margin-top: 1rem;
			}
			h3,
			.h3 {
				font-size: 1.4rem;
				line-height: 1.8rem;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.media-text {
			.image-title {
				h2,
				.h2 {
					font-size: 4.5vw;
					line-height: 4.5vw;
					margin-top: 1.5rem;
				}
				+ .h3 {
					font-size: 1.85vw;
					line-height: 2.2vw;
				}
				h2 + h3,
				h2 + .rich-text {
					margin-top: 1rem;
				}
				h3,
				.h3 {
					font-size: 1.2rem;
					line-height: 1.4rem;
				}
			}
			+ .rich-text {
				margin-top: 1rem;
			}
		}
	}
}
.bloc-image-texte {
	.media-text {
		&.coinsArrondis {
			border-radius: 20px;
		}
		.image-title {
			h2,
			.h2 {
				font-size: 8.5vw;
				line-height: 1.18;

				@include media-breakpoint-up(md) {
					font-size: 2.5vw;
				}

				.under-title-text {
					font-size: 4.1vw;
					line-height: 1.37;
					font-family: 'graphik_semibold', Arial, Helvetica, sans-serif !important;

					@include media-breakpoint-up(md) {
						font-size: 1.1vw;
						font-family: $font-graphik-medium;
					}
	
				}
			}
			+ .h3 {
				font-size: 7vw;
				line-height: 8.5vw;
				font-family: 'graphik_semibold', Arial, Helvetica, sans-serif !important;

				@include media-breakpoint-up(md) {
					font-size: 1.85vw;
					line-height: 2.2vw;
					font-family: $font-graphik-semibold;
				}
			}
		}
	}
}



.deux-textes {
	.media-text {
		.image-title {
			h2 {
				@include media-breakpoint-down(sm) {
					font-size: 6.6vw;
					line-height: 7vw;
				}
			}
			h3 {
				//display: none;
				margin-bottom: 0;
			}
		}
	}
}


.carousel-promotions {
	&[data-responsive-items-small='1'] {
		&[data-responsive-items-medium='4'] {
			&[data-responsive-items-large='4'] {
				&[data-responsive-items-xlarge='4'] {
					@include media-breakpoint-down(sm) {
						.media-image .content-text div.over-title-text + .under-title-text {
							font-family: graphik_regular;
							padding: 3vw 12vw;
							bottom: 10%;
							font-size: 3.6vw;
							left: 5vw;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.deux-textes {
		.media-text {
			h2 {
				font-size: 4.3vw;
				line-height: 5vw;
			}
		}
	}
}
