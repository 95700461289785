

.jackpot-info {
    clear: both;
    content: " ";
    display: table;
    margin: 10px 0 0;
    width: 100%;
}

.french .currency {
    margin-left: 9px;
    margin-right: 0;
}
.jackpot-total {
    float: right;
    margin-right: 5px;
}
.category-page .jackpot-total {
    margin-bottom: 10px;
    margin-top: 4px;
}

.jackport-number-wrapper {
	display: flex;

	.jackpot-number {
		display: block;
	}
}

.jackpot-number {
    border-radius: 1px;
    background: linear-gradient(to bottom, #fff 16%, #fff 10%, #ccc 58%, #fff 25%, #fff 70%);
    border: none;
    color: #000;
    display: inline-block;
    font-family: 'Tahoma Bold', Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 3.5px;
    min-width: 18px;
    padding: 0;
    text-align: center;
    margin-left: 3px;
    z-index: 1;
}

.jackpot-number:lang(fr):nth-last-of-type(3n+3) {
    margin-left: 8px;
}
.jackpot-number:lang(en):nth-last-of-type(3n+3) {
    margin-left: 1px;
}

.jackpot-number.number-separator {
	min-width: 10px;
}

.currency {
    color: #fff;
    font: 1.4em/2.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
    margin-left: 5px;
}
.progressif-petit{
    .currency {
        color: #fff;
        font: 0.8em/1.6em 'Tahoma Bold', Arial, Helvetica, sans-serif;
        margin-left: 5px;
    }
}
.progressif-xpetit {
    margin-top: -7px;

    .currency {
        font: 1em/2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
    }

    .jackpot-number {
        &:lang(fr):nth-last-of-type(3n+3) {
            margin-left: 5px;
        }
        font: 1.2em 'Century Gothic', Arial, Helvetica, sans-serif;
        min-width: 10.5px;
        border-radius: 0px;
        font-weight: bolder;
        margin-left: 1px;
        
        &.number-separator{
    	    min-width: 6px;
        }
        
    }
}

.progressif-moyen {
    .currency {
        font: 1.9em/1.5em 'Tahoma Bold', Arial, Helvetica, sans-serif;
    }
    .jackpot-number {
        font: 1.8em/1.4em 'Tahoma Bold', Arial, Helvetica, sans-serif;
        min-width: 20px;
        
        &.number-separator{
    	    min-width: 10px;
        }
        
    }
    
}

.progressif-grand {
    .currency {
        font: 3.88em/2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
        margin-right: 3px;
    }
    .jackpot-number {
        font: 4.5em/1.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
        min-width: 43px;
        &.number-separator{
    	    min-width: 20px;
        }
    }
} 


.progressif-center {
    .jackpot-number {
        font: 1.3em/1.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
        min-width: 15px;
        &.number-separator{
    	    min-width: 10px;
        }
    }
}


.fr {
    .progressif-grand {
        .currency {
            margin-left: 12px;
        }
    }
    .currency {
        margin-right: 0;
        margin-left: 5px;
    }
}

.jackpot-number.blank {
    background: none #343434;
    opacity: .3;
}

.jackpot-number.gris {
    background: none #1c1c1c;
}

.zoneMontantLot {
    .jackpot-number {
        margin-top: 0;
    }
}

.tooltip-item {
    display: inline-block;
}

.progressif-libelle-actuel {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}