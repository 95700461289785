
// color

.color-tamarillo {
	color: #9C1212 !important;	
}

// Display

.desktop-content {
    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.mobile-content {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}

// Flex

.flex-grow-1_5 {
    flex-grow: 1.5;
}

.justify-content-domainMobile-center {
	.domain-mobile & {
		justify-content: center !important;
	}
}

// Fonts

.fs-8 {
    font-size: 0.8rem !important;
}

.tahoma-regular {
    font-family: "Tahoma" !important;
}

.tahoma-bold {
    font-family: "Tahoma Bold" !important;
}

.text-transform-uppercase {
    text-transform: uppercase !important;
}

// Layout 

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.px-025 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.px-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.px-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.px-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}
.px-9 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
.px-10 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}
.px-15 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.horizontal-align-item-center {
    left: 50% !important;
    transform: translateX(-50%) !important;
} 

.vertical-align-item-center {
    top: 50% !important;
    transform: translateY(-50%) !important;
} 

// Position

.position-fixed {
    position: fixed !important;
}

// Sizing

.w-lg-100-px {
    @include media-breakpoint-up(lg) {
        width: calc(100% - 50px) !important;
    }
}

.mw-80-px {
	max-width: 80px;
}

.mw-50-px {
	max-width: 50px;
}


.mw-128-px {
    max-width: 1280px;
}

.mw-300-px {
    max-width: 300px;
}

.h-lg-100 {
    @include media-breakpoint-up(lg) {
       height: 100% !important;
    }
}

.h-lg-50 {
    @include media-breakpoint-up(lg) {
       height: 50% !important;
    }
}

// Text 

.ta-center {
    text-align: center;
}

.ta-right {
    text-align: right;
}

.ta-left {
    text-align: left;
}

.fill-width{
	width: 100% !important;
}

.display-horizontally{
	@include media-breakpoint-up(md){
     	display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex;
	} 
}

.d-none {
	display: none;
}

// VueJS

[v-cloak] {
	display: none;
}


// DEPRECATED

.hide {
	display: none;
}