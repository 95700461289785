
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.espaceSection .row.gutters-xsmall .sectionTables {
  height: 90px;
  margin-top: 20px;
  font-family: "graphik_regular";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section-verte .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #0f6d56;
}

.section-rouge .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #bc122e;
}

.section-bleu .espaceSection .row.gutters-xsmall .sectionTables,
.section-bleu-ciel .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #194ea1;
}

.section-bleu-marin .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #3135a6;
}

.section-violet .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #a51c5d;
}

.clickable {
  cursor: pointer;
}

.sectionTables div {
  &.splitGauche,
  &.splitDroite {
    color: white;
    fill: white;
    line-height: 24px;
    max-width: 50%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: inherit;
  }

  &.splitGauche {
    text-align: left;
    left: 23px;
    @media (max-width: 425px) {
      left: 15px;
    }

    .nombre {
      font-size: 16px;
    }

    .titreSection {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &.splitDroite {
    font-size: 16px;
    right: 23px;
    @media (max-width: 425px) {
      right: 15px;
    }
  }
}

.section-verte .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #00a389;
    fill: #00a389;
  }
}

.section-rouge .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #f03447;
    fill: #f03447;
  }
}

.section-bleu .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #0a71f0;
    fill: #0a71f0;
  }
}

.section-bleu-ciel .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #01aef0;
    fill: #01aef0;
  }
}

.section-bleu-marin .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #4f71f8;
    fill: #4f71f8;
  }
}

.section-violet .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #d63a93;
    fill: #d63a93;
  }
}

.regroupementTables {
  margin-left: -5px;
  margin-right: -6px;
  margin-top: -2px;
}

.stickyContainer {
  margin-top: 0px;
}
#table-liste {
  max-width: 1290px;
  margin-right: auto;
  margin-left: auto;
}

.theme-bg-ced .section-page .filtre,
.vue-slider.vue-slider-ltr {
  background-color: transparent !important;
  max-width: 1290px;
}
.theme-bg-ced .section-page .filtre {
  margin-right: auto;
  margin-left: auto;
}
.row.gutters-xsmall {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.theme-bg-ced .centrerFiltre {
  margin-bottom: 15px;
  @media (max-width: 1199px) {
    justify-content: center;
    display: flex;
  }
}
#table-liste {
  margin-top: 5px;
}
.sticky.vue-sticky-el {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: transparent;
  left: 0px !important;
  width: 100%;
}
@include media-breakpoint-up(md) {
  .domain-mobile .hideSticky.sticky {
    display: none;
  }
}

.sticky.vue-sticky-el.top-sticky {
  padding-top: 20px;
  padding-bottom: 5px;
  overflow: visible;
  width: 100% !important;
}
.blackjack.sticky.vue-sticky-el.top-sticky {
  background-color: #0e000d;
}
.roulette.sticky.vue-sticky-el.top-sticky {
  background-color: #050941;
}
.baccara.sticky.vue-sticky-el.top-sticky {
  background-color: #3d000e;
}
.jeuxendirect.sticky.vue-sticky-el.top-sticky {
  background-color: #101336;
}
.poker.sticky.vue-sticky-el.top-sticky {
  background-color: #0e000d;
}
.des.sticky.vue-sticky-el.top-sticky {
  background-color: #22011e;
}
.vue-slider.vue-slider-ltr {
  margin-left: 10px;
  padding: 0px 0px !important;
}
.barSlider .vue-slider-rail {
  width: 175px;
  @include media-breakpoint-up(xs) {
    width: 200px;
  }
}
.barreFiltre {
  justify-content: center;
}
.barSlider {
  display: none;
  width: 100%;
  flex-wrap: wrap;
  .tablesOuverteSlider {
    text-align: left;
    font-size: 3vw;
    background-color: transparent;
    align-self: center;
  }
}
.centrerFiltre .filtre .col-12.col-xl-10 {
  padding-right: 0px;
  padding-left: 0px;
}

@media (max-width: 767px) {
  .barSlider {
    display: inline-flex !important;
  }
}
@include media-breakpoint-up(sm) {
  .barSlider .tablesOuverteSlider {
    font-size: 2vw;
  }
}
@include media-breakpoint-up(lg) {
  .barSlider .tablesOuverteSlider {
    font-size: 1.5vw;
  }
}

/* rail style */
.vue-slider-rail {
  background-color: transparent;
  width: calc(100% - 35px);
  border-radius: 15px;
  height: 11px !important;
  border: 2px solid #00a389;
  top: 4px;
}

/* process style */
.vue-slider-process {
  background-color: #00a389;
  border-radius: 15px;
}

/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  border-radius: 50%;
  background-color: #205447;
  border: 2px solid #00a389;
  width: 25px;
  height: 17px;
  border-radius: 15px;
  position: relative;
  top: -2px;
}

.roulette {
  .vue-slider-rail {
    border: 2px solid #0a71f0;
  }

  .vue-slider-process {
    background-color: #0a71f0;
  }

  .vue-slider-dot-handle {
    background-color: #163b75;
    border: 2px solid #0a71f0;
  }
}

.jeuxendirect {
  .vue-slider-rail {
    border: 2px solid #01aef0;
  }

  .vue-slider-process {
    background-color: #01aef0;
  }

  .vue-slider-dot-handle {
    background-color: #3a71a9;
    border: 2px solid #01aef0;
  }
}

.baccara {
  .vue-slider-rail {
    border: 2px solid #bc122e;
  }

  .vue-slider-process {
    background-color: #bc122e;
  }

  .vue-slider-dot-handle {
    background-color: #851929;
    border: 2px solid #bc122e;
  }
}

.poker {
  .vue-slider-rail {
    border: 2px solid #3135a6;
  }

  .vue-slider-process {
    background-color: #3135a6;
  }

  .vue-slider-dot-handle {
    background-color: #0d035b;
    border: 2px solid #3135a6;
  }
}

.des {
  .vue-slider-rail {
    border: 2px solid #a51c5d;
  }

  .vue-slider-process {
    background-color: #a51c5d;
  }

  .vue-slider-dot-handle {
    background-color: #730040;
    border: 2px solid #a51c5d;
  }
}
