$fontFolder: '../fonts/';

@font-face {
    font-family: 'Tahoma';
    src: url('#{$fontFolder}tahoma.eot?#iefix') format('embedded-opentype'),
    url('#{$fontFolder}tahoma.woff') format('woff'),
    url('#{$fontFolder}tahoma.ttf') format('truetype'),
}

@font-face {
    font-family: 'Tahoma Bold';
    src: url('#{$fontFolder}tahomabd.eot?#iefix') format('embedded-opentype'),
    url('#{$fontFolder}tahomabd.woff') format('woff'),
    url('#{$fontFolder}tahomabd.ttf') format('truetype'),
}

@font-face {
    font-family: 'icomoon';
    src: 
    url('#{$fontFolder}icomoon.woff?rlrhyo') format('woff'),
    url('#{$fontFolder}icomoon.ttf?rlrhyo') format('truetype'),
    url('#{$fontFolder}icomoon.svg?rlrhyo#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ej-icon';
    src : 
    url('#{$fontFolder}ej-icon.ttf?pqlh5k') format('truetype'),
    url('#{$fontFolder}ej-icon.woff?pqlh5k') format('woff'),
    url('#{$fontFolder}ej-icon.svg?pqlh5k#ej-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'ej-icomoon';
  src: 
    url('#{$fontFolder}/ej-icomoon.ttf?kcws80') format('truetype'),
    url('#{$fontFolder}/ej-icomoon.woff?kcws80') format('woff'),
    url('#{$fontFolder}/ej-icomoon.svg?kcws80#ej-icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="ejicon-"], [class*=" ejicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ej-icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ejicon-bullhorn:before {
  content: "\e91a";
}
.ejicon-bell:before {
  content: "\e951";
}
.ejicon-spinner11:before {
  content: "\e984";
}
.ejicon-enlarge:before {
  content: "\e989";
}
.ejicon-shrink:before {
  content: "\e98a";
}
.ejicon-cancel-circle:before {
  content: "\ea0d";
}
.ejicon-arrow-right:before {
  content: "\ea34";
}
.ejicon-arrow-left:before {
  content: "\ea38";
}

/* graduate-regular - latin */
@font-face {
  font-family: 'Graduate';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontFolder}graduate-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Graduate'), local('Graduate-Regular'),
       url('#{$fontFolder}graduate-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontFolder}graduate-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontFolder}graduate-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$fontFolder}graduate-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fontFolder}graduate-v7-latin-regular.svg#Graduate') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontFolder}montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('#{$fontFolder}montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontFolder}montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontFolder}montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$fontFolder}montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fontFolder}montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fontFolder}montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('#{$fontFolder}montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontFolder}montserrat-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontFolder}montserrat-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fontFolder}montserrat-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fontFolder}montserrat-v14-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}