.deux-textes {
	@include media-breakpoint-up(md) {
		.table-row1 {
			display: table;
			table-layout: fixed;
		}

		.table-col1 {
			float: none;
			display: table-cell;
			vertical-align: top;
		}
	}

	.flex-wrap {
		display: flex;
		flex-wrap: wrap;
		.flex-item {
			flex-direction: column;
		}
	}

	height: 100%;
	.txt {
		.bgImage {
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.boxBgImage {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.txt-content .media-component.media-text {
			background: transparent !important;
		}
		&:last-child {
			.content {
				padding: 10px;
			}
		}
	}
	.txt-content {
		width: 99%;
	}
	/* wa=workaround */
	.ratio-wa {
		width: 1%;
		padding-top: 56.25%;
	}
}
