
.coupDeCoeurListe {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #131313;
	margin-bottom: 20px;
	.item-banniere {
		padding-right: 5px;
		padding-left: 5px;
		img {
			max-width: 230px;
		}
	}
}

.bloc-jeu-accueil {
	padding: 0;
}
