
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.contenu {
  background-position: center;
  background-repeat: no-repeat;
}
.contenu.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.barre_entete{
  border: 1rem solid rgb(13, 203, 143);
  width: 100%;
  margin: 0;
}

.be_section{
  overflow-x: hidden;
  padding-left: 0px;
}
.be_titre {
  position: absolute;
  top: 10%;
  left: 20%;
  @include ms-respond(font-size, 4);
  //line-height: 1;
}

.be_sous_titre {
  position: absolute;
  top: 45%;
  @include ms-respond(font-size, 1);
  margin-left: 25%;
  color: #808080;
}
.be_coin_rond{
  border-top-left-radius: ms(3); 
  border-top-right-radius: ms(3);
}

@include media-breakpoint-only(md) {

.be_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
  }

  .be_sous_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
  }


}


@media screen and (max-width: 765px) {
  .be_section{
    margin-bottom:2rem;
  }

  .be_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
  }

  .be_sous_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
    top: 60%;
  }

  .barre_entete{
    border-width: 0.5rem ;
    width: 100%;
    margin: 0;
  }
}
