
p.required {
	font: italic 1.1em/1.2em 'Tahoma', Arial, Helvetica, sans-serif;
	margin: 10px 15px 10px 15px;
	text-align: left;
	color: #ccc;
	vertical-align: middle;
	border-top: 1px solid #333;
	padding-top: 10px;
}

p.required span {
	color: #eee;
	margin-right: 7px;
	line-height: 21px;
}

p.newsletter {
	font: 1em/1.2em 'Tahoma', Arial, Helvetica, sans-serif;
	margin: 0;
	color: #ccc;
	vertical-align: middle;
}

p.promotion {
	font: 1em/1.2em 'Tahoma', Arial, Helvetica, sans-serif;
	margin: 0;
	color: #ccc;
	vertical-align: middle;
}

.account-balance {
	padding: 0;
	border-bottom: 0;
}

.balance {
	font: 1.25em/1.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
	padding: 15px 0;
	color: #ddd;
	display: inline-block;
}

.balance-amount {
	font: 1.25em/1.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
	padding: 15px 0;
	color: #fff;
	float: right;
	display: inline-block;
}

label {
    color: #333;
    font: 1.2em/1.1em 'Tahoma Bold',Arial,Helvetica,sans-serif;
    margin: 10px 0 7px;
}