.row {
	&.gutters-xxsmall {
		margin-left: -0.25rem;
		margin-right: -0.25rem;

		> [class*='col'] {
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
	}

	&.gutters-xsmall {
		margin-left: -0.5rem;
		margin-right: -0.5rem;

		> [class*='col'] {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	&.gutters-small {
		margin-left: -1rem;
		margin-right: -1rem;

		> [class*='col'] {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	// &.gutters-medium = .row
	// Default no class needed
	
	&.gutters-large {
		margin-left: -2rem;
		margin-right: -2rem;

		> [class*='col'] {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	&.gutters-xLarge {
		margin-left: -4rem;
		margin-right: -4rem;

		> [class*='col'] {
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}
}

//
// DIALOG
//

.modal-dialog {
	max-width: 100%;
}

.modal-content {
	//flex-direction: row;
}
