.conteneurRangee {
  @include media-breakpoint-between(sm,lg) {
    .col {
      width: 50%;
      .bgEspaceur .espaceur  .espaceur-inner-content {
        padding: 5vw 5vw;
        h2 {
          line-height: calc(2.5vw + 4px);
          font-size: 2.5vw;
          margin-bottom: 1vw;
        }
        .ww-texte p{
          font-size: 14px;
          line-height: 1.3em;
        }
      }
    }
  }
}

