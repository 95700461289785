/* $Header: /cvsroot/lq/mobile/Portal/css/loader.css,v 1.1 2015/09/01 19:23:04 mnaranjo Exp $ */

/* loader */
.loader {
	width: 260px;
	padding: 30px;
	margin: auto;
	display: table;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
}

.icon-clubs:before,
.icon-diamonds:before,
.icon-hearts:before,
.icon-spades:before {
	font-size: 2.5em;
	line-height: 1em;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	margin-top: -3px;
	min-width: 0px;
}

.icon-clubs:before {
	content: "\e607";
}

.icon-diamonds:before {
	content: "\e609";
}

.icon-hearts:before {
	content: "\e60e";
}

.icon-spades:before {
	content: "\e61b";
}

.loader.small .icon-clubs:before,
.loader.small .icon-diamonds:before,
.loader.small .icon-hearts:before,
.loader.small .icon-spades:before {
	margin-right: 12px;
	margin-left: 12px;
}

.loading {
	margin: auto;
}

.loading > div {
	float: left;
	opacity: 0.2;
    transform: scale(1);
	animation-name: loading;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
	animation-direction: linear;
}

.loading > div:nth-child(1) {
	animation-delay: 0.24s;
}

.loading > div:nth-child(2) {
    animation-delay: 0.48s;
}

.loading > div:nth-child(3) {
    animation-delay: 0.72s;
}

.loading > div:nth-child(4) {
    animation-delay: 0.96s;
}

@-o-keyframes loading {
	0% {
		-o-transform: scale(1);
		opacity: 1;
	}

	100% {
		-o-transform: scale(1);
		opacity: 0.2;
	}
}