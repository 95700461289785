
.expand-row {
    margin-left: -15px;
    margin-right: -15px;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.expand-row-header {
	background: linear-gradient(to bottom, #39383E 0, #39383E 49%, #2A292E 51%, #27262B 55%, #1A191E 100%);
    cursor: pointer;
    margin: 0 -15px;
    padding: 20px 15px;
    position: relative;
}

.download-button {
	background: linear-gradient(to bottom, rgba(104,190,232,1) 0%, rgba(104,190,232,1) 3%, rgba(41,150,208,1) 10%, rgba(29,94,154, 1) 100%);    border: 1px solid #55a7cf;
    border-radius: 6px;
    box-shadow: 1px 1px 2px 0 #000;
    cursor: pointer;
    display: inline-block;
    padding: 5px 6px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.section-title {
    color: #fff;
    display: inline-block;
    font: 1.2em/1em 'Tahoma Bold',Arial,Helvetica,sans-serif;
    text-shadow: 1px 1px 2px #333;
}