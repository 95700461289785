
.add-homescreen-preauthorization-wrapper {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: zindex(addToHomescreen);
}

.add-homescreen-preauthorization {
    max-width: 45rem;
    position: relative;

    @include media-breakpoint-up(md) {
        max-width: 68rem;
    }

    img {
        max-height: 40vh;
        max-width: 100%;
    }
}

.add-homescreen-preauthorization__close {
    background: none;
    border: none;
    position: absolute;
    right: 6.6vw;
    top: 4vw;

    @include media-breakpoint-up(sm) {
        right: 2rem;
        top: 1rem;
    }

    @include media-breakpoint-up(md) {
        right: 4rem;
        top: 3rem;
    }

    .ios & {
        right: 1rem;
        top: 1rem;

        @include media-breakpoint-up(md) {
            top: 3rem;
        }
    }
}

.add-homescreen-preauthorization__close-icon {
    color: #9597a0;
    font-family: arial;
    font-size: 4.4vw;
    font-weight: bold;
    line-height: 1;

    @include media-breakpoint-up(sm) {
        font-size: 3rem;
    }
}

.add-homescreen-preauthorization__trigger-a2hs {
    background: none;
    border: none;
}

.add-homescreen-preauthorization__ios {
    display: block;
}