.grid.styleAccueil {
	.ratio-16_9 {
		@include media-breakpoint-up(lg) {
			padding-top: 51.25%;
		}
		
		position: relative;
	}

	.container-absolute {
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	.tiles-container {
		height: 100%;
	}

	.tile-static {
		height: 100%;

		a {
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		}

		.text-block-tile-content {
			flex-grow: 1;
			padding: 5px 10px 10px 10px;
		}

		.container-progressif {
			position: absolute;
			width: 100%;
			left: 0;
		}

		.btnWrap {
			text-align: center;
		}

		.note {
			font-size: 1.1vw;
			line-height: inherit !important;
		}

		h3 {
			font-family: 'graphik_medium', Arial, Helvetica, sans-serif;
			width: 100%;
			font-size: 1.7vw;
			line-height: 1.5;
		}

		.bg-img {
			height: auto;
			position: relative;
			flex-grow: 1.5;

			@include media-breakpoint-down(md) {
				padding-bottom: 60%;
			}
		}

		.btn {
			font-size: 1vw;
			line-height: 1;
			margin-top: 0.9rem;
			padding: 0.8rem 3.3rem;

			@include media-breakpoint-down(md) {
				font-size: 4vw;
				margin-top: 7.5vw;
				padding: 2.2rem 4.2rem;
			}
		}

		@include media-breakpoint-down(md) {
			padding: 0 0 10px 0 !important;
			h3 {
				font-size: 4vw;
				padding-top: 1.5vw;
			}
			.note {
				font-size: 2.5vw;
				padding-top: 2vw;
				display: block;
				width: 100%;
			}
			.container-progressif {
				.jackpot-number,
				.currency {
					font-size: 6vw;
				}
			}
		}
	}

	.tile-content {
		height: 100%;
		position: relative;
	}

	.text-block-tile-wrapper {
		flex-grow: 1;
		position: relative;
		width: 100%;

		@include media-breakpoint-down(md) {
			padding-bottom: 40%;
		}


		* {
			color: white;
		}
		.text-block-tile-content {
			position: absolute;
			width: 100%;
			height: 100%;
			background: #000;
		}
	}

	.subc-full1col1row {
		.tile[data-height='3'][data-width='4'] .tile-content .bg-img {
			// @include media-breakpoint-down(md) {
			// 	padding-bottom: 60%;
			// }
		}
	}

	.subc-1par2 {
		.nord {
			.text-block-tile-wrapper {
				//padding-bottom: 20%;
			}
			.tile[data-height='2'][data-width='4'] .tile-content .bg-img {
				//padding-bottom: 33.6%;
			}
		}

		.sud {
			.text-block-tile-wrapper {
				//padding-bottom: 36%;
			}
			.tile[data-height='1'][data-width='2'] .tile-content .bg-img {
				//padding-bottom: 60%;
			}
		}
	}
}
