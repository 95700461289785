
.game-details {
	position: relative;
}

.game-info {
	.game-info__row {
		align-items: center;
		border-bottom: 1px solid #444;
		display: flex;
		flex-wrap: wrap;
		padding: 1.2rem 1.5rem 0;
		width: 100%;

		> * {
			padding-bottom: 1.2rem;
		}
	}

	.game-info__icon {
		width: 3.3rem;

		&::before {
			font-size: 1.8em;
			color: #fff;
			display: inline-block;
			width: 100%;
			margin-top: -3px;
		}
	}
}

.game-buttons {
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	margin: 10px auto;
	position: relative;
	text-align: center;
	width: 100%;
/* PAS NÉCESSAIRE CAR LES BOUTONS PEUVENT ETRE TOUJOURS SOUS L'IMAGE
	@include media-breakpoint-up(md) {
		bottom: 18px;
		left: 50%;
		margin: 0 0 0 -40%;
		position: absolute;
		width: 80%;
	}
*/
}

.texte-mkt-mobile {
    padding: 15px 15px;
    color: #fff;
    font: 1.2em/1.2em 'Tahoma', Arial, Helvetica, sans-serif;
    margin: 5px 14px;
}