.modal-promo-guest-alert-logo{
	@include media-breakpoint-up(md){
     	width:100px;
		height:100%;
	} 
	@include media-breakpoint-down(sm){
 		width:100px;
		height:100%;
 	}
	
}

.modal-promo-guest-alert-text{
	width:100%;
	@include media-breakpoint-up(md){
     	text-align: left;
     
	} 
	@include media-breakpoint-down(sm){
 		text-align: center;
 	}
	
}

.modal--promo-content{
	border: 2px solid #00417f !important;
    border-radius: 7px !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 46rem;
    
    @include media-breakpoint-down(sm) {
     	margin-left: 10px !important;
		margin-right: 10px !important;
	}
}

.modalBody--modalPromo{
    padding: 2rem 0 0 0;
    
    p {
        font-size: 1.2rem;
    }
}
