/* BINGO (COMPONENTS) */

.page_salle_bingo {
	p {
		margin-bottom: 10px !important;
		font-size: 14px;
		line-height: 19px;
		color: #ffffff;
	}
	a {
		color: #f6c812 !important;
		text-decoration: none;
	}
}

.bingo {
	a {
		color: #f6c812;
		text-decoration: none;
		&:hover {
			color: #f6c812;
		}
	}
	div.apprendre-a-jouer-bouton {
		text-align: center;
	}
	div.progressif-center {
		background-color: #3c3c3c;
		padding-bottom: 5px;
		position: relative;
		min-height: 40px;
	}
	.panel-body {
		background: url(/dam/espacejeux/backgrd_casino.jpg) repeat scroll 0px 0px #181818;
	}
	.labelprogressifBingo {
		font: 1.5em/1.5em Arial, sans-serif;
		text-align: center;
		display: block;
		padding-bottom: 0;
		padding-top: 2px;
		text-transform: uppercase;
	}
	.currency {
		font: 1.1em/2.3em 'Tahoma', Arial, Helvetica, sans-serif;
	}
	.progressif {
		display: block !important;
	}
}

div#blocs-salles {
	div.ej-texte-fond {
		clear: left;
		line-height: 150%;
		padding: 0 0 0 10px;
		margin: 10px 0;
	}
}

div.bloc-salle {
	margin-bottom: 10px;
	cursor: pointer;
}

.salle_desactivation {
	opacity: 0.4 !important;
	cursor: default;
}

.salle_desactivation_texte {
	div {
		font-weight: bold;
		line-height: 15px;
		padding: 40px 0px 0px 20px;
	}
}

img.bloc-salle {
	margin-top: 8px;
}

div.entete.bingo {
	h2 {
		font-size: 26px;
		margin-top: 0;
		margin-bottom: 0;
		sup {
			font-size: 50%;
		}
	}
	h3 {
		margin-top: 10px;
	}
}

div.bloc-salle-main {
	height: 128px;
	background-color: #131313;
	position: relative;
}

div.groslot {
	float: left;
	max-width: 300px;
	height: 128px;
	text-align: center;
	position: relative;
}

.bloc-salle .lotProgerssif-conteneur {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: auto;
	transform: translate(-50%, -50%);
	z-index: 98;
	&.msgNonDisponibilite {
		text-align: center;
		font: 1.5em/1.5em Arial, sans-serif;
	}
}

.labelprogressifBingo {
	color: darkgray;
	font-size: 1.2em;
	padding: 0 0 5px;
	font-weight: bold;
}

div.compteur {
	max-width: 126px;
	padding-top: 10px;
	padding-bottom: 12px;
}

div.joueurs {
	float: left;
}

.icon-ej_user {
	padding: 30px 0px 8px;
}

a.bloc-salle-lien {
	text-decoration: none;
}

div.bloc-salle-img {
	float: left;
	height: 100%;
	width: 184px;
}

div.info {
	text-align: center;
	color: white;
}

div.lien-info {
	float: right;
	padding-right: 3px;
}

div.salle-lien-info {
	text-align: right;
	padding-top: 17px;

	a {
		color: #fff;
	}
}

div.salle-lien-info-inactive {
	clear: left;
	text-align: right;
	padding: 0 10px 10px 0;
}

.salle_bingo_info {
	padding-top: 38px;
	padding-left: 25px !important;
	span {
		padding-left: 6px;
		vertical-align: middle;
		font-size: 15px;
		font-weight: bold;
	}
}

.salle_bingo_info_2 {
	padding-top: 29px;
	span {
		vertical-align: middle;
		font-size: 15px;
		font-weight: bold;
	}
}

.bloc-salle-main {
	a {
		&:hover {
			color: #ffffff;
		}
	}
	.progressif-moyen {
		padding: 5px 0;
		.jackpot-number {
			font: 1.8em/1.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
			min-width: 18px;
		}
		.currency {
			font: 1.5em/1.2em 'Tahoma Bold', Arial, Helvetica, sans-serif;
		}
		&.progressif2 {
			.labelprogressifBingo {
				font-size: 1.1em;
			}
			.currency {
				font-size: 1.4em;
			}
			.jackpot-number {
				font-size: 1.4em;
			}
			.tooltip-inner {
				width: 200px;
			}
			.msg {
				color: #616161;
				font-weight: bolder;
			}
		}
	}
}

.page_salle_bingo {
	.bs-progressif-moyen {
		padding: 5px 0;
		&.progressif2 {
			.labelprogressifBingo {
				font-size: 1.1em;
			}
			.currency {
				font-size: 1em;
			}
			.jackpot-number {
				font-size: 1em;
			}
			.tooltip-inner {
				width: 200px;
			}
			.msg {
				color: #616161;
				font-weight: bolder;
			}
		}
	}
}

.nbJoueurs {
	padding-bottom: 10px;
}

.carte {
	font: 1.25em/0.8em Arial, sans-serif;
	color: darkgray;
	font-weight: bold;
	text-transform: uppercase;
}

.prix_carte {
	padding-left: 6px;
}

.decompte {
	text-align: right;
}

body.en {
	.decompte {
		padding-left: 6px;
	}
}

body.fr {
	.decompte {
		padding-left: 18px;
	}
}

div.bloc-jeu-footer-mini {
	font-size: 11px;
	line-height: 20px;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding-left: 10px;
}

div.bloc-salle-boutons-inactive {
	opacity: 0.5;
}

div.bloc-salle-boutons {
	padding: 27px 10px 6px 0;
	text-align: right;
	position: relative;
	p {
		float: left;
	}
	a {
		text-decoration: none;
	}
}

.element-desactive {
	display: none;
}

div.bloc-jeuMini-boutons {
	background-color: #3c3c3c;
	padding: 8px 5px 8px 0;
	text-align: right;
	overflow: hidden;
	clear: both;
}

.bouton_jouez_pastille {
	a.bloc-jeu-lien {
		color: #000000 !important;
	}
	font-size: 14px;
}

a.aPastilleJeu.disabled {
	div.bloc-jeuMini-boutons {
		span {
			cursor: default;
		}
	}
}

a.aPastilleJeu {
	div.bloc-jeuMini-boutons {
		padding-right: 3px;
	}
}

.titre_page_bingo_salles {
	position: relative;
	div.entete {
		background-color: #5a2678;
		span {
			position: absolute;
			top: -5px;
			left: 15px;
		}
		h2 {
			margin-left: 90px;
		}
	}
}

.titre_page_bingo_jeuxMini {
	position: relative;
	div.entete {
		background-color: #5a2678;
		span {
			position: absolute;
			top: -5px;
			left: 40%;
		}
		h2 {
			margin-left: 70px;
		}
	}
}

.titre_page_bingo_gagnants {
	div.entete {
		background-color: #5a2678;
	}
}

.titre_page_bingo_offres {
	div.entete {
		background-color: #5a2678;
	}
}

.bingo_offres {
	div {
		&:first-child {
			padding-left: 0px;
		}
	}
	font-size: 13px;
	line-height: 16px;
	margin: 0 0 8px 0;
	background: #000000;
}

.bouton_jouez {
	display: inline-block;
	box-sizing: border-box;
	font: 1.7em/1.7em Arial, Helvetica, sans-serif;
	font-weight: bold;
	color: #000000;
	padding: 0px 30px;
	background: linear-gradient(to bottom, rgba(255, 212, 41, 1) 0%, rgba(226, 155, 1, 1) 100%);
	border-radius: 11px 11px 11px 11px;
	border: 3px solid #232323;
	padding: 0px 18px;
}

.bouton_jouez_grand {
	padding: 0px 30px;
}

.labelProgressifBingo {
	font: 1.5em/0.8em Arial, sans-serif;
	color: darkgray;
	text-align: right;
	padding-right: 13px;
	padding-bottom: 1px;
	text-transform: uppercase;
}

.salle-bingo-panel {
	width: 50%;
	float: left;
}

.animateur_pastille_nom {
	font-size: 15px;
}

.animateur_pastille_image {
	background-color: #000000;
	a {
		img {
			margin-left: -7px;
		}
	}
}

.lien_bio {
	background-color: #000000;
	text-align: right;
	width: 100%;
	padding-right: 5px;
	a {
		color: #f6c812;
		&:hover {
			color: #f6c812;
		}
	}
}

.jeuMini_colonne_gauche {
	padding-top: 30px;
	padding-left: 15px;
	li {
		padding: 0px 10px 5px 20px;
		a {
			color: #f6c812;
			text-decoration: none;
		}
	}
}

.liste-jeux {
	.labelprogressif {
		font-size: 12px;
		font-family: Arial;
	}
}

.jeuMini {
	div.apprendre-a-jouer-bouton {
		text-align: center;
		span.disabled {
			opacity: 0.5;
			cursor: default;
		}
	}
	.ejCasinoCarrouselJeu {
		padding-right: 5px;
	}
}

.nonDispoMini {
	font: 1.7em/0.8em Arial, sans-serif;
}

.sectionBas {
	padding-top: 20px;
}

.accordeonJeuxMini {
	width: 100%;
	.panel {
		.ej-panel-noir-heading {
			background: #161616;
			cursor: pointer;
			height: 4.6rem;
			padding: 0;
		}
	}
	.ej-panel-noir-title {
		text-align: center;
		height: 46px;
		.panel-title {
			margin-bottom: -8px;
		}
	}
	span.glyphicon {
		margin-top: 4px;
	}
}

.fleche_jeumini {
	font-size: 35px;
	color: #999;
}

.image_220X220 {
	width: 220px;
	height: 220px;
}

#contenue-pseudo {
	.modal-header {
		background: #422150;
		text-align: center;
		border-radius: 6px 6px 0 0;
	}
	.modal-dialog {
		width: 313px;
		margin-left: 58em;
	}
	.modal-body {
		background: #5a2678;
		border-radius: 0 0 6px 6px;
	}
	.modal-content {
		width: 315px;
	}
	.modal {
		z-index: 9999;
	}
}

input#pseudo {
	margin-left: 15px;
	color: #000000;
}

#contenu_pseudo {
	width: 315px;
}

#pseudo_close {
	font-size: 22px !important;
}

.pseudo_div_bouton {
	text-align: center;
}

.pseudo_bouton {
	background: linear-gradient(
		180deg,
		rgba(226, 192, 90, 1) 0%,
		rgba(211, 151, 30, 1) 10%,
		rgba(209, 146, 37, 1) 50%,
		rgba(209, 146, 37, 1) 90%,
		rgba(200, 124, 44, 1) 100%
	);
	border: 1px solid #db9d59;
	border-radius: 6px;
	cursor: pointer;
	letter-spacing: 0.01em;
	text-align: center;
	box-shadow: 1px 1px 2px 0px #333;
	font: 1em/1.8em Arial, sans-serif;
	width: auto;
}

#zoneNicknameError {
	font-weight: bold;
	color: #8e2828;
	padding-bottom: 10px;
}

.bingoMenuGauche {
	a {
		color: #ffffff;
		&:focus {
			text-decoration: none;
		}
		&:active {
			text-decoration: none;
		}
		&:visited {
			text-decoration: none;
		}
	}
	max-height: 500px;
	margin-bottom: 20px;
	.icon-arrowdown {
		&:before {
			float: none;
			margin-left: 10px;
			line-height: 24px;
			position: absolute;
			margin-top: 2px;
		}
	}
}

.bingo_menu_item {
	padding: 10px 10px 10px 10px;
	font: 1.35em/0.8em Arial, sans-serif;
	font-weight: bold;
	line-height: 24px;
	.bingo_menu_sousItem {
		a {
			text-decoration: none;
			padding-bottom: 8px;
			padding-top: 6px;
		}
	}
}

.bingo_menu_sousItem {
	font-size: 12px;
	font: 0.85em/0.7em Arial, sans-serif;
	font-weight: bold;
	margin-top: 10px;
	padding-left: 23px;
}

.ej_bingo_underline {
	background: #5a2678;
	height: 4px;
	margin-top: -4px;
}

#collapse1 {
	.bingo_menu_sousItem {
		margin-bottom: 7px;
	}
}

.bouton_achetez {
	background: linear-gradient(
		180deg,
		rgba(226, 192, 90, 1) 0%,
		rgba(211, 151, 30, 1) 10%,
		rgba(209, 146, 37, 1) 50%,
		rgba(209, 146, 37, 1) 90%,
		rgba(200, 124, 44, 1) 100%
	);
	border: 1px solid #db9d59;
	border-radius: 6px;
	cursor: pointer;
	letter-spacing: 0.03em;
	text-align: center;
	box-shadow: 1px 1px 2px 0px #333;
	font: 1.5em/1em 'Arial', Helvetica, sans-serif;
	color: #000000;
	padding: 6px 15px;
	float: right;
	a {
		color: #000000;
	}
}

#clavardage {
	ul.tab-menu {
		li {
			a {
				color: #f6c812;
			}
			a.active {
				color: #fff !important;
				height: 15px;
				border-bottom: 0;
			}
		}
	}
}

.texte-animateurs {
	font-family: Arial, Verdana, Arial, Helvetica, sans-serif;
	line-height: 115%;
	font-size: 15px;
	padding: 10px;
	h2 {
		color: #ffffff;
		font-family: Arial, Verdana, Arial, Helvetica, sans-serif;
		line-height: 115%;
		font-size: 15px;
		font-weight: bold;
	}
}

.titre-animateur {
	font-weight: bold;
	padding: 0;
}

p.titre-animateur {
	margin-bottom: 5px !important;
}

.section-animateur {
	padding-bottom: 10px;
	.blockImageTitreDescRight {
		padding-top: 5px;
	}
}

.img-animateur {
	background-color: #181818;
}

table.tableAvecCoinRond {
	float: left;
	margin: 0 0 20px 0;
	color: #fff;
	font-size: bold;
	width: 100%;
	tr {
		th {
			padding: 7px 0 10px 0;
			text-align: center;
			vertical-align: middle;
			div.c_0d0d0d_t_l {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
			div.c_0d0d0d_t_r {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
			div.c_3d3d3d_t_l {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
			div.c_3d3d3d_t_r {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
		}
		td {
			padding: 7px 0 10px 0;
			text-align: center;
			vertical-align: middle;
			div.c_0d0d0d_t_l {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
			div.c_0d0d0d_t_r {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
			div.c_3d3d3d_t_l {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
			div.c_3d3d3d_t_r {
				width: 5px;
				height: 5px;
				padding: 0;
				margin: 0;
			}
		}
		th.aleft {
			text-align: left;
			padding: 7px 10px 10px 10px;
		}
		td.aleft {
			text-align: left;
			padding: 7px 10px 10px 10px;
		}
		th.aright {
			text-align: right;
			padding: 7px 10px 10px 10px;
		}
		td.aright {
			text-align: right;
			padding: 7px 10px 10px 10px;
		}
		th.c_0d0d0d.texteJaune {
			color: #fcc400;
			font-weight: bold;
		}
		td.c_0d0d0d.texteJaune {
			color: #fcc400;
			font-weight: bold;
		}
		th.c_0d0d0d {
			background-color: #0d0d0d;
		}
		td.c_0d0d0d {
			background-color: #0d0d0d;
		}
		th.c_0d0d0d_b_l {
			width: 5px;
			height: 5px;
			background-color: #0d0d0d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		td.c_0d0d0d_b_l {
			width: 5px;
			height: 5px;
			background-color: #0d0d0d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		th.c_0d0d0d_b_r {
			width: 5px;
			height: 5px;
			background-color: #0d0d0d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		td.c_0d0d0d_b_r {
			width: 5px;
			height: 5px;
			background-color: #0d0d0d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		th.c_3d3d3d {
			background-color: #3d3d3d;
		}
		td.c_3d3d3d {
			background-color: #3d3d3d;
		}
		th.c_3d3d3d_b_l {
			width: 5px;
			height: 5px;
			background-color: #3d3d3d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		td.c_3d3d3d_b_l {
			width: 5px;
			height: 5px;
			background-color: #3d3d3d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		th.c_3d3d3d_b_r {
			width: 5px;
			height: 5px;
			background-color: #3d3d3d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
		td.c_3d3d3d_b_r {
			width: 5px;
			height: 5px;
			background-color: #3d3d3d;
			vertical-align: top;
			padding: 0;
			margin: 0;
		}
	}
	tr.separateur {
		th {
			padding: 0;
			height: 5px;
		}
		td {
			padding: 0;
			height: 5px;
		}
	}
	tr.realTableHeader {
		td {
			padding: 0 0 7px 0;
		}
	}
}

table.tableAvecCoinRond.SicBo {
	tr {
		th {
			vertical-align: top;
		}
		td {
			vertical-align: top;
		}
	}
}

.ej-panel-noir-body {
	li {
		list-style-type: disc;
	}
}

.liste-jeux--mini {
	margin-bottom: 0 !important;

	.bloc-jeu {
		margin-bottom: 0;
	}
}

.hvr-underline-from-left {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	overflow: hidden;
}
