
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

.lobby-table-block-cnt {
svg use {
	fill: unset;
}
}

.tableComplete {
	font-size: calc(11px + 0.5vw);
	font-family:$font-graphik-regular;
	font-weight:600;
	padding-bottom: 0.5vw;

	@media (min-width: 640px) {
		font-size: 2vw;
		padding-bottom: 0;
	}
	@include media-breakpoint-up(md) {
		font-size: 1.8vw;
		padding-bottom: 0;
	}
	@media (min-width: 991px) {
		font-size: 1.2vw;
		padding-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		font-size: 15px;
		padding-bottom: 0;
	}

	&.tableCompleteAccueil {
		font-size: calc(11px + 0.3vw);
		padding-bottom: 0;

		@include media-breakpoint-up(md) {
			font-size: 1.5vw;
			padding-bottom: 1.3vw;
		}
		@include media-breakpoint-up(xl) {
			font-size: 18px;
			padding-bottom: 15px;
		}
	}

	.TableFullText--1IsTW {
		color: #fff;
	}
}

.BetBehindText--I86eo.betBehindText {
	color:  #00A389;
	font-family:$font-graphik-regular;
	font-size:13px;
	font-weight:medium;
	line-height:17px;
}
.col-2	.BetBehindText--I86eo.betBehindText {
	@include media-breakpoint-up(xl) {
		font-size:11px;
	}
}
.desktop .col-3 .BlackJackSeat--i2NtA {
	height: 2em !important;
}

