
.owl-carousel {
    &.carousel-standard {
        display: block;
        min-height: 84vw;
 
        @include media-breakpoint-up(md) { 
            min-height: 27.6rem;
        } 
 
        @include media-breakpoint-up(lg) { 
            min-height: 45rem; 
        } 
 
        @include media-breakpoint-up(xxl) { 
            min-height: 50rem; 
        } 
 
        @include media-breakpoint-up(xxxl) { 
            min-height: 57.5rem; 
        } 

        .owl-stage {
            display: flex;
        }

        .owl-item {
            flex-shrink: 0;
            float: none;

            &:after {
                @include media-breakpoint-down(sm) {
                    content: '';
                    position: absolute;
                    right: -6px;
                    left: 3px;
                    top: -14%;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    transition: all 0.55s;
                }
            }

            &:not(.active):after {
                @include media-breakpoint-down(sm) {
                    background-color: transparent;
                }
            }
        }

        .fn-carousel__item {
            height: 100%;
            overflow: hidden;
            position: relative;

            .content-video {
                height: 100%;
            }
        }

        .fn-carousel__item.ban-loterie-stage {
            align-items: center;
            display: flex;
            max-height: 27.6rem;

            @include media-breakpoint-up(md) {
              max-height: 27.6rem;
            }
      
            @include media-breakpoint-up(lg) {
              max-height: 45rem;
            }
      
            @include media-breakpoint-up(xxl) {
              max-height: 50rem;
            }
      
            @include media-breakpoint-up(xxxl) {
              max-height: 57.5rem;
            }


            .avis-gros-lot {
                width: 100%;
                
            }
          }

        .fn-carousel__iframe {
            display: block;
            height: 100%;
            left: 50%;
            overflow: hidden;
            position: absolute;
            padding-top: 84vw; 
            transform: translate(-50%,-50%);
            top: 50%;
            width: 100%;

            @include media-breakpoint-down(sm) {
                padding-top: 56.25%; // 16:9
			}
			
			.carousel-text-block {
				position: absolute;
				top: 0;
				width: 100%;
			}
        }

        .btn {
            margin-bottom: 0;
        }

        .tile-carousel-static.tile-carousel-text-bg {

			.container-progressif {
				@include media-breakpoint-up(md) {
					left: 33%;
				}

				.jackpot-number {
					@include media-breakpoint-only(md) {
						font-size: 5vw;
					}
				}

				.currency {
					@include media-breakpoint-only(md) {
						font-size: 5.5vw;
					}
				}
			}

            .media-text-with-bg-image {
                @include media-breakpoint-down(sm) {
                    background-size: 150vw;
                    background-position: 0 -19vw;
                }

                &.textefirst {
                    @include media-breakpoint-down(sm) {
                        background-position-y: -19vw;
                        background-position-x: -50vw;
                    }
                }
            }
        }

        .carousel-text-block:after {
            @include media-breakpoint-down(sm) {
                content: '';
                position: absolute;
                right: 0;
                left: 0;
                top: 26vw;
                height: 100%;
                width: 100%;
                background: linear-gradient(to bottom, rgba(222,222,222,0) 1%, rgba(222,222,222,0.29) 6%, rgba(222,222,222,0.44) 10%, rgba(222,222,222,0.73) 14%, rgba(222,222,222,0.8) 15%, rgba(222,222,222,0.84) 17%, rgba(222,222,222,1) 26%);
            }   
        }
    }

    @include media-breakpoint-up(md) {


        @at-root .carousel-gagnants#{&} {
            .owl-item:after {
                width: calc(100% - 9px);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .owl-item {

            &:after,
            &:not(.active):after {
                background-color: transparent;
            }
        }

        &.carousel.carousel.carousel-full-width {
            .owl-nav.side-arrows {
                display: block;
            }
        }

        .mask {
            position: absolute;
            height: 100%;
            width: 18rem !important;
            top: 0;

            &.left-arrow {
                left: -7.7%;
                z-index: 2;
            }

            &.right-arrow {
                right: -7.7%;
                z-index: 2;
            }
        }

        &.carousel-gagnants .mask {
            top: 0;

            &.left-arrow {
                background-image: linear-gradient(to left, rgba(9, 88, 146, 0) 0%, rgba(9, 88, 146, 0.5) 22%, rgba(9, 88, 146, 1) 44%);
            }

            &.right-arrow {
                background-image: linear-gradient(to right, rgba(9, 88, 146, 0) 0%, rgba(9, 88, 146, 0.5) 22%, rgba(9, 88, 146, 1) 44%);
            }
        }

        &.carousel-promotions .mask {
            &.left-arrow {
                background-image: linear-gradient(to left, rgba(50, 133, 182, 0) 0%, rgba(50, 133, 182, 0.5) 22%, rgba(50, 133, 182, 1) 44%);
            }

            &.right-arrow {
                background-image: linear-gradient(to right, rgba(11, 94, 160, 0) 0%, rgba(11, 94, 160, 0.5) 22%, rgba(11, 94, 160, 1) 44%);
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .owl-item {
            .tile-carousel-static.tile-carousel-text-bg {
                .media-text-with-bg-image {
                    background-position: center center;
                }
            }
        }
    }   
}

.for-carousel-standard {
    overflow-x: hidden;
    
    .owl-dots {
        margin-top: -34px;
        position: relative;

        @include media-breakpoint-up(lg) {
            display: none;
        }
	}
	
	.container-progressif {
        left: 50%;
        transform: translateX(-50%);
		position: absolute;
		z-index: 1;
    
        @include media-breakpoint-up(lg) {
            left: 33%;
		}
		
		.progressif {
			align-items: stretch;
		}
    
        .jackpot-number-wrapper {
            display: inline-flex;
            position: relative;
    
            &::before {
                box-shadow: 0 0 20px 0px rgba(0,0,0,.9);
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                z-index: 0;
                top: 0;
            }
        }
    
        .jackpot-number {
			font-size: 8vw;
            margin-top: 0;
            padding-left: 4px;
            padding-right: 4px;
    
            @include media-breakpoint-up(lg) {
				font-size: 5.5rem;
                padding-left: 5px;
                padding-right: 5px;
            }
    
            &:first-child {
                margin-left: 0;
            }
        }
    
        .currency,
        .jackpot-number {
            line-height: 1.2;
        }
    
		.currency {
			align-items: center;
			display: flex;
			font-size: 7.5vw;
			position: relative;
			text-shadow: 0px 0px 10px rgba(0,0,0,.6);
			top: 0.2rem;
			
			@include media-breakpoint-up(lg) {
				font-size: 4.8rem;
				top: 0.4rem;
            }
        }
    } 
}

.for-carousel-standard {
    .media-text {
        @include media-breakpoint-up(md) {
            padding-left: 2vw;
            padding-right: 2vw;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 12%;
            padding-right: 12%;
        }

        .image-title {
            img {
                width: 13%;
            }

            h2 {
                margin-top: 5px;

                &:after {
                    bottom: -4vw;
                    width: 5vw;
                
                    @include media-breakpoint-up(md) {
                        bottom: -1.2857142857rem;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        bottom: -0.7142857143rem;
                    }
                }

            }
        }

        h3,
        .h3,
        .subtitle {
            text-transform: uppercase;
            font-size: 1.2rem;
        }
    }
}

.for-carousel-standard {
    margin-top: 0;

    @include media-breakpoint-up(md) {
        height: 100%;
        margin-bottom: 8px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    // les valeurs suivantes (padding-top pour .tile-sizer:afte) doivent correspondre aux heights plus bas sous 
    // .media-text-with-bg-image
    .carousel.carousel-standard {

        .tile-sizer:after {
            padding-top: 84vw;

            @include media-breakpoint-up(md) {
                padding-top: 285px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 450px;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: 500px;
            }

            @include media-breakpoint-up(xxxl) {
                padding-top: 575px;
            }
        }

        .media-text-with-bg-image {
            height: 100%;

            @include media-breakpoint-up(md) {
                background-position: 50% 50%;
            }

            .desktop-content {
                @include media-breakpoint-up(md) {
                    margin-left: 4vw;
                    margin-right: 4vw;
                    max-width: none;
                }

                .row {
                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }
            }

            .mobile-content {
                @include media-breakpoint-down(sm) {
                    bottom: 1.1vw;
                    color: $color-n1;
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                    vertical-align: bottom;
                    width: 100%;
                    z-index: 1;
                }

                h2,
                h3,
                .under-title-text,
                .subtitle,
                .h3 {
                    @include media-breakpoint-down(sm) {
                        color: $color-n1;
                        margin-top: 2%;
                        white-space: normal;
                    }
                }

                .media-text {
                    @include media-breakpoint-down(sm) {
                        padding: 0 1.5rem;
                    }
                }
            }
        }

        .owl-dots {
            @include media-breakpoint-down(sm) {
                margin-top: -40.5vw;
            }
        }

        .media-text {
            .image-title {
                img {
                    height: 55px;
                    width: auto;

                   @include media-breakpoint-only(md) {
                        height: 44px;
                    }
                }

                h2 {
                    margin-top: 0;
                    font-size: 5.0666666667vw;
                    line-height: 5.8666666667vw;

                    @include media-breakpoint-up(md) {
                        font-size: 2.4rem;
                        line-height: 1.1;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 3.4rem;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 4.2rem;
                    }


                    span.under-title-text {
                        font-family: latoblack, Arial, Helvetica, sans-serif;

                        @include media-breakpoint-up(md) {
                            font-size: 1.9rem;
                            line-height: 1.2;
                        }
    
                        @include media-breakpoint-up(lg) {
                            font-size: 1.8rem;
                        }
    
                        @include media-breakpoint-up(xl) {
                            font-size: 2.4rem;
                        }
                    }

                                    
                    &:after {
                        bottom: -4vw;
                        width: 5vw;

                        @include media-breakpoint-up(md) {
                            bottom: -0.8571428571rem;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            bottom: -0.7142857143rem;
                        }
                    }
                }
            }

            h3,
            .h3,
            .subtitle {
                text-transform: uppercase;
                font-size: 1.2rem;

                @include media-breakpoint-down(sm) {
  
                    text-transform: none;
                    font-size: 3.7vw;
                    line-height: 5vw;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 1.1vw;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .under-title-text {
                line-height: 1.2em;
            }

            .btn {
                background-color: transparent;
                color: $color-carousel-dots-active;
                border-color: $color-carousel-dots-active;
                text-decoration: none;

                &:hover {
                    background-color: $color-carousel-dots-active;
                    color: $color-w1;
                }

                &.opaque {
                    background-color: $color-carousel-dots-active;
                    color: $color-w1;

                    &:hover {
                        background-color: transparent;
                        color: $color-carousel-dots-active !important;
                        border-color: $color-carousel-dots-active !important;
                    }
                }
            }

            .tile .tile-inner {
                overflow-y: visible;
            }
        }
    }
}

// Fixing carousel with big titles.
.for-carousel-standard{
    .media-text {
        padding-right: 0;
    }
    .carousel{
        &.carousel-standard{
            .media-text{
                .image-title{
                    h2{
                        @media only screen and (min-width: 768px) and (max-width: 1432px) {
                            font-size: 3.6rem;
                        }    
                        @media only screen and (min-width: 768px) and (max-width: 1226px) {
                            font-size: 3rem;
                        }                
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1020px) {
        .desktop-content .columns {
            padding: 0;
        }
        .media-text {
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            top: -10px;
            position: relative;
        }
        .carousel{
            &.carousel-standard{
                .media-text{
                    .image-title{
                        h2 {
                            font-size: 2.4rem;
                            margin-bottom: 15px;
                        }
                    }
                    .subtitle{
                        &.h3 {
                            margin-top: 15px;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}




