
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

  @import url('https://fonts.googleapis.com/css?family=Graduate&display=swap');

  #entete {    

    ::v-deep .entete_btn{
      margin-top: 0 !important;
    }
  }

  #nav {
    @include ms-respond(font-size, -1);    
    text-transform: uppercase;
    padding: 0;


    a, ::v-deep a.nav-link {
      font-weight: bold;
      color: #2c3e50;
      text-decoration: none;

      &.router-link-exact-active {
        color: #06845c;
      }
      :hover{
        color: #06845c;
      }
    }

  }

  .bingo_cercle{
    height: 1.5rem;
    width: 1.5rem;
    background-color: #004687;
  }

  .nav_bingo_icon{
    @include ms-respond(height, 6);
  }

  .nav-link{
    text-align: center;
  }

  .navbar_content{
    background-color: white;
  }

  .navbar_content_titles{
    justify-content: flex-start;
  }

  .toggler_icon{
    border: none;
  }

  ::v-deep .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    width: 2.5em;
    height: 2.5em; 
    border: none;
  }

  ::v-deep .dropdown-menu{
    background-color: white;

    li{
      a {
        color: #2c3e50;;
        text-decoration: none;

        &.router-link-exact-active {
          color: #06845c;
        }
      }
    }
  }

  ::v-deep .dropdown-item{
    @include ms-respond(font-size, -1);
    padding-left: 0.5rem;
    text-transform: uppercase;
    white-space: normal;
  }

  ::v-deep .dropdown-item:active{
    background-color: unset;
  }

  ::v-deep .dropdown-item a:hover, .nav-item :hover{
    color: #06845c !important;
  }

@include media-breakpoint-up(lg){

  .navbar_content_titles{
    justify-content: space-evenly;
  }


  .route_btn{
    z-index: 98;

  }

  ::v-deep ul.dropdown-menu{
    position: absolute;
    top: 0;
    width: 110%;
    margin-left: -5%;
    margin-top: -5%;
    padding-top: 6rem;
    z-index: -1;
    border-width: 2px;
  }
}
 
@include media-breakpoint-down(md){
  $border-color: #d9d9d9;

  .route_btn{
      border-bottom: 1px solid $border-color;
      padding: 1rem 0;
      margin: 1rem 1rem;
      @include ms-respond(font-size, 1);
  }

  .route_btn:last-child{
      border-bottom: none;
  }

  .navbar_content{
    border: 3px solid $border-color;
    padding: 0 0.5rem;
  }

  .close_menu_icon{
    height: 3rem;
    filter: brightness(0);
  }

  .dropdown-menu li{
    border-bottom: 1px solid $border-color;
  }

  .dropdown-menu li:last-child{
    border-bottom: none;
  }

  ::v-deep ul.dropdown-menu{
    border: none;
    padding: 0 1rem;
    border-top: 2px solid #d9d9d9;
  }

  ::v-deep .dropdown-item{    
    padding: 1rem 0;
    @include ms-respond(font-size, 1);
  }
} 

  .navbar-dark {
    .navbar-nav {
      .nav-link {
        font-weight: bold;
        color: rgba(230, 230, 230, 1);
        margin-left: 10px;
        padding: 25px 0px 25px 0px !important;
        &.router-link-exact-active {
          color: rgba(230, 230, 230, 1);
          border-bottom: 3px solid #FF0000;
        }
      }
      .nav-link:hover {
        color: rgba(230, 230, 230, 1);
      }
    }

  }

  .install-app {
    /*animation: bouton 0.5s alternate infinite;*/
    width: 100%;
  }
  @keyframes bouton {
		from {
			transform: scale(1);
		}
		to {
			transform: scale(2);
		}
	}

