.bloc-image-texte {
	.columns.img {
		.tile-inner {
			.content.content-text {
				height: 100%;
				.contentWrap {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	&.imagefirst {
		.img {
			@include media-breakpoint-up(md) {
				padding-right: 10px;
			}
		}
		.txt {
			@include media-breakpoint-up(md) {
				padding-left: 10px;
			}
			.bgImage {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-repeat: no-repeat;
			}
			.boxShadow {
				@include media-breakpoint-up(md) {
					box-shadow: -15px 2px 30px 2px rgba(204, 204, 204, 0.5);
					height: 100%;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
			.boxBgImage {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}

	&.textefirst {
		.txt {
			.bgImage {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-repeat: no-repeat;
			}
			.boxShadow {
				position: absolute;
				width: 100%;
				height: 100%;
				margin-left: -10px;
				@include media-breakpoint-up(md) {
					box-shadow: 15px 2px 30px 2px rgba(204, 204, 204, 0.5);
				}
			}
			.boxBgImage {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}

	div:not(.deux-textes) {
		.media-text,
		.media-image {
			position: relative;

			@include media-breakpoint-up(md) {
				align-items: center;
				display: flex;
				height: 100%;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.tile-inner {

			//@include media-breakpoint-up(md) {
				align-items: center;
				display: flex;
			//}
		}
	}

	.media-image-with-text-beside > .row {
		display: flex;
		flex-wrap: wrap;

		& > [class*='col-'] {
			flex-direction: column;
			height: inherit;
		}
	}

	.media-component.media-text {
		.content {
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.image-title {
			h3:after {
				display: none;
			}
			&.nounderline {
				h2 {
					font-family: $font-graphik-medium;
				}
			}
		}
		.btn {
			text-decoration: none;
			font-family: $font-graphik-bold;;
		}
	}

	.media-image {
		.content-text {
			left: 30px;
		}
	}
}

.content-inner {
	// padding-left: 12%;
	// padding-right: 12%;
	width: 100%;

	@include media-breakpoint-down(sm) {
		padding: 0;
	}
}

@include media-breakpoint-up(md) {
	.carousel .content-inner {
		// padding-left: 10%;
		// padding-right: 10%;
	}
}

.media-text {
	padding-right: 0;
	padding-left: 0;
	h3 {
		font-family: $font-graphik-medium;
	}
}
@include media-breakpoint-down(sm) {
	.bloc-image-texte {
		.media-component.media-text {
			.btn {
				padding: 3% 14%;
				margin-top: 10px;
			}
		}
	}
	.deux-textes {
		.content-inner {
			padding: 0;
		}
	}
}
