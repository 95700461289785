
								@import "./dev/assets/sass/variables";
								@import "./dev/assets/sass/mixins";
								@import "./dev/assets/sass/miseojeu/_miseojeu-variables.scss";
								@import "./dev/assets/sass/modularscale";
								@import "./dev/assets/sass/global/bootstrap-variables";
								@import "./node_modules/bootstrap/scss/functions";
								@import "./node_modules/bootstrap/scss/variables";
								@import "./node_modules/bootstrap/scss/mixins";
								@import "~bootstrap-vue/src/index.scss";
								

// Loading
.js-televersement-initial-loading {
	align-items: center;
	background: #fff;
	display: flex;
	height: 100%;
	left: 0;
	justify-content: center;
	opacity: 1;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 20000;
}

.loading-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.loading-ring div {
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.loading-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.loading-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.loading-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes loading-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading-ring--blue {
	div {
		border-color: #3b8bc1 transparent transparent transparent;
	}
}
