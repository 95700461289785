.owl-carousel .animated {
	animation-duration: 3000ms;
	animation-fill-mode: both;
}
  
.owl-carousel .owl-animated-in *{
	z-index: 0;
}
  
.owl-carousel .owl-animated-out *{
	z-index: 1;
}

form[name=changerDate] {
    display: none;
}

.fn-carousel__content {
    display: none;
}

.carousel{
    height: 100%;

    .owl-stage-outer {
        height: 100%;

        .owl-stage {
            height: 100%;
        }
    }

    .bg-img.lazyloaded {
        height: 50%;
    }

    svg {
        width: 100%;
        height: 100%;
        z-index: 15;

        use {
            fill: $color-w1;
        }
    }

    &.owl-theme {
        .owl-controls {
            z-index: 6;
            position: absolute;

            right: 0;
            left: 0;

            .owl-dots-wrapper {
                display: inline-block;
                position: relative;
            }
        }

        .owl-dots .owl-dot.active span,
        .owl-dots .owl-dot:hover span {
            background-color: $color-carousel-dots-active;
            border: 2px solid $color-carousel-dots-active;
        }

    

        &.carrouselmultiple .owl-controls {
            top: 0;
        }
    }

    .owl-nav {
        display: none;
        position: relative;
        > div {
            position: absolute;

            &.owl-prev {
                background: transparent;
                opacity: 1;
                left: -5.3333333333vw;

                &:hover {
                    background: transparent;
                }

    
                @include media-breakpoint-up(md) {
                    left: -1.7142857143rem;
                }
                
                @include media-breakpoint-up(lg) {
                    left: -1.4285714286rem;
                }
            }

            &.owl-next {
                background: transparent;
                opacity: 1;
                right: -5.3333333333vw;

                &:hover {
                    background: transparent;
                }


                @include media-breakpoint-up(md) {
        
                    right: -1.7142857143rem;
                }
                
                @include media-breakpoint-up(lg) {
                
                    right: -1.4285714286rem;
                }
            }
        }


        &.side-arrows {
            display: block;
        }
    }

    .carousel-full-width {
        &.owl-theme {
            .owl-dots .owl-dot span {
                display: none;
            }
        }

        .tile .tile-inner {
            margin: 0;
        }
    }
}

.swipeAdecouvrir{
    .carousel{
        .owl-stage-outer {
            .owl-stage {
                @include media-breakpoint-up(xs){
                    padding-left: 5% !important;
                    padding-right: 0 !important;
                    .owl-item{
                        height: 110% !important;
                        .content-img{
                            width: 150px !important;
                            height: 100% !important;
                            .bg-img{
                                margin: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }      
                @include media-breakpoint-up(lg) {
                    width: 50%;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    .owl-item{
                        padding-right: 2rem;
                        padding-left: 2rem;
                        width: 320px !important;
                        .content-img{
                            width: 100% !important;
                            height: 100% !important;
                        }
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                    }
                }

            }
        }
    }
}

.swipeAdecouvrir .carousel .owl-stage-outer .content-text{
	width:50%;
}

.swipeAdecouvrir .carousel .owl-stage-outer .content-img{
	width: 50% !important;
    height: 100% !important;
}

.swipeAdecouvrir .carousel .owl-stage-outer{
	padding:0;
}

.swipeAdecouvrir .carousel-promotions{
	min-height: 150px;
}

.carousel .owl-nav button,
.carousel.owl-theme .owl-controls .owl-nav [class*="owl-"] {
  border: 0;
  background: none;
  display: none;
  margin: 0 !important;
  padding: 0;
  width: 3.3333333333vw;
  height: 3.3333333333vw;
  padding-top: 0.4vw;

  @include media-breakpoint-up(md) {
  
    width: 1.0714285714rem;
    }

    @include media-breakpoint-up(lg) {
    
        width: 0.8571428571rem;
    }

    @include media-breakpoint-up(md) {
    
        height: 1.0714285714rem;
    }

    @include media-breakpoint-up(lg) {
    
        height: 0.8571428571rem;
    }

    @include media-breakpoint-up(md) {
    
        padding-top: 0.2142857143rem;
    }

    @include media-breakpoint-up(lg) {
    
        padding-top: 0.0714285714rem;
    }
}
   
.carousel.owl-theme .owl-dots .owl-dot span {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    margin: 0.6666666667vw;
    background-color: #9a9a9a;
    border: 2px solid #9a9a9a;

    @include media-breakpoint-up(md) {
		height: 1.2rem;
		margin: 0.3rem;
        width: 1.2rem;
    }
}


.carousel {
    .tile {
        .btn {
            margin-top: 3vw;

            @include media-breakpoint-up(md) {
                margin-top: 0.7vw;
            }

            @include media-breakpoint-up(xxl) {
                margin-top: 1.4rem;
            }
        }
    }
}


/* line 373, dev/assets/sass/components/_carousel.scss */
.carousel .carousel-full-width .tile .tile-inner {
    margin: 0;
  }
  
  /* line 381, dev/assets/sass/components/_carousel.scss */
  .carousel .tile .bg-img.lazyloaded {
    height: 50%;
  }
  
  /* line 385, dev/assets/sass/components/_carousel.scss */
  .carousel .tile .tile-inner {
    margin: 0vw;
  }
  
  @include media-breakpoint-up(md) {
    /* line 385, dev/assets/sass/components/_carousel.scss */
    .carousel .tile .tile-inner {
      margin: 0;
    }
  }
  
  @include media-breakpoint-up(lg) {
    /* line 385, dev/assets/sass/components/_carousel.scss */
    .carousel .tile .tile-inner {
      margin: 0;
    }
  }
  
  @include media-breakpoint-up(xxl) {
    /* line 385, dev/assets/sass/components/_carousel.scss */
    .carousel .tile .tile-inner {
      margin: 0;
    }
  }
  
	@include media-breakpoint-up(md) {
		.carousel {

			.tile {
				.bg-img {
					background-position: center top;
				}
			}

			.text-block {
				padding: 3rem 20% 0;
			}
		}
	}

@include media-breakpoint-up(lg) {
    .carousel {

        .tile {
            .bg-img {
                background-position: center center;

                &.lazyloaded {
                    height: 100%;
                }
            }
		}
		
        .owl-nav button,
        &.owl-theme .owl-controls .owl-nav [class*="owl-"] {
            display: initial;
        }

        &.owl-loaded .text-block {
            opacity: 0;
        }

        .owl-item {
            &.active .text-block {
                opacity: 1;
            }
        }

        &:hover {
            &.arrows .side-arrows > div {
                opacity: 1;
            }

            &.arrows .side-arrows > div.disabled {
                opacity: 0;
            }
        }

        &.arrows {
            .owl-nav {
                &.side-arrows {
                    display: block;
                }
            }

            .side-arrows {
                position: static;
                top: 50%;
                z-index: 6;

                &.owl-nav button {
                    z-index: 6;
                    height: 8.4rem;
                    width: 5.5rem;
                }

                & > div {
                    position: absolute;
                    top: 50%;
                    z-index: 5;
                    transition: opacity 0.2s;
                }

                .owl-next {
                    z-index: 6;
                    right: 0.5rem;

                    transition: opacity 0.5s;
                }

                .owl-prev {
                    z-index: 6;
                    left: 0.5rem;

                    transition: opacity 0.5s;
                }
            }
        }

        &:not(.arrows) {
            .side-arrows {
                display: none;
            }

            .owl-dots-wrapper {
                display: inline-block;
                position: relative;
            }

            .owl-nav > div {
                position: absolute;
                top: 0;

                &.owl-prev {
                    left: -3.7rem;
                }

                &.owl-next {
                    right: -3.7rem;
                }
            }
        }
    }

    body.accueil .carousel .tile-sizer:after {
        padding-top: $wide-ratio;
    }

    .tile .carousel {
        .btn {
            margin-top: 1.4rem;
        }

        h1 {
            font-size: 2.8rem;
            line-height: 2.8rem;
        }
    }
}

.carousel {
    .carousel-text-block {
        height: 100%;
        overflow: hidden;
        position: relative;
        z-index: 17;
    }

    .tile-inner {
        margin: 0;
        padding: 0;

        .bg-img.lazyloaded {
            height: 100%;
        }
    }

    &.carousel.owl-theme {
        .owl-controls {
            top: auto;
            bottom: 0;

            .owl-dots-wrapper {
                bottom: 0.5em;
            }
        }

        .owl-nav {
            margin-top: 0;

            .owl-next,
            .owl-prev {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            button {
                display: block;
                margin: 0 -1rem;
            }
        }
    }

    .tile-carousel-static {
        .tile-inner {
            .bg-img {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .content-text {
            z-index: 1;
        }
    }
}

.carousel.carousel.carousel-full-width {
    .owl-nav.side-arrows {
        display: none;
        position: absolute;

        width: 100%;

        use {
            fill: #9a9a9a;
        }

        .owl-prev {
            margin-top: 0;
            padding-top: 0;
        }

        .owl-next {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

.carousel.carousel.carousel-full-width .owl-nav.side-arrows .navleftbut,
.carousel.carousel.carousel-full-width .owl-nav.side-arrows .navrightbut {
    margin-top: 0;
    padding-top: 0;
    position: relative;
    width: 8vw;
    height: 8vw;

    
    @include media-breakpoint-up(md) {
        height: 3.6rem;
        width: 3.6rem;
    }

    @include media-breakpoint-up(lg) {
        height: 4rem;
        width: 4rem;
    }

}

.owl-prev,
.owl-next {
	@include media-breakpoint-up(md) {
        z-index: 6;
    }
}

.carousel-standard .over-title-text,
.carousel-standard .under-title-text {
  font-size: 2.9333333333vw;
  line-height: 4vw;

    @include media-breakpoint-up(md) {
        font-size: 1.7rem;
        line-height: 1.3;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
    }
}

.owl-nav.side-arrows.disabled {
    opacity: 0;
}

.carousel.carousel.carousel-full-width .owl-nav.side-arrows {
    top: 50%;
}

.related-text-box {
    position: absolute;
    left: 0;
    padding: 0;
    margin: 0;
    bottom: 0vw;
    padding-left: 4.6666666667vw;
    padding-right: 4.6666666667vw;
    text-align: left;
    
    @include media-breakpoint-up(md) {
        bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        bottom: 0;
    }

    @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        padding-right: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding-right: 2.5rem;
    }
}

.tile-carousel-static.tile-carousel-text-bg {
    .media-text-with-bg-image {
        background-size: cover;
        padding: 0;
    }
}

@include media-breakpoint-down(sm) {
    .tile-carousel-static {
        .carousel-text-block {
            width: 100%;

            .carousel-text-block-content {
                display: table-cell;
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }
        }
    }
    body.theme-bg-bingo #dynamicCarrousel .carousel-item .row{
        margin: 0px;
    }
}
